<!--
 * @Author: your name
 * @Date: 2021-05-08 14:10:59
 * @LastEditTime: 2021-05-11 13:53:43
 * @LastEditors: Please set LastEditors
 * @Description: 无效token
 * @FilePath: \dolphin-web\src\views\home\noAuthorityLayout.vue
-->
<template>
  <div style="width: 100%;height: 100%">
    <img style="width: 100%;height: 100%" src="@/assets/images/home/jurisdiction.png" alt="">
  </div>
</template>
<script>
import { mapActions } from 'vuex'
export default {
  data () {
    return {}
  },
  mounted () {
    this.getSystemFrom('noAuthority')
  },
  methods: {
    ...mapActions([
      'getSystemFrom'
    ])
  }
}
</script>
<style scoped>
</style>