/*
 * @Author: your name
 * @Date: 2021-05-13 10:43:14
 * @LastEditTime: 2021-06-04 18:21:28
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \dolphin-web\src\assets\js\common\env.js
 */
const baseUrl = {
  baseUrl: window.appUrl,
}
export default baseUrl;

//加上kongPath就是因为域名解耦的时候跳转不了登陆端加上的
let kong = window.kongPath ? window.kongPath : ''
if (kong && kong.substring(0,1) != '/') {
  kong = '/'+ window.kongPath
}

const imageUrl = window.imageNginxAgent + '/'
const oneloginBaseUrl = window.location.origin + kong + window.oneloginBase //登录组件基础地址
const oneloginUrl = window.location.origin + kong + window.oneloginUrl
// const oneloginUrl = 'http://localhost:8098/'
const oneloginManageUrl = window.location.origin + kong + window.oneloginManageUrl
const oneloginDeskTopManageUrl = oneloginBaseUrl +'desktopSetting/browserTabConfig?entryModule=manage' //桌面配置地址
export {
  imageUrl, oneloginUrl, oneloginManageUrl, oneloginDeskTopManageUrl
}


