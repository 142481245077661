import Vue from 'vue'
import router from '@/router/index'
import store from './store/index'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'
import "verte/dist/verte.css";

import '@/assets/css/common/global.less'
import '@/assets/css/iconFont/iconfont.css'
Vue.use(ElementUI, {
  size: 'small',
  zIndex: 3000
})

// 添加字体文件
// addserviceFontList()

ElementUI.Dialog.props.lockScroll.default = false
Vue.config.productionTip = false
Vue.directive('alterELDialogMarginTop'/*修改elementUI中el-dialog顶部的距离,传入值eg:{marginTop:'5vh'} */, {
  inserted(el, binding) {
    el.firstElementChild.style.marginTop = binding.value.marginTop
  }
})
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
