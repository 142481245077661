<template>
  <div id="app">
    <noAuthorityLayout v-if="appFrom === 'noAuthority'" />
    <layout v-if="isRouterAlive" />
  </div>
</template>
<script>
import layout from "@/views/layout/layout";
import noAuthorityLayout from "@/views/layout/noAuthorityLayout";
import { mapActions, mapState } from "vuex";
import { getDeskTopSetting } from './assets/js/api/reset.js'
import { imageUrl as IMAGE_REL } from "@/assets/js/common/env.js";
import Cookies from 'js-cookie'
export default {
  provide () {
    return {
      reload: this.reload,
    };
  },
  components: {
    layout,
    noAuthorityLayout,
  },
  name: "app",
  data () {
    return {
      isRouterAlive: true,
      appFrom: "", // 来源
    };
  },
  created () {
    getDeskTopSetting()
      .then(res => {
        if (res.code === 200) {
          const { result } = res
          
          let link = document.querySelector("link[rel*='icon']") || document.createElement('link');
          link.type = 'image/x-icon';
          link.rel = 'shortcut icon';
          link.href = IMAGE_REL + result.iconImg
          document.getElementsByTagName('head')[0].appendChild(link);
          document.title = result.iconName; 
        } else {
          window.icoUrl = null
          window.icoUrl = null
        }
      })
      .catch((error) => {
        return Promise.reject(error)
      })


    if (this.getQueryString("accountToken")) {
      localStorage.setItem(
        "DataOSDesktopTokenJson",
        JSON.stringify({
          accessToken: this.getQueryString("accountToken"),
          accountId: this.getQueryString("accountId"),
        })
      );
      Cookies.set("cookieToken", this.getQueryString("accountToken"))
      // localStorage.setItem(
      //   "cookieToken",
      //    JSON.stringify(this.getQueryString("accountToken"))  
      // );
    }
    if (this.getQueryString("entryModule")) {
      if (this.getQueryString("entryModule") === "manage") {
        this.$router.push({ name: "home" });
        sessionStorage.setItem(
          "DataOSDesktopTokenJson",
          JSON.stringify({
            entryModule: this.getQueryString("entryModule"),
          })
        );
      } else {
        this.$router.push({ name: "client" });
      }
    } else {
      this.$router.push({ name: "client" });
    }
  },
  mounted () {
    // 设置拖动的安全区域
    // 屏幕的宽
    const bodyWidth = document.body.offsetWidth;
    // 左边的安全距离
    const left_safe_distance = (bodyWidth - this.safe_distance.safe_width) / 2;
    // 右边距离屏幕左边的安全距离
    const right_safe_distance =
      left_safe_distance + this.safe_distance.safe_width;
    console.log(bodyWidth, left_safe_distance, right_safe_distance);
    this.safe_distance.left_safe_distance = left_safe_distance;
    this.safe_distance.right_safe_distance = right_safe_distance;
    this.safeDistanceChange(this.safe_distance);
  },
  methods: {
    reload () {
      this.isRouterAlive = false;
      this.$nextTick(function () {
        this.isRouterAlive = true;
      });
    },
    getQueryString (name) {
      const url = window.location.href.substring(
        window.location.href.indexOf("?")
      );
      const reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
      const r = url.substr(1).match(reg);
      if (r != null) {
        return unescape(r[2]);
      }
      return null;
    },
    ...mapActions(["getSystemFrom", "safeDistanceChange"]),
    ...mapActions("certificateAuthority", ["changeDefaultMenuObj"]),
  },
  computed: {
    ...mapState(["systemFrom", "safe_distance"]),
  },
  watch: {
    systemFrom: function (now) {
      this.appFrom = now;
    },
  },
};
</script>
<style lang="less">
* {
  margin: 0;
  padding: 0;
  list-style: none;
}
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  color: #2c3e50;
  position: relative;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
