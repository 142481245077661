/*
 * @Author: your name
 * @Date: 2021-06-02 10:54:22
 * @LastEditTime: 2021-06-02 11:06:44
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \dolphin-web\src\views\manage\components\staticData\index.js
 */

/** 字体大小 */
const FONT_SIZE_ENUM = [
  { key: 12, value: '12px' },
  { key: 14, value: '14px' },
  { key: 16, value: '16px' },
  { key: 18, value: '18px' },
  { key: 20, value: '20px' },
  { key: 24, value: '24px' },
  { key: 30, value: '30px' },
  { key: 36, value: '36px' },
  { key: 48, value: '48px' },
  { key: 60, value: '60px' },
  { key: 72, value: '72px' },
  { key: 84, value: '84px' },
  { key: 96, value: '96px' },
  { key: 108, value: '108px' },
  { key: 120, value: '120px' },
];
export {
  FONT_SIZE_ENUM
}
