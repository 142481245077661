/**
* 作者 ：wbz
*
* 日期 ：2020-09-22
*
* 描述 ：
*/
<template>
  <div class="bindAccount">
    <el-dialog
      :append-to-body="true"
      :close-on-click-modal="false"
      :show-close="false"
      :visible.sync="dialogVisible"
      width="440px"
    >
      <!-- :visible.sync="options.visible" -->
      <div class="dialog-header-title" slot="title">
        <img alt src="../../../assets/images/home/dialog.png" />
        <span style="margin-left: 7px">绑定账号</span>
      </div>
      <span class="dialog-view-cont">
        建议将 [ {{ currentBindAccount }} ]
        的账号与当前登录账号进行绑定，后续打开 [ {{ currentBindAccount }} ]将无需 再输入密码！
      </span>
      <div class="dialog-footer" slot="footer">
        <el-button @click="handleUnBind" class="dialog-btn btn1">取消</el-button>
        <el-button
          :disabled="bindDisabled"
          :loading="bindLoading"
          @click="handleStartBinding"
          class="dialog-btn btn2"
          type="primary"
        >立即绑定</el-button>
      </div>
    </el-dialog>

    <!--登录进行绑定-->
    <div class="desktop-add-view-box">
      <el-dialog
        :append-to-body="true"
        :close-on-click-modal="false"
        :visible.sync="dialogVisible3"
        @close="closeBindDialog"
        class="dialog-form"
        width="360px"
      >
        <div class="dialog-header-title" slot="title">
          <span>绑定账号</span>
        </div>
        <div class="dialog-form-content">
          <span class="dialog-view-cont">
            请输入 [ {{ currentBindAccount }} ]
            的账号信息与当前登录账号进行绑定！
          </span>
          <el-form
            :model="bindAccountForm"
            :rules="bindAccountRules"
            class="demo-ruleForm"
            label-position="top"
            label-width="100px"
            ref="bindAccountForm"
          >
            <el-form-item label="账号" prop="accountBindName">
              <el-input
                autocomplete="off"
                placeholder="请输入账户名"
                v-model="bindAccountForm.accountBindName"
              ></el-input>
            </el-form-item>
            <el-form-item label="密码" prop="accountBindPass">
              <el-input
                autocomplete="new-password"
                placeholder="请输入密码"
                type="password"
                v-model="bindAccountForm.accountBindPass"
              ></el-input>
            </el-form-item>
          </el-form>
        </div>
        <div class="dialog-footer" slot="footer">
          <div class="btn-box">
            <el-button
              :class="bindAccountFormBtnValid ? '' : 'no-valid'"
              :disabled="bindDisabled || !bindAccountFormBtnValid"
              :loading="bindLoading"
              @click="handleBindAccount('bindAccountForm')"
              class="dialog-btn-only"
              type="primary"
            >绑定</el-button>
          </div>
        </div>
      </el-dialog>
    </div>

    <!--更新密码-->
    <div class="desktop-add-view-box desktop-add-view-box2">
      <el-dialog
        :append-to-body="true"
        :close-on-click-modal="false"
        :visible.sync="errorPassword.dialogVisible1"
        width="360px"
      >
        <div class="dialog-header-title" slot="title">
          <img alt src="../../../assets/images/home/dialog.png" />
          <span style="margin-left: 5px">提醒</span>
        </div>
        <span class="dialog-view-cont">{{ errorPassword.message }}!</span>
        <el-form
          :model="numberValidateForm"
          class="demo-ruleForm1"
          label-width="0"
          ref="numberValidateForm"
        >
          <el-form-item
            :rules="[
                { required: true, message: '密码不能为空', trigger: 'blur' },
              ]"
            prop="passwordUpdata"
          >
            <el-input
              autocomplete="off"
              placeholder="请输入密码"
              type="password"
              v-model="numberValidateForm.passwordUpdata"
            ></el-input>
          </el-form-item>
        </el-form>
        <div class="dialog-footer" slot="footer">
          <el-button @click="handleClose" class="dialog-btn btn1">取 消</el-button>
          <el-button
            @click="submitForm('numberValidateForm')"
            class="dialog-btn btn2"
            type="primary"
          >确 定</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import { getIsBind, getPostBindAccount, postClickApp } from "@/assets/js/api/application"; //
import { Encryption } from "@/assets/js/common/baseCommon";
export default {

  props: ['options'],
  data () {
    return {
      dialogVisible: false,
      dialogVisible3: false,
      errorPassword: {
        dialogVisible1: false,
        message: 'dsfd'
      },
      bindLoading: false,
      currentBindAccount: '',
      bindDisabled: false,
      bindAccountFormBtnValid: false, // Form按钮是否可点击
      bindAccountRules: {
        accountBindName: [
          { required: true, message: "账户名不能为空", trigger: "blur" },
        ],
        accountBindPass: [
          { required: true, message: "密码不能为空", trigger: "blur" },
        ],
      },
      bindAccountForm: {
        accountBindName: "",
        accountBindPass: "",
      },
      numberValidateForm: {
        passwordUpdata: "",
      },
      appInfo: {}, //是否绑定接口获取的app信息
      menuData: {}, //meun菜单传来的数据
    }
  },
  watch: {
    bindAccountForm: {
      handler (val) {
        this.bindAccountFormBtnValid = !!(
          val.accountBindName && val.accountBindPass
        );
      },
      deep: true,
    },
  },
  methods: {
    init () {
    },
    //点击应用判断是否需要绑定
    getHandleIsBind (data) {
      let params = {
        appId: data.link,
      }
      getIsBind(params)
        .then((res) => {
          if (res.code == 200) {
            if (res.result.accessType && res.result.accessType != 3) { //通过这2个字段判断是否是自由体系
              data.accessType = res.result.accessType
              this.$emit('openIframeUrl', res.result.linkAddress, data)
            } else {
              this.appInfo = res.result
              this.menuData = data
              if (res.result.bindingSystem) {
                data.accessType = res.result.accessType
                let obj = {
                  appId: res.result.appId,
                  accessType: res.result.accessType,
                  systemAccountId: res.result.systemId,
                  belongSystem: false,
                  ssoEntryUrl: res.result.linkAddress
                }
                this.menuData.id = data.id //当前点击的应用ID
                this.openApp(obj)
                //this.$emit('openIframeUrl',res.result.linkAddress, data)
              } else {
                this.currentBindAccount = data.content;
                this.dialogVisible = true;
              }

            }
          } else if (res.code == 11030113) {
            this.$emit('noToken', res.message)
          } else {
            this.$message.error(res.message);
          }
        })
        .catch(function (error) {
          return Promise.reject(error);
        });

    },

    // 点击暂不绑定
    handleUnBind () {
      this.dialogVisible = false;
      this.$emit('openIframeUrl', this.appInfo.linkAddress, this.menuData)
    },
    // 点击立即绑定
    handleStartBinding () {
      this.dialogVisible3 = true;
      this.dialogVisible = false;
      this.bindDisabled = false;
      this.bindLoading = false;
    },

    // 提交绑定信息
    handleBindAccount (formName) {
      const vm = this
      this.$refs[formName].validate((valid) => {
        if (valid) {

          let obj = {
            userName: vm.bindAccountForm.accountBindName,
            password: Encryption(vm.bindAccountForm.accountBindPass),
            systemId: Number(vm.appInfo.systemId),
            appUrl: vm.appInfo.linkAddress
          };
          //this.appInfo.md5password = obj.password
          this.bindDisabled = true;
          this.bindLoading = true;
          this.postBindAccount(JSON.stringify(obj));
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },

    // 提交绑定账户函数
    postBindAccount (data) {
      getPostBindAccount(data)
        .then((res) => {
          this.bindDisabled = false;
          this.bindLoading = false;
          if (res.code == 200) {
            this.$message({
              message: "绑定成功",
              type: "success",
            });
            this.dialogVisible3 = false;
            this.appInfo.systemAccountId = this.appInfo.systemId
            this.appInfo.isType = true;
            this.appInfo.ssoEntryUrl = res.result.data; // 第三方账户体系登录时的url

            this.openApp(this.appInfo)
          } else {
            this.$message.error(res.message);
          }
        })
        .catch((err) => {
          this.dialogVisible3 = false;
          return Promise.reject(err);
        });
    },
    //自由体系打开应用
    openApp (myAppItem) {
      console.log("myAppItem::1", myAppItem)
      const {
        accessType,
        appId,
        systemAccountId,
        md5password,
        ssoEntryUrl
      } = myAppItem

      const params = {
        appId,
        accessType,
        id: this.menuData.id,
        systemAccountId,
        belongSystem: false,
        ssoEntryUrl
      }

      if (md5password) { //重置密码
        params.ifReLogin = 1  //0 第一次登陆  1登陆失败再次登录
        params.reSetBindAppUserPassWord = md5password
      }
      postClickApp({ ...params })
        .then(res => {
          const { code } = res
          console.log("postClickApp-parma", res)
          if (code === 110118) {
            //本账号未绑定应用，请绑定后重试
            console.log("110118", res)
            this.errorPassword = {
              dialogVisible1: true,
              message: res.message
            }

          } else if (code === 110120) {
            // 认证失败，需要重新设置密码
            this.errorPassword = {
              dialogVisible1: true,
              message: res.message
            }
          } else if (code === 110119) {
            // 密码再次认证失败
            this.errorPassword = {
              dialogVisible1: true,
              message: res.message
            }
          } else if (code === 200) {
            // 认证成功
            const { result } = res
            this.$emit('openIframeUrl', result.appUrl, this.menuData)
          } else {
            this.$message.error(res.message)
          }
        })
        .catch(err => {
          return Promise.reject(err)
        })

    },
    //修改密码
    submitForm (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const md5password = Encryption(
            this.numberValidateForm.passwordUpdata
          );

          this.appInfo.systemAccountId = this.appInfo.systemId
          this.appInfo.isType = true;
          //this.appInfo.ssoEntryUrl = res.result.data; // 第三方账户体系登录时的url
          this.appInfo.md5password = md5password

          this.openApp(this.appInfo);

          // 关闭密码重置弹窗
          this.handleClose()
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },

    handleClose () {
      this.errorPassword.dialogVisible1 = false
    },

    // 关闭绑定弹窗
    closeBindDialog () {
      this.dialogVisible3 = false;
      if (
        this.bindAccountForm.accountBindName ||
        this.bindAccountForm.accountBindPass
      ) {
        this.bindAccountForm.accountBindName = "";
        this.bindAccountForm.accountBindPass = "";
        this.$refs["bindAccountForm"].resetFields();
      }
    },
  },
  created () { },
  mounted () {
    this.init()
  }
}
</script>
<style lang="less" >
@color-primary: rgb(84, 140, 244);
@color-primary-hover: rgb(146, 179, 255);

.el-button--primary,
  .el-button--primary:focus,
  .el-button--primary:hover {
    border-color: @color-primary !important;
    background-color: @color-primary !important;
  }
.el-button--primary:disabled {
    border-color: @color-primary-hover !important;
    background-color: @color-primary-hover !important;
  }
.el-button--default,
  .el-button--default:focus {
    color: @color-primary !important;
    border-color: @color-primary !important;
  }
.el-button--default:hover {
    color: @color-primary !important;
    border-color: @color-primary !important;
  }

</style>