<!--
-->
<template>
  <div>
    <div class="settingPanel">
      <ul>
        <li @click="saveData" class="btn">
          <p>立即保存</p>
          <!-- <p v-if="!save">上次保存：2分钟前</p> -->
          <img
            alt
            src="@/assets/images/Setting/saving@2x.png"
            srcset
            style="width: 20px"
            v-if="save"
          />
          <span v-if="save">正在保存...</span>
        </li>
        <li @click="dialogVisible = true">
          <img alt src="@/assets/images/Setting/icon1@2x.png" srcset style="width: 20px" />
          <span>可见元素</span>
        </li>

        <li @click="toManage(1)">
          <img alt src="@/assets/images/Setting/icon3@2x.png" srcset style="width: 20px" />
          <span>登录页配置</span>
        </li>
        <li @click="toManage(2)">
          <img alt src="@/assets/images/Setting/icon4@2x.png" srcset style="width: 20px" />
          <span>桌面配置</span>
        </li>
        <!-- <li>
        <img
          style="width: 20px"
          src="@/assets/images/Setting/icon2@2x.png"
          alt=""
          srcset=""
        /><span>推荐皮肤</span>
        </li>-->
      </ul>
    </div>
    <el-dialog
      :before-close="handleClose"
      :visible.sync="dialogVisible"
      left
      title="保存可见元素"
      width="500px"
    >
      <div class="diabox">
        <ul class="left">
          <li>
            <p>请勾选要显示的元素</p>
          </li>
          <li @mouseenter="imgtype = 1" @mouseleave="imgtype = 0">
            <el-checkbox @change="handleCheckChange" v-model="checked1">顶部栏</el-checkbox>
          </li>
          <li @mouseenter="imgtype = 2" @mouseleave="imgtype = 0">
            <el-checkbox :disabled="!checked1" @change="handleCheckChange" v-model="checked3">栏目</el-checkbox>
          </li>
          <li @mouseenter="imgtype = 3" @mouseleave="imgtype = 0">
            <el-checkbox :disabled="!checked1" @change="handleCheckChange" v-model="checked4">登录组件</el-checkbox>
          </li>
          <li @change="handleCheckChange" @mouseenter="imgtype = 4" @mouseleave="imgtype = 0">
            <el-checkbox v-model="checked2">底部栏</el-checkbox>
          </li>
        </ul>
        <div class="right">
          <img alt src="@/assets/images/Setting/show0@2x.png" v-if="imgtype === 0" />
          <img alt src="@/assets/images/Setting/show1@2x.png" v-if="imgtype === 1" />
          <img alt src="@/assets/images/Setting/show2@2x.png" v-if="imgtype === 2" />
          <img alt src="@/assets/images/Setting/show3@2x.png" v-if="imgtype === 3" />
          <img alt src="@/assets/images/Setting/show4@2x.png" v-if="imgtype === 4" />
        </div>
      </div>
      <span class="dialog-footer" slot="footer" style="justify-content: flex-end">
        <el-button @click="handleDialogClose">取 消</el-button>
        <el-button :loading="loading" @click="setVisible" type="primary">保 存</el-button>
      </span>
    </el-dialog>
    <el-dialog
      :visible.sync="manageIframe"
      title
      v-alterELDialogMarginTop="{ marginTop: '5vh' }"
      width="1500px"
    >
      <iframe
        :src="iframeSrc"
        class="iframe"
        frameborder="0"
        id="iframe"
        ref="iframe"
        scrolling="auto"
        style="width: 100%; height: 600px; border-top: 1px solid #f1f1f1"
      ></iframe>
    </el-dialog>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { getSetting } from "@/assets/js/api/settingsPage";
import {
  updateBackground,
  updateContainer,
} from "@/assets/js/api/headerEditer";
import { updateElemet } from "@/assets/js/api/elements";
import { oneloginManageUrl, oneloginDeskTopManageUrl } from "@/assets/js/common/env.js";
export default {
  name: "home",
  data () {
    return {
      iframeSrc: "",
      manageIframe: false,
      oneloginManageUrl,
      save: false,
      dialogVisible: false,
      imgtype: 0,
      checked1: false,
      checked2: false,
      checked3: false,
      checked4: false,
      obj1: {},
      obj2: {},
      obj3: {},
      obj4: {},
      setCount: 0,
      loading: false,
      oldObj: {},
    };
  },
  components: {},
  mounted () {
    this.gettVisible();
  },
  watch: {
    dialogVisible (e) {
      if (e == true) {
        this.gettVisible();
      }
    },
    setCount (e) {
      if (e == 3) {
        this.dialogVisible = false;
        this.setCount = 0;
        this.loading = false;
        this.$notify({
          title: "成功",
          message: "修改成功",
          type: "success",
        });
      }
    },
  },
  computed: {
    ...mapState(["elementVisible"]),
  },
  methods: {
    ...mapActions(["elementVisibleChange"]),
    /** 关闭弹窗*/
    handleClose () {
      this.dialogVisible = false;
    },
    //
    toManage (type) {
      this.manageIframe = true;
      let tokenData = JSON.parse(
        localStorage.getItem("DataOSDesktopTokenJson")
      );
      if (type == 1) {
        this.iframeSrc =
          oneloginManageUrl +
          "&accessToken=" +
          tokenData.accessToken +
          "&accountId=" +
          tokenData.accountId;
      } else {
        this.iframeSrc =
          oneloginDeskTopManageUrl +
          "&accessToken=" +
          tokenData.accessToken +
          "&accountId=" +
          tokenData.accountId;

      }

    },
    /** 提交当前各个模块显示隐藏状态 */
    setVisible () {
      this.loading = true;
      this.obj1.display = this.checked1 ? 1 : 2;
      this.obj2.display = this.checked2 ? 1 : 2;
      this.obj3.display = this.checked3 ? 1 : 2;
      this.obj4.display = this.checked4 ? 1 : 2;
      //顶部
      updateBackground(this.obj1)
        .then((res) => {
          if (res.code === 200) {
            this.setCount++;
          } else {
            this.loading = false;
            this.setCount = 0;
            this.$message({
              type: "error",
              message: "顶部保存失败",
            });
          }
        })
        .catch((err) => {
          return Promise.reject(err);
        });
      //底部
      updateBackground(this.obj2)
        .then((res) => {
          if (res.code === 200) {
            this.setCount++;
          } else {
            this.loading = false;
            this.setCount = 0;
            this.$message({
              type: "error",
              message: "底部保存失败",
            });
          }
        })
        .catch((err) => {
          return Promise.reject(err);
        });
      //栏目
      updateContainer(this.obj3)
        .then((res) => {
          if (res.code === 200) {
            this.setCount++;
          } else {
            this.loading = false;
            this.setCount = 0;
            this.$message({
              type: "error",
              message: "栏目保存失败",
            });
          }
        })
        .catch((err) => {
          return Promise.reject(err);
        });
      //登录组件
      updateElemet({
        elementCode: this.obj4.containerCode,
        elementUpdateDto: { display: this.obj4.display },
      })
        .then((res) => {
          if (res.code === 200) {
            this.setCount++;
          } else {
            this.loading = false;
            this.setCount = 0;
            this.$message({
              type: "error",
              message: "登录组件保存失败",
            });
          }
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    },
    /** 加载当前各个模块显示隐藏状态 */
    gettVisible () {
      const vm = this;
      getSetting()
        .then((res) => {
          if (res.code === 200) {
            res.result.forEach((element) => {
              // detailType 1顶部 2底部 3栏目 4登录
              switch (element.detailType) {
                case 1:
                  vm.checked1 = element.display === 1;
                  this.elementVisible.top = vm.checked1;
                  vm.obj1 = {
                    display: element.display,
                    backgroundCode: element.code,
                  };
                  break;
                case 2:
                  vm.checked2 = element.display === 1;
                  this.elementVisible.bottom = vm.checked2;
                  vm.obj2 = {
                    display: element.display,
                    backgroundCode: element.code,
                  };
                  break;
                case 3:
                  vm.checked3 = element.display === 1;
                  this.elementVisible.menu = vm.checked3;
                  vm.obj3 = {
                    display: element.display,
                    containerCode: element.code,
                  };
                  break;
                case 4:
                  vm.checked4 = element.display === 1;
                  this.elementVisible.login = vm.checked4;
                  vm.obj4 = {
                    display: element.display,
                    containerCode: element.code,
                  };
                  break;
                default:
              }
            });
            this.elementVisibleChange(this.elementVisible);
            this.oldObj = JSON.parse(JSON.stringify(this.elementVisible));
          } else {
            vm.$message({
              type: "error",
              message: res.message,
            });
          }
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    },
    saveData () {
      this.$message({
        message: "已实时保存您的操作",
        type: "success",
      });
    },
    handleCheckChange () {
      this.elementVisible.top = this.checked1;
      this.elementVisible.menu = this.checked3;
      this.elementVisible.login = this.checked4;
      this.elementVisible.bottom = this.checked2;
      this.elementVisibleChange(this.elementVisible);
    },
    handleDialogClose () {
      this.dialogVisible = false;
      this.elementVisibleChange(this.oldObj);
    },
  },
};
</script>

<style lang="less" scoped >
.settingPanel {
  position: fixed;
  width: 180px;
  background: #ffffff;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
  border-radius: 0px 12px 12px 0px;
  height: 242px;
  margin: auto 0;
  padding: 16px 16px 6px 16px;
  top: 0;
  bottom: 0;
  .btn {
    background: #279eb7;
    justify-content: center;
    color: #fff;
    text-align: center;
    height: 38px;
    &:hover {
      background: #279eb7;
    }
    img {
      -webkit-animation: round_animate 2s linear infinite;
      animation: round_animate 2s linear infinite;
    }
  }
  li {
    background: #edf7f9;
    border-radius: 4px;
    padding: 13px 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-bottom: 10px;
    font-size: 14px;
    flex-wrap: wrap;

    &:hover {
      background: #bee2e9;
    }
    img {
      margin-right: 20px;
    }
  }
  @keyframes round_animate {
    to {
      transform: rotate(1turn);
    }
  }
}
.diabox {
  display: flex;
  justify-content: center;
  .left,
  .right {
    p {
      color: #7f7f7f;
    }
    text-align: left;
    width: 45%;
    color: #4c4c4c;
    font-size: 14px;
    li {
      margin-bottom: 15px;
    }
    img {
      display: block;
      width: 100%;
    }
  }
}
</style>
