/**
* 作者 ：wbz
*
* 日期 ：2020-09-22
*
* 描述 ：
*/
<template>
  <div>
    <el-dialog :visible.sync="options.visible" append-to-body title="跳转设置" width="500px">
      <div>
        <el-form :model="ruleForm" :rules="textRules" label-width="80px" ref="ruleForm">
          <el-form-item label="跳转到" prop="linkType" >
            <el-radio-group v-model="ruleForm.linkType" @change="changeLinkType">
              <el-radio :label="1">链接</el-radio>
              <el-radio :label="2">应用</el-radio>
              <el-radio :label="4">栏目</el-radio>
            </el-radio-group>
 
          </el-form-item>
          <div v-show=" ruleForm.linkType === 1">
          <el-form-item label="链接" prop="linkAddressInput">
            <el-input
              @input="onInput()"
              class="input-with-select"
              placeholder="请输入链接"
              v-model="ruleForm.linkAddressInput"
              ref="linkAddressInput"
            >
              <el-select slot="prepend" style="width: 90px;" v-model="ruleForm.agreement" >
                <el-option label="http://" value="http://"></el-option>
                <el-option label="https://" value="https://"></el-option>
              </el-select>
            </el-input>
          </el-form-item>
          </div>
         <template  v-show=" ruleForm.linkType === 2">
          <el-form-item label="应用" prop="appId" v-if=" ruleForm.linkType === 2">
            <youeSelectSearch
              :options="appList"
              :optionsType="appOptions.optionsType"
              :pageNum="appOptions.pageNum"
              :pageSize="appOptions.pageSize"
              :pageTotal="appOptions.pageTotal"
              :selectLabel="appOptions.selectLabel"
              :selectValue="appOptions.selectValue"
              :total="appOptions.total"
              @changeSelectValue="chioceOption"
              @initMoreOptionClick="initMoreOption"
              @selectOption="selectOption"
              style="height:32px"
            />
          </el-form-item>
         </template>
         <div v-show=" ruleForm.linkType === 4">
          <el-form-item label="栏目" prop="columnId" >
              <el-cascader
                placeholder="请选择栏目"
                :options="menuOptions"
                v-model="ruleForm.columnId"
                @change="handleChange"
                :props="prosOptions" 
                v-if="showColumn"
                filterable
                change-on-select>
              </el-cascader>
          </el-form-item>
         </div>
          
          <el-form-item label="打开方式" prop="linkMode" v-if="ruleForm.linkType != 4">
            <el-radio-group v-model="ruleForm.linkMode">
              <el-radio :label="1">桌面内打开</el-radio>
              <el-radio :label="2">新窗口打开</el-radio>
            </el-radio-group>

          </el-form-item>
        </el-form>
      </div>
      <div class="dialog-footer" slot="footer">
        <el-button  @click="options.visible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')">保 存</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import youeSelectSearch from 'youe-select-search';
import { getApps } from "@/assets/js/api/application.js";
import { getColumnTree, getElementDetail } from "@/assets/js/api/elements.js";

export default {
  components: { youeSelectSearch },
  props: ['options'],
  data () {
    return {
      ruleForm: {
        linkType: 1, //跳转类型
        linkAddressInput: '',
        appName: '',
        appId: '',
        agreement: "http://",
        linkMode:1,  //打开方式
        columnId:''
      },
      linkAddress:'',
      columnValue:'',
       
      textRules: {
        
        linkType: [
          { required: true, message: '跳转到不能为空', trigger: 'blur' }
        ],
        linkAddressInput: [
          { required: true, message: '链接不能为空', trigger: 'blur' },
          { pattern: /([\w.]+\/?)\S*/, message: '请输入合法的链接地址', trigger: 'blur' }
        ],
        appId: [
          { required: true, message: '应用不能为空', trigger: 'blur' }
        ],
        columnId: [
          { required: true, message: '栏目不能为空', trigger: 'blur' }
        ],
        linkMode: [
          { required: true, message: '打开方式不能为空', trigger: 'blur' }
        ],
      },
      appList: [],
      appOptions: {
        selectValue: '',
        selectLabel: '',
        optionsType: { "value": "appId", "label": "appName" },
        pageSize: 10,
        pageNum: 1,
        total: 0,
        pageTotal: 0
      },
      optionsType: {
        label: "appName",
        value: "appId"
      },
      menuOptions: [],
      showColumn:false,
      prosOptions:{
        //checkStrictly: true,
        //expandTrigger: 'hover',
        value: 'elementCode',
        label: 'content',
        children: 'columnVoList'
      }
    }
  },

  mounted () {
    
    
    /** 回显跳转相关信息 */
    let data = this.options.data
    console.log("data:::",data)
    if (data) {
      this.ruleForm.linkType = data.linkType 
      this.ruleForm.linkMode = data.linkMode //打开方式
      if (this.ruleForm.linkType==1 && data.linkAddress) {
          this.ruleForm.linkAddress = data.linkAddress
          this.ruleForm.agreement = this.ruleForm.linkAddress.split('//')[0] + '//'
          this.ruleForm.linkAddressInput = this.ruleForm.linkAddress.split('//')[1]
          
        } else if (this.ruleForm.linkType==2 && data.link) {
           this.init(data.elementCode)
        } else if (this.ruleForm.linkType == 4 ) {
          console.log("type==",typeof(data.columnId))
           this.ruleForm.columnId =  typeof(data.columnId) =='string' ?  JSON.parse(data.columnId) : data.columnId
           this.changeLinkType()
        }
    }
  },
  methods: {
    onInput(){
      this.$forceUpdate();
    },
    
    init (elementCode) {
      const vm = this
      this.appList = []
      getApps({ pageNumber: 1, pageSize: 10 }).then((res) => {
        const { result } = res;
        this.appList = result.records;
        this.appOptions.pageNum = result.current
        this.appOptions.total = result.total
        this.appOptions.pageTotal = result.pages
        if(elementCode){
          getElementDetail({ elementCode }).then((res) => {
              const { result } = res;
              vm.ruleForm.appName = result.linkName
              vm.ruleForm.appId = result.link
              vm.appOptions.selectLabel  = result.linkName
              vm.appOptions.selectValue = result.link

          });
        }
      });
    },

    //点击加载更多 参数 num 当前页数
    initMoreOption (num) {
      getApps({ pageNumber: num, pageSize: 10, search: this.inputValue }).then((res) => {
        const { result } = res;
        let newAppList = [...this.appList, ...result.records]
        this.appList = newAppList;
        this.appOptions.pageNum = result.current
        this.appOptions.total = result.total
        this.appOptions.pageTotal = result.pages
      });
    },

    //改变select值 参数 item 选中的对象
    chioceOption (item) {
      this.appOptions.selectValue = item.appId
      this.appOptions.selectLabel = item.appName
      this.ruleForm.link = item.appId
    },
    selectOption (val) {
      this.inputValue = val.toString()
      getApps({ pageNumber: 1, pageSize: 10, search: this.inputValue }).then((res) => {
        const { result } = res;
        this.appList = []
        this.appList = result.records;
        this.appOptions.pageNum = result.current
        this.appOptions.total = result.total
        this.appOptions.pageTotal = result.pages
      });
    },
    //搜索input值变化 参数 inputValue
    inputValueChangeEvent (val) {
      this.inputValue = val.toString()
      getApps({ pageNumber: 1, pageSize: 10, search: this.inputValue }).then((res) => {
        const { result } = res;
        this.appList = []
        this.appList = result.records;
        this.appOptions.pageNum = result.current
        this.appOptions.total = result.total
        this.appOptions.pageTotal = result.pages
      });

    },
     
    //切换跳转方式
    changeLinkType(){
      
      if (this.ruleForm.linkType == 1) {
        this.textRules['linkAddressInput'][0].required = true
        this.textRules['appId'][0].required = false
        this.textRules['columnId'][0].required = false
        this.ruleForm.appId =''
        this.ruleForm.appName =''
        this.ruleForm.columnId =''
         
      } else if (this.ruleForm.linkType == 2){
        this.textRules['linkAddressInput'][0].required = false
        this.textRules['appId'][0].required = true
        this.textRules['columnId'][0].required = false
        this.ruleForm.linkAddressInput =''
        this.ruleForm.columnId =''
        
 
      } else if (this.ruleForm.linkType == 4){
        
        this.textRules['linkAddressInput'][0].required = false
        this.textRules['appId'][0].required = false
        this.textRules['columnId'][0].required = true
        this.ruleForm.linkAddressInput =''
        this.ruleForm.appId =''
        this.ruleForm.appName =''
         
      }


      //跳转方式为栏目时，获取栏目列表
      if( this.ruleForm.linkType == 4){
        getColumnTree().then((res) => {
        const { result } = res;
        
        result.forEach(item => {
           item.value = item.elementCode
           item.label = item.content
           if(item.columnVoList.length == 0){
             item.columnVoList = ''
           }
          if(item.columnVoList){
             
            item.children = item.columnVoList
          }
        });
        this.menuOptions = result;
        //console.log("切换跳转方式444", this.ruleForm.columnId, this.menuOptions )
        this.showColumn = true
        
      });
      } else if(this.ruleForm.linkType == 2){
        this.init()
      }
    },
    //修改栏目
    handleChange(value) {
      console.log(value);
    },
    //保存提交
    submitForm (formName) {

      let that = this
      if (that.ruleForm.linkType == 1) {
        this.textRules['linkAddressInput'][0].required = true
        this.textRules['appId'][0].required = false
        this.textRules['columnId'][0].required = false
        if (!that.ruleForm.linkAddressInput){
          that.$message.error("链接不能为空")
          return
        }
        if (that.ruleForm.linkAddressInput.slice(0, 6).indexOf('http')>-1) {
          that.$message.error("请输入合法的链接地址")
          return
        }
        that.linkAddress = that.ruleForm.agreement + that.ruleForm.linkAddressInput
        that.ruleForm.appId =''
        that.ruleForm.appName =''
        that.ruleForm.columnId =''
      } else if (that.ruleForm.linkType == 2){
        this.textRules['linkAddressInput'][0].required = false
        this.textRules['appId'][0].required = true
        this.textRules['columnId'][0].required = false
        that.ruleForm.appId = this.appOptions.selectValue
        that.ruleForm.appName = this.appOptions.selectLabel

        that.ruleForm.columnId =''
      } else if (that.ruleForm.linkType == 4){
        console.log("columnId::", this.textRules['columnId'][0])
        this.textRules['linkAddressInput'][0].required = false
        this.textRules['appId'][0].required = false
        this.textRules['columnId'][0].required = true
        that.ruleForm.appId = 
        that.ruleForm.appName =''
      }
 
      that.$refs[formName].validate((valid) => {
        if (valid) {
           that.saveLink()
        }else{
          console.log("校验失败")
        }
      })

    },

    //保存跳转设置
    saveLink () {
      /**
        * columnId 3栏目id 
        * link 2应用Id  
        * linkAddress 1链接地址
        * linkMode: 1 打开方方式 1桌面内 2新窗口看
        * linkType: 2 //链接类型 1链接  2 应用 4 栏目
      */
 
     let data = {
       columnId: this.ruleForm.columnId,
       link: this.ruleForm.appId,
       linkAddress: this.linkAddress,
       linkMode: this.ruleForm.linkType == 4 ? 1 : this.ruleForm.linkMode,
       linkType: this.ruleForm.linkType
     }
     this.options.visible = false
     this.$emit('changeJumpSettingData',data, true)
       
    },




  },
  created () { },

}
</script>
<style lang="less" >
.formTips {
  cursor: pointer;
  color: #cacaca;
  font-size: 12px;
  margin-left: 5px;
}
.el-input-group__append, .el-input-group__prepend{
  background-color: #ffffff !important;
}
</style>