/*
 * @Author: your name
 * @Date: 2021-06-03 16:53:01
 * @LastEditTime: 2021-06-03 16:54:10
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \dolphin-web\src\assets\js\api\topic.js
 */
import request from '../common/axios';

/** 获取互联网主题id */
export function getInternetTopicId(params) {
  return request({
    url: `topic/getInternetTopicId`,
    method: 'get',
    params
  })
}