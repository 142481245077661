import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)
export default new Router({
    // noJurisdiction token无效页面
    routes: [
        {
            path: '/',
            redirect: '/client'
        },
        {
            path: '/home',
            name: 'home',
            component: require('@/views/home/home.vue').default
        }, {
            path: '/client',
            name: 'client',
            component: require('@/views/home/client.vue').default
        },
        {
            path: '/noJurisdiction',
            name: 'noJurisdiction',
            component: require('@/views/home/lackOfCompetence.vue').default
        }]

})

const VueRouterPush = Router.prototype.push
Router.prototype.push = function push(to) {
    return VueRouterPush.call(this, to).catch(err => err)
}