<template>
  <div
    :style="
      'position:' +
      (styleConfig.position ? 'fixed;' : 'static;') +
      'backdrop-filter: blur(' +
      styleConfig.blurry +
      'px);'
    "
    @click.stop
    @mouseenter="showEditer"
    @mouseleave="showColor()"
    @mousemove="handleMousemove"
    class="headerEditer"
    v-show="!styleConfig.display"
  >
    <div
      :class="isHover ? 'line' : 'line2'"
      :style="'height:' + (styleConfig.style.height - 4) + 'px;'"
    ></div>
    <el-header :style="headerStyleComputed" id="headerEditer">
      <div class="header">
        <TextElement
          :elementData="item"
          :key="item.elementCode"
          @handleChange="handleChange"
          @handleCopy="handleCopy"
          @handleDelete="handleDelete"
          @hiddenTool="hiddenTool"
          ref="TextElement"
          source="header"
          v-for="item in textElementList"
        ></TextElement>
        <MenuElement
          :backgroundCode="backgroundCode"
          @hiddenTool="hiddenTool"
          v-if="backgroundCode"
        ></MenuElement>
        <!-- <div class="text-event"> -->
        <ImageElement
          :elementData="item"
          :key="item.id"
          @handleChange="handleChange"
          @handleDelete="handleDeleteImage"
          @hiddenTool="hiddenTool"
          source="header"
          v-for="item in imageElementList"
        ></ImageElement>
        <!-- </div> -->
        <LoginElement
          :backgroundCode="backgroundCode"
          @hiddenTool="hiddenTool"
          v-if="backgroundCode"
        ></LoginElement>
      </div>
    </el-header>
    <div @click.stop class="editBox" v-if="showBox">
      <ul class="toolP">
        <li>
          <!-- <el-color-picker
            style="margin-top: 6px"
            v-model="styleConfig.style.backgroundColor"
            show-alpha
            @change="sentSave"
          >
          </el-color-picker>-->
          <verte
            @click="sentSave"
            model="rgb"
            picker="square"
            v-if="styleConfig.style && styleConfig.style.backgroundColor"
            v-model="styleConfig.style.backgroundColor"
          ></verte>
        </li>
        <li>
          <div class="inputBox">
            <img alt src="@/assets/images/editerImg/width.png" srcset style="width: 14px" />
            <input
              @blur="handleSafeHight"
              maxlength="3"
              oninput="value=value.replace(/[^\d]/g,'')"
              placeholder="请输入20-500以内的数"
              type="text"
              v-model="boxStyle.height"
            />px
          </div>
        </li>
        <li @click.stop="menuTag = 1" class="li">阴影</li>
        <li @click.stop="menuTag = 2" class="li">背景模糊</li>
        <li @mouseenter="showDrop = true" @mouseleave="showDrop = false" style="position: relative">
          <span>添加元素</span>
          <ul class="dropdown" v-show="showDrop">
            <li @click="handlerElement('text')">添加文字</li>
            <li @click="handlerElement('image')">添加图片</li>
          </ul>
        </li>
        <li>
          <el-checkbox @change="sentSave" v-model="styleConfig.display">隐藏</el-checkbox>
        </li>
        <li>
          <el-checkbox @change="sentSave" v-model="styleConfig.position">固定</el-checkbox>
        </li>
      </ul>
      <ul @click.stop class="toolPP" v-if="menuTag != 0">
        <li v-if="menuTag == 1">
          距离：
          <el-input
            @change="setShadow"
            onkeyup="this.value = this.value.replace(/[^\d.]/g,'');"
            placeholder="请输入内容"
            v-model="shadowConfig.d"
          ></el-input>
        </li>
        <li v-if="menuTag == 1">
          模糊：
          <el-input
            @change="setShadow"
            onkeyup="this.value = this.value.replace(/[^\d.]/g,'');"
            placeholder="请输入内容"
            v-model="shadowConfig.m"
          ></el-input>
        </li>
        <li v-if="menuTag == 1">
          <verte model="rgb" picker="square" v-if="shadowConfig.c" v-model="shadowConfig.c"></verte>
        </li>
        <li v-if="menuTag == 2">
          模糊背景：
          <el-input placeholder="请输入内容" v-model="styleConfig.blurry"></el-input>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import TextElement from "./components/TextElement/index.vue";
import ImageElement from "./components/ImageElement/index1.vue";
import MenuElement from "./components/MenuElement/index.vue";
import LoginElement from "./components/LoginElement/index.vue";
import Verte from "verte";
import { mapActions, mapState } from "vuex";

import { updateBackground } from "@/assets/js/api/headerEditer";
import { getBackgroundDetail } from "@/assets/js/api/reset";
import {
  // addElemet,
  addToBackground,
  deleteElemet,
  updateElemet,
  getElementList,
} from "./../../../assets/js/api/elements.js";
export default {
  name: "headerEditer",
  components: { TextElement, ImageElement, MenuElement, LoginElement, Verte },
  data () {
    return {
      showDrop: false,
      count: 0,
      input: "",
      shadowConfig: {
        d: "1",
        m: "1",
        c: "rgba(0, 0, 0, 1)",
      },
      toFixed: false,
      toHidden: false,
      showBox: false,
      picker: true,
      menuTag: 0,
      color1: "#279EB7",
      color2: null,
      styleConfig: {
        backgroundCode: "",
        blurry: "0",
        shadow: "",
        position: "", //父
        display: "",
        style: {
          backgroundColor: "",
          height: "",
          position: "static",
        },
      },
      boxStyle: {
        height: 20
      },
      textElementList: [],
      elementId: 0,
      imageElementList: [],
      timeID: "",
      isHover: false,
    };
  },
  computed: {
    ...mapState({
      backgroundCode: (state) => state.backgroundCodeTop,
      elementVisible: (state) => state.elementVisible,
      safe_distance: (state) => state.safe_distance,
    }),
    // backgroundConfig() {
    //   return this.$store.state.backgroundConfig;
    // },
    headerStyleComputed () {
      return {
        background: `${this.styleConfig.style.backgroundColor}`,
        height: `${this.styleConfig.style.height}px`,
        boxShadow: `${this.styleConfig.shadow}`,
      };
    },
    shadowColor () {
      return this.shadowConfig.c;
    },
  },
  watch: {
    shadowColor: {
      handler () {
        this.setShadow();
      },
    },
    "styleConfig.style.backgroundColor": {
      handler () {
        // this.sentSave();
        this.handleDebounce();
      },
    },
    // styleConfig: {
    //   handler() {
    //     if (this.count === 0) {
    //       this.count = 1;
    //       this.sentSave();
    //       setTimeout(() => {
    //         this.count = 0;
    //       }, 1000);
    //     }
    //   },
    //   immediate: true,
    //   deep: true,
    // },
    // backgroundConfig(e) {
    //   // 获取默认模板
    //   let data = e[0];
    //   // 转换position和display
    //   data.style.position = data.style.position === 2 ? "fiexd" : "static";
    //   data.display = data.display === 2 ? "none" : "block";
    //   this.toFixed = data.position === "fiexd" ? true : false;
    //   this.toHidden = data.display === "none" ? true : false;
    //   // 赋值给styleConfig
    //   Object.assign(this.styleConfig, data);
    //   console.log(e);
    // },
    // shadowConfig: {
    //   immediate: true,
    //   handler: function (val) {
    //     this.setShadow(val);
    //   },
    //   deep: true,
    // },
    elementVisible: {
      handler: function (val) {
        this.styleConfig.display = !val.top;
      },
      deep: true,
    },
    // toFixed: function (val) {
    //   this.styleConfig.position = val ? "fiexd" : "static";
    // },
    // toHidden: function (val) {
    //   this.styleConfig.display = val ? "none" : "block";
    // },
    backgroundCode () {
      getBackgroundDetail({ backgroundCode: this.backgroundCode })
        .then((res) => {
          if (res.code === 200) {
            console.log("getBackgroundDetail res", res);
            const { result = {} } = res;
            this.styleConfig.backgroundCode = result.backgroundCode;
            this.styleConfig.shadow = result.shadow;
            let d = result.shadow.split(" ")[1]
            let m = result.shadow.split(" ")[2]

            this.shadowConfig.d = d.length == 2 ? '' : parseInt(d);
            this.shadowConfig.m = m.length == 2 ? '' : parseInt(m);
            this.shadowConfig.c = result.shadow.split(" ")[3];
            this.styleConfig.position = result.position === 1;
            this.styleConfig.display = result.display === 2;
            this.styleConfig.style.height = result.style.height;

            this.safe_distance.top_safe_distance =
              this.styleConfig.style.height;
            this.safeDistanceChange(this.safe_distance);

            this.styleConfig.blurry = result.blurry;

            this.styleConfig.style.backgroundColor =
              result.style.backgroundColor;
          } else {
            this.$message({
              type: "error",
              message: res.message,
            });
          }
        })
        .catch((err) => {
          return Promise.reject(err);
        });
      /** 获取文字元素列表 */
      getElementList({
        backgroundCode: this.backgroundCode,
        elementType: 1,
      }).then((res) => {
        console.log("getElementList res=>", res);
        const { result = {} } = res;
        this.textElementList = result.records;
        this.textElementList.forEach((item, index) => {
          item.style.zIndex = index + 20;
        });
      });
      /** 获取图片元素列表 */
      getElementList({
        backgroundCode: this.backgroundCode,
        elementType: 2,
      }).then((res) => {
        console.log("getElementList res=>", res);
        const { result = {} } = res;
        this.imageElementList = result.records;
        this.imageElementList.forEach((item, index) => {
          item.style.zIndex = index + 30;
        });
      });
    },
  },
  mounted () {
    this.boxStyle.height = this.styleConfig.style.height
    
    //当前页面监视键盘输入
    // const vm = this
    // document.onkeydown = function (e) {
    //   console.log('键盘输入了')
    //   //事件对象兼容
    //   let e1 = e || event || window.event || arguments.callee.caller.arguments[0]
    //   //键盘按键判断:左箭头-37;上箭头-38；右箭头-39;下箭头-40
    //   console.log('键盘输入了2', e1)
    //   vm.keydownHandle(e)
    // }

  },
  methods: {
    // ...mapActions("headerEditer", ["changeBackgroundConfig"]),
    ...mapActions(["safeDistanceChange"]),
    hiddenTool () {
      this.showBox = false;
    },
    showColor () {
      this.showBox = false;
      // let box = document.getElementsByClassName("el-color-dropdown");
    },
    handleSafeHight () {
      if (this.boxStyle.height > 500) {
        this.boxStyle.height = 500;
      } else if (this.boxStyle.height < 20) {
        this.boxStyle.height = 20;
      } else {
        this.safe_distance.top_safe_distance = this.boxStyle.height;
        this.safeDistanceChange(this.safe_distance);
      }
      this.styleConfig.style.height = this.boxStyle.height
      this.sentSave();

    },
    countWatch () {
      if (this.styleConfig.style.height > 500) {
        this.styleConfig.style.height = 500;
      }
      if (this.styleConfig.style.height < 20) {
        this.styleConfig.style.height = 20;
      }
    },
    debounce (func, wait) {
      // timeID = "";
      return (v) => {
        if (this.timeID) {
          clearTimeout(this.timeID);
        }
        this.timeID = setTimeout(() => {
          func(v);
        }, wait);
      };
    },
    handleDebounce (type) {
      this.debounce(this.sentSave, 200)(type);
    },
    sentSave () {
      let postData = JSON.parse(JSON.stringify(this.styleConfig));
      postData.position = postData.position ? 1 : 2;
      postData.display = Number(postData.display) + 1;
      // if (this.styleConfig.backgroundCode) {
      updateBackground(postData)
        .then((res) => {
          if (res.code === 200) {
            // this.$notify({
            //   title: "成功",
            //   message: res.message,
            //   type: "success",
            // });
          } else {
            this.$message({
              type: "error",
              message: res.message,
            });
          }
        })
        .catch((err) => {
          return Promise.reject(err);
        });
      // }
    },
    setShadow () {
      this.styleConfig.shadow =
        "0px " +
        this.changeNum(this.shadowConfig.d) +
        "px " +
        this.changeNum(this.shadowConfig.m) +
        "px " +
        this.shadowConfig.c;
      console.log("setShadow2222", this.styleConfig.shadow);
      this.sentSave();
    },
    changeNum(value){
      if(isNaN(value) || value==''|| value == 'NaN'){
        return ''
      }else{
        return value
      }
    },
    showEditer (e) {
      console.log("showEditer", e);
      this.showBox = true;
    },
    handleMousemove (e) {
      // console.log("handleMousemove", e.target.className);
      this.isHover = e.target.className === "header";
    },
    // 颜色输入框点击事件处理
    colorInputClick () {
      this.picker = !this.picker;
    },
    // 颜色值改变事件处理
    colorValueChange (val) {
      console.log(val);
    },
    /** 添加元素 */
    handlerElement (val) {
      console.log(val);
      let pObj = {};
      if (val === "text") {
        pObj = {
          backgroundCode: this.backgroundCode,
          elementType: 1, // 1:文本 2:图片 3:按钮
          content: "双击修改文字",
          linkType: 1, // 1.链接 2.应用 3.中台桌面 4.栏目
          linkModel: 2, //  1.桌面内打开 2.新窗口打开
          linkAddress: "", // 链接地址
          style: {
            width: 200,
            font: '思源',
            fontColor: "#808080", // 颜色
            fontSize: 20, // 字体大小
            bold: "normal", // 是否粗体 normal:默认无 1:粗体
            italic: "normal", //  是否斜体 normal:默认无 1:斜体
            textDecoration: "none", // 是否下划线 none:默认无 1:下划线
            lineHeight: 1.2, // 行高 ,
            alignment: 1, // 对齐方式 1:左对齐 2:居中对齐 3:右对齐 ,
            xAxis: 200,
            yAxis: 30,
          },
        };
      } else if (val === "image") {
        pObj = {
          backgroundCode: this.backgroundCode,
          elementType: 2, // 1:文本 2:图片 3:按钮
          linkType: 1, // 1.链接 2.应用 3.中台桌面
          linkModel: 2, //  1.桌面内打开 2.新窗口打开
          linkAddress: "", // 链接地址
          imagePath: "", // 图片路径
          style: {
            borderRadius: 5, // 圆角弧度
            width: 60,
            height: 60,
            xAxis: 0,
            yAxis: 0,
          },
        };
      }
      addToBackground(pObj).then((res) => {
        console.log("res--->", res);
        const { result } = res;
        if (val === "text") {
          this.$nextTick(() => {
            this.textElementList.push(result);
            this.textElementList.forEach((item, index) => {
              item.style.zIndex = index + 20;
            });
          });
        } else if (val === "image") {
          this.$nextTick(() => {
            this.imageElementList.push(result);
          });
        } else {
          return;
        }
      });
    },
    /** 父容器处理子元素复制 */
    handleCopy (val) {
      const oVal = JSON.parse(JSON.stringify(val));
      oVal.style.xAxis = oVal.style.xAxis + 10;
      oVal.style.yAxis = oVal.style.yAxis + 10;
      addToBackground({ ...oVal }).then((res) => {
        const { result } = res;
        this.$nextTick(() => {
          this.textElementList.push(result);
        });
      });
    },
    /** 删除文字元素 */
    handleDelete (elementCode) {
      deleteElemet({ elementCode }).then(() => {
        // const textList = JSON.parse(JSON.stringify(this.textElementList));
        // this.textElementList = textList.filter(
        //   (item) => item.elementCode !== elementCode
        // );
        getElementList({
          backgroundCode: this.backgroundCode,
          elementType: 1,
        }).then((res) => {
          console.log("getElementList res=>", res);
          const { result = {} } = res;
          this.textElementList = result.records;
          this.textElementList.forEach((item, index) => {
            item.style.zIndex = index + 20;
          });
        });
      });
    },
    /** 删除图片元素 */
    handleDeleteImage (elementCode) {
      deleteElemet({ elementCode }).then(() => {
        
        getElementList({
          backgroundCode: this.backgroundCode,
          elementType: 2,
        }).then((res) => {
          const textList = JSON.parse(JSON.stringify(this.imageElementList));
          this.imageElementList = textList.filter(
            (item) => item.elementCode !== elementCode
          );
          console.log("getElementList res=>", res);
        });
      });
    },
    /** 元素修改 */
    handleChange (elementData, showMsg) {
      updateElemet({
        elementCode: elementData.elementCode,
        elementUpdateDto: { ...elementData },
      }).then((res) => {
        console.log(res);

        const { code, result } = res
        if (code == 200) {
          let index = this.textElementList.find(item => {
            return item.id == result.id
          });
          this.$set(this.textElementList, index, result);
          if (showMsg) {
            //有值 则弹出提示框
            this.$message.success("设置成功")
          }
        }
      });
    },

    /**
     * 上下左右按键调整组件位置
     *
     */
    keydownHandle (ev, index) {
      let vm = this

      console.log("keydownHandle:", ev, index)

      // 获取被选中的组件列表
      let selectOptions = []
      // if (this.selectIds.length > 1) {
      //   this.pageOptions[this.pageIndex].componentList.map(item => {
      //     let selectIndex = this.selectIds.findIndex(id => {
      //       return id == item.data.id
      //     })
      //     if (selectIndex > -1) {
      //       selectOptions.push(item)
      //     }
      //   })
      // }

      // 当前是否有组件选中框（有则认为有组件选中）
      /**
       * textareaUpActive用在文字组件是否需要移动 值来自vuex中，false或者true由鼠标是否编辑文字组件
       * */
      if (
        !vm.ActivePointShow
      ) {
        if (ev.keyCode == 38) {
          this.chartOptions.top--
        } else if (ev.keyCode == 39) {
          this.chartOptions.left++
        } else if (ev.keyCode == 40) {
          this.chartOptions.top++
        } else if (ev.keyCode == 37) {
          this.chartOptions.left--
        }
        ev.preventDefault()
      } else if (selectOptions.length > 1) {
        // 如果被选中的组件列表长度大于1，说明为多选组件
        // 根据键key相应的遍历改变组件的边距
        if (ev.keyCode == 38) {
          selectOptions.map(item => {
            item.data.chartOptions.top--
          })
        } else if (ev.keyCode == 39) {
          selectOptions.map(item => {
            item.data.chartOptions.left++
          })
        } else if (ev.keyCode == 40) {
          selectOptions.map(item => {
            item.data.chartOptions.top++
          })
        } else if (ev.keyCode == 37) {
          selectOptions.map(item => {
            item.data.chartOptions.left--
          })
        }
        // 调用组件位置更新方法
        this.changePosition()
        // 调用组件多选框位置更新方法
        this.$refs.page[0].resetParameHandle()
        ev.preventDefault()
      } else {
        return true
      }
      vm.updateChartStyle()
    },

  },
};
</script>

<style lang="less">
.headerEditer {
  .verte__menu-origin--bottom {
    top: 23px !important;
  }
  width: 100%;
  box-sizing: border-box;
  // position: relative;
  .positionBox {
    position: absolute;
    z-index: 9999999;
  }
  .dropdown {
    position: absolute;
    bottom: -76px;
    background: #fff;
    left: -20px;
    text-align: center;
    width: 100px;
    font-size: 13px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    li {
      line-height: 40px;
      padding: 0 !important;
      &:hover {
        color: #279eb7;
      }
    }
    &:hover {
      color: #2c3e50 !important;
    }
  }
  &:hover {
    .line {
      border-top: 2px solid #54adff;
      border-bottom: 2px solid #54adff;
      background: #54acff42;
    }
    .line2 {
      border-top: 2px solid #54adff;
      border-bottom: 2px solid #54adff;
    }
  }
  .inputBox {
    width: 88px;
    border: 1px solid #e5e5e5;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    height: 30px;
    input {
      text-align: center;
      width: 40px;
      border: none;
      &:focus-visible {
        border: none;
        outline: none;
      }
    }
  }
  .line {
    width: 100%;
    position: absolute;
  }
  .line2 {
    width: 100%;
    position: absolute;
  }
  .header {
    width: 1280px;
    height: 100%;
    margin: 0 auto;
    position: relative;
    border-left: 1px dotted #e130ff;
    border-right: 1px dotted #e130ff;
  }
  .toolP {
    display: flex;
    justify-content: space-between;
    align-items: center;
    li {
      cursor: pointer;
      padding: 10px 0;
      font-size: 14px;
    }
    .li {
      &:hover {
        color: #279eb7;
      }
    }
  }
  .toolPP {
    border-top: 1px solid #ddd;
    display: flex;
    li {
      margin: 10px 10px 10px 0;
      font-size: 14px;
      display: flex;
      align-items: center;
    }
    .el-input {
      width: 100px;
    }
  }
  .editBox {
    width: 550px;
    background: #fff;
    position: absolute;
    margin: 0 auto;
    left: 0;
    right: 0;
    line-height: 15px;
    padding: 0 20px;
    z-index: 10;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
  }
  .pickerBox {
    position: absolute;
  }
}
.text-event {
  // float: left;
  height: 100%;
  width: 100%;
  // border: 1px solid red;
  // position: relative;
  display: inline-block;
  position: absolute;
}
.verte__guide svg {
  cursor: pointer;
}
</style>

