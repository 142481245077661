<template>
  <div
    :style="'position: fixed; bottom: 0;'"
    @click.stop
    @mouseenter="showEditer()"
    @mouseleave="hiddenTool()"
    class="footerEditer"
    v-show="!styleConfig.display"
  >
    <!-- ' + (styleConfig.position ? 'fixed;' : 'static;') + ' -->
    <div :style="'height:' + (styleConfig.style.height - 4) + 'px;'" class="line"></div>
    <el-footer :style="headerStyleComputed" id="footerEditer" style="position: relative">
      <div class="footer" ref="footer">
        <TextElement
          :elementData="item"
          :key="item.elementCode"
          @handleChange="handleChange"
          @handleCopy="handleCopy"
          @handleDelete="handleDelete"
          @hiddenTool="hiddenTool"
          source="footer"
          v-for="item in textElementList"
        ></TextElement>
        <ImageElement
          :elementData="item"
          :key="item.id"
          @handleChange="handleChange"
          @handleDelete="handleDeleteImage"
          @hiddenTool="hiddenTool"
          source="footer"
          v-for="item in imageElementList"
        ></ImageElement>
      </div>
      <div @click.stop class="editBox" v-if="showBox">
        <ul class="toolP">
          <li>
            <verte
              @change="sentSave"
              menuPosition="top"
              model="rgb"
              picker="square"
              v-model="styleConfig.style.backgroundColor"
            ></verte>
            <!-- <el-color-picker
              style="margin-top: 18px"
              v-model="styleConfig.style.backgroundColor"
              show-alpha
              @change="sentSave"
            ></el-color-picker>-->
          </li>
          <li>
            <div class="inputBox">
              <img alt src="@/assets/images/editerImg/width.png" srcset style="width: 14px" />
              <input
                @blur="handleSafeHight"
                maxlength="3"
                oninput="value=value.replace(/[^\d]/g,'')"
                placeholder="请输入20-500以内的数"
                type="text"
                v-model="boxStyle.height"
              />px
            </div>
          </li>
          <li
            @mouseenter="showDrop = true"
            @mouseleave="showDrop = false"
            style="position: relative"
          >
            <span>添加元素</span>
            <ul class="dropdown" v-show="showDrop">
              <li @click="handlerElement('text')">添加文字</li>
              <li @click="handlerElement('image')">添加图片</li>
            </ul>
          </li>
          <li>
            <el-checkbox @change="sentSave" v-model="styleConfig.display">隐藏</el-checkbox>
          </li>
          <li>
            <el-checkbox @change="sentSave" v-model="styleConfig.position">固定</el-checkbox>
          </li>
        </ul>
      </div>
    </el-footer>
  </div>
</template>
<script>
import TextElement from "./components/TextElement/index.vue";
import ImageElement from "./components/ImageElement/index1.vue";
import Verte from "verte";
import { mapState, mapActions } from "vuex";
import { updateBackground } from "@/assets/js/api/headerEditer";
import { getBackgroundDetail } from "@/assets/js/api/reset";
import {
  // addElemet,
  addToBackground,
  deleteElemet,
  updateElemet,
  getElementList,
} from "./../../../assets/js/api/elements.js";
export default {
  name: "footerEditer",
  components: { TextElement, ImageElement, Verte },
  data () {
    return {
      showDrop: false,
      count: 0,
      input: "",
      shadowConfig: {
        d: "1",
        m: "1",
        c: "rgba(0, 0, 0, 1)",
      },
      toFixed: false,
      toHidden: false,
      showBox: false,
      picker: true,
      menuTag: 0,
      color1: "#279EB7",
      color2: null,
      styleConfig: {
        backgroundCode: "",
        shadow: "",
        position: "", //父
        display: "",
        style: {
          width: 200,
          backgroundColor: "#c3c3c3",
          height: "120",
        },
      },
      boxStyle: {
        height: 120
      },
      textElementList: [],
      elementId: 0,
      imageElementList: [],
    };
  },
  computed: {
    ...mapState({
      backgroundCode: (state) => state.backgroundCodeBottom,
      elementVisible: (state) => state.elementVisible,
      safe_distance: (state) => state.safe_distance,
    }),
    // backgroundConfig() {
    //   console.log(this.$store.state.backgroundConfig);
    //   return this.$store.state.backgroundConfig;
    // },
    headerStyleComputed () {
      return {
        background: `${this.styleConfig.style.backgroundColor}`,
        height: `${this.styleConfig.style.height}px`,
        // position: `${this.styleConfig.position ? "fixed" : "static"}`,
        boxShadow: `${this.styleConfig.shadow}`,
      };
    },
  },
  watch: {
    "styleConfig.style.backgroundColor": {
      handler () {
        this.sentSave();
      },
    },
    // styleConfig: {
    //   handler() {
    //     if (this.count === 0) {
    //       this.count = 1;
    //       this.sentSave();
    //       setTimeout(() => {
    //         this.count = 0;
    //       }, 1000);
    //     }
    //   },
    //   immediate: true,
    //   deep: true,
    // },
    // backgroundConfig(e) {
    //   // 获取默认模板
    //   let data = e[1];
    //   // 转换position和display
    //   data.position = data.position === 2 ? "fiexd" : "static";
    //   data.display = data.display === 2 ? "none" : "block";
    //   this.toFixed = data.position === "fiexd" ? true : false;
    //   this.toHidden = data.display === "none" ? true : false;
    //   // 赋值给styleConfig
    //   Object.assign(this.styleConfig, data);
    //   console.log("change");
    // },
    // shadowConfig: {
    //   immediate: true,
    //   handler: function (val) {
    //     this.setShadow(val);
    //   },
    //   deep: true,
    // },
    // toFixed: function (val) {
    //   this.styleConfig.position = val ? "fiexd" : "static";
    // },
    // toHidden: function (val) {
    //   this.styleConfig.display = val ? "none" : "block";
    // },
    elementVisible: {
      handler: function (val) {
        this.styleConfig.display = !val.bottom;
      },
      deep: true,
    },
    backgroundCode () {
      let that = this
      getBackgroundDetail({ backgroundCode: that.backgroundCode })
        .then((res) => {
          if (res.code === 200) {
            console.log("getBackgroundDetail res", res);
            const { result = {} } = res;
            that.styleConfig.backgroundCode = result.backgroundCode;
            that.styleConfig.shadow = result.shadow;
            that.styleConfig.position = result.position === 1;
            that.styleConfig.display = result.display === 2;
            that.styleConfig.style.height = result.style.height;
            that.boxStyle.height = that.styleConfig.style.height
            that.safe_distance.bottom_safe_distance =
              that.styleConfig.style.height;
            that.safeDistanceChange(that.safe_distance);

            that.styleConfig.style.backgroundColor =
              result.style.backgroundColor;
          } else {
            that.$message({
              type: "error",
              message: res.message,
            });
          }
        })
        .catch((err) => {
          return Promise.reject(err);
        });
      /** 获取文字元素列表 */
      getElementList({
        backgroundCode: this.backgroundCode,
        elementType: 1,
      }).then((res) => {
        console.log("getElementList res=>", res);
        const { result = {} } = res;
        this.textElementList = result.records;
        this.textElementList.forEach((item, index) => {
          item.style.zIndex = index + 20;
        });
      });
      /** 获取图片元素列表 */
      getElementList({
        backgroundCode: this.backgroundCode,
        elementType: 2,
      }).then((res) => {
        console.log("getElementList res=>", res);
        const { result = {} } = res;
        this.imageElementList = result.records;
        this.imageElementList.forEach((item, index) => {
          item.style.zIndex = index + 30;
        });
      });
    },
  },
  mounted () {
    document.body.addEventListener(
      "click",
      () => {
        this.showBox = false;
      },
      false
    );
  },
  methods: {
    // ...mapActions("headerEditer", ["changeBackgroundConfig"]),
    ...mapActions(["safeDistanceChange"]),
    hiddenTool () {
      this.showBox = false;
    },
    handleSafeHight () {
      if (this.boxStyle.height > 500) {
        this.boxStyle.height = 500;
      } else if (this.boxStyle.height < 20) {
        this.boxStyle.height = 20;
      } else {
        this.safe_distance.top_safe_distance = this.boxStyle.height;
        this.safeDistanceChange(this.safe_distance);
      }
      this.styleConfig.style.height = this.boxStyle.height
      console.log("handleSafeHight", this.styleConfig)
      this.sentSave();
    },
    countWatch () {
      if (this.styleConfig.style.height > 500) {
        this.styleConfig.style.height = 500;
      }
      if (this.styleConfig.style.height < 20) {
        this.styleConfig.style.height = 20;
      }
    },
    sentSave () {
      const vm = this;
      let postData = JSON.parse(JSON.stringify(this.styleConfig));
      postData.position = postData.position ? 1 : 2;
      postData.display = Number(postData.display) + 1;
      if (this.styleConfig.backgroundCode) {
        updateBackground(postData)
          .then((res) => {
            if (res.code === 200) {
              if (vm.count != 0) {
                this.$notify({
                  title: "成功",
                  message: res.message,
                  type: "success",
                });
              }
              vm.count++;
            } else {
              this.$message({
                type: "error",
                message: res.message,
              });
            }
          })
          .catch((err) => {
            return Promise.reject(err);
          });
      }
    },
    setShadow (val) {
      this.styleConfig.shadow =
        val.d + "px " + val.d + "px " + val.m + "px " + val.c;
      this.sentSave();
    },
    showEditer () {
      this.showBox = true;
    },
    // 颜色输入框点击事件处理
    colorInputClick () {
      this.picker = !this.picker;
    },
    // 颜色值改变事件处理
    colorValueChange (val) {
      console.log(val);
    },
    /** 添加元素 */
    handlerElement (val) {
      console.log(val);
      let pObj = {};
      if (val === "text") {
        pObj = {
          backgroundCode: this.backgroundCode,
          elementType: 1, // 1:文本 2:图片 3:按钮
          content: "双击修改文字",
          linkType: 1, // 1.链接 2.应用 3.中台桌面 4.栏目
          linkModel: 2, //  1.桌面内打开 2.新窗口打开
          linkAddress: "", // 链接地址
          style: {
            width: 200,
            font:'思源',
            fontColor: "#808080", // 颜色
            fontSize: 20, // 字体大小
            bold: "normal", // 是否粗体 0:默认无 1:粗体
            italic: "normal", //  是否斜体 0:默认无 1:斜体
            textDecoration: "none", // 是否下划线 none:默认无 1:下划线
            lineHeight: 1.2, // 行高 ,
            alignment: 1, // 对齐方式 1:左对齐 2:居中对齐 3:右对齐 ,
            xAxis: 200,
            yAxis: 30,
          },
        };
      } else if (val === "image") {
        pObj = {
          backgroundCode: this.backgroundCode,
          elementType: 2, // 1:文本 2:图片 3:按钮
          linkType: 1, // 1.链接 2.应用 3.中台桌面
          linkModel: 2, //  1.桌面内打开 2.新窗口打开
          linkAddress: "", // 链接地址
          imagePath: "", // 图片路径
          style: {
            borderRadius: 5, // 圆角弧度
            width: 60,
            height: 60,
            xAxis: 0,
            yAxis: 0,
          },
        };
      }
      addToBackground(pObj).then((res) => {
        console.log("res--->", res);
        const { result } = res;
        if (val === "text") {
          this.$nextTick(() => {
            this.textElementList.push(result);
            this.textElementList.forEach((item, index) => {
              item.style.zIndex = index + 20;
            });
          });
        } else if (val === "image") {
          this.$nextTick(() => {
            this.imageElementList.push(result);
          });
        } else {
          return;
        }
      });
    },
    /** 父容器处理子元素复制 */
    handleCopy (val) {
      const oVal = JSON.parse(JSON.stringify(val));
      oVal.style.xAxis = oVal.style.xAxis + 10;
      oVal.style.yAxis = oVal.style.yAxis + 10;
      addToBackground({ ...oVal }).then((res) => {
        console.log(res);
        const { result } = res;
        this.$nextTick(() => {
          this.textElementList.push(result);
        });
      });
    },
    /** 删除文字元素 */
    handleDelete (elementCode) {
      deleteElemet({ elementCode }).then(() => {
        const textList = JSON.parse(JSON.stringify(this.textElementList));
        this.textElementList = textList.filter(
          (item) => item.elementCode !== elementCode
        );
      });
    },
    /** 删除图片元素 */
    handleDeleteImage (elementCode) {
      deleteElemet({ elementCode }).then(() => {
        const textList = JSON.parse(JSON.stringify(this.imageElementList));
        this.imageElementList = textList.filter(
          (item) => item.elementCode !== elementCode
        );
      });
    },
    /** 元素修改 */
    handleChange (elementData, showMsg) {
      updateElemet({
        elementCode: elementData.elementCode,
        elementUpdateDto: { ...elementData },
      }).then((res) => {
        console.log(res);
        const { code } = res
        if (code == 200) {
          if (showMsg) {
            //有值 则弹出提示框
            this.$message.success("设置成功")
          }
        }
      });
    },
  },
};
</script>

<style lang="less">
.footerEditer {
  .verte__menu-origin--top {
    bottom: 32px !important;
  }
  width: 100%;
  box-sizing: border-box;
  &:hover {
    .line {
      border-top: 2px solid #54adff;
      border-bottom: 2px solid #54adff;
      background: #54acff42;
    }
  }
  .dropdown {
    position: absolute;
    top: -90px;
    background: #fff;
    left: -20px;
    text-align: center;
    width: 100px;
    font-size: 13px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    li {
      &:hover {
        color: #279eb7;
      }
    }
    &:hover {
      color: #2c3e50 !important;
    }
  }
  .line {
    width: 100%;
    position: absolute;
  }
  .inputBox {
    width: 88px;
    border: 1px solid #e5e5e5;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    height: 40px;
    input {
      text-align: center;
      width: 40px;
      border: none;
      &:focus-visible {
        border: none;
        outline: none;
      }
    }
  }
  .footer {
    width: 1280px;
    height: 200px;
    left: 50%;
    margin-left: -640px;
    position: absolute;
    bottom: 0;
    border-left: 1px dotted #e130ff;
    border-right: 1px dotted #e130ff;
  }
  .toolP {
    display: flex;
    justify-content: space-between;
    align-items: center;
    li {
      cursor: pointer;
      & > :hover {
        color: #279eb7;
      }
    }
  }
  .toolPP {
    border-top: 1px solid #ddd;
    display: flex;
    li {
      margin-right: 10px;
      display: flex;
    }
    .el-input {
      width: 100px;
    }
  }
  .editBox {
    width: 500px;
    background: #fff;
    position: absolute;
    margin: 0 auto;
    left: 0;
    right: 0;
    line-height: 50px;
    padding: 0 20px;
    z-index: 10;
    top: -50px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
  }
  .pickerBox {
    position: absolute;
  }
}
</style>

