<template>
  <div class="client">
    <!-- 头部 -->
    <template v-if="topStyle.style.backgroundCode != ''">
      <el-header
        :style="
        'backdrop-filter: blur(' +
          topStyle.blurry +
          'px);background:' +
          topStyle.style.backgroundColor +
          ';' +
          'height:' +
          topStyle.style.height +
          'px;' +
          'position:' +
          (topStyle.position === 1 ? 'fixed;top:0;width:100%' : 'static') +
          ';' +
          'box-shadow:' +
          topStyle.shadow +
          ';'
        "
        id="headerEditer"
        v-show="topStyle.display !== 2"
      >
        <div class="header">
          <!-- 头部菜单 -->

          <template v-if="menuStyle.style ">
            <div
              :style="
                'backgroundColor:' +
                 ( menuList.length > 1   ? menuList[0].style.backgroundColor : menuStyle.style.backgroundColor )  +
                '; height:' +
                (  menuList.length > 1   ? menuList[0].style.height : menuStyle.style.height) +
                'px; padding-left: ' + (   menuStyle.style.height/2) +
                'px; padding-right: ' + (   menuStyle.style.height/2) +
                'px; position:absolute; min-height:10px;min-width:10px;left:' +
                menuStyle.style.xAxis +
                'px;top:' +
                menuStyle.style.yAxis +
                'px;line-height:' +
                menuStyle.style.lineHeight +
                'px; z-index: 2222'
              "
              v-show="menuStyle.display != 2"
            >
              <template v-for="(item, i) in menuList">
                <div :key="i" class="dp-submenu" style="display: inline-block" v-if="item.children">
                  <span
                    :style="
                      'height:' +  item.style.height + 
                      'px; line-height:' + item.style.height +
                      'px; z-index: 2222; ' + 
                      'display: inline-block;cursor: pointer; color:' +
                      (item.style.fontColor1?item.style.fontColor1:item.style.fontColor) +
                      ';font-size:' +
                      item.style.fontSize +
                      'px;text-decoration:' +
                      (Number(item.style.textDecoration) === 1 ? 'underline;' : 'none;') +
                      'font-style:' +
                      (Number(item.style.italic) === 1 ? 'italic;' : 'none;') +
                      'font-weight:' +
                      (Number(item.style.bold) === 1 ? '800;' : 'normal;') +
                      'margin-right:'+
                      (menuList.length > 1 && i != menuList.length-1 ? item.style.padding :'') +'px;'
                    "
                    @click="clickHandlerMenu(i)"
                    @mouseleave="mouseLeaveLi(i)"
                    @mouseover="mouseOverLi(i)"
                    class="levelOne"
                  >
                    {{ item.content }}
                    <i class="dp-submenu__icon-arrow el-icon-arrow-down"></i>
                  </span>
                  <div
                    :style="
                    'position: absolute; background:'+
                    item.style.backgroundColor +
                    '; margin-left: -' + 
                    item.children[0].style.padding/2 + 'px;'
                  "
                    v-show="item.children[0].showChildren"
                  >
                    <div
                      :key="j"
                      :style="
                          'clear: both;cursor: pointer;display: block;color:' +
                          (subMenu.style.fontColor1?subMenu.style.fontColor1:subMenu.style.fontColor) +
                          ';font-size:' +
                          subMenu.style.fontSize +
                          'px;' +
                          'text-decoration:' +
                          (Number(subMenu.style.textDecoration) === 1
                            ? 'underline;'
                            : 'none;') +
                          'font-style:' +
                          (Number(subMenu.style.italic) === 1 ? 'italic;' : 'none;') +
                          'font-weight:' +
                          (Number(subMenu.style.bold) === 1 ? '800;' : 'normal;')+
                          'height:' +
                          (subMenu.style.height==0?10:subMenu.style.height) +
                          'px;line-height:'+
                          (subMenu.style.height==0?10:subMenu.style.height) +
                          'px;background:'+ subMenu.style.backgroundColor +
                          ';padding-left: '+ subMenu.style.padding + 'px;'+
                          ';padding-right: '+ subMenu.style.padding + 'px; box-shadow: 0px 2px 4px 0px rgb(0 0 0 / 15%);'
                          
                          "
                      @click="clickHandlerMenu(i,j)"
                      @mouseleave="mouseLeaveLi(i,j)"
                      @mouseover="mouseOverLi(i,j)"
                      class="el-menu-item"
                      v-for="(subMenu, j) in item.children[0].children"
                    >{{ subMenu.content }}</div>
                  </div>
                </div>
                <div
                  :key="i"
                  :style="
                      'height:' +  item.style.height + 
                      'px; line-height:' + item.style.height +
                      'px; z-index: 2222; ' + 
                      'display: inline-block;cursor: pointer; color:' +
                      (item.style.fontColor1?item.style.fontColor1:item.style.fontColor) +
                      ';font-size:' +
                      item.style.fontSize +
                      'px;text-decoration:' +
                      (Number(item.style.textDecoration) === 1 ? 'underline;' : 'none;') +
                      'font-style:' +
                      (Number(item.style.italic) === 1 ? 'italic;' : 'none;') +
                      'font-weight:' +
                      (Number(item.style.bold) === 1 ? '800;' : 'normal;') +
                      'margin-right:'+
                      (menuList.length > 1 && i != menuList.length-1 ? item.style.padding :'') +'px;'
                    "
                  @click="clickHandlerMenu(i)"
                  @mouseleave="mouseLeaveLi(i)"
                  @mouseover="mouseOverLi(i)"
                  v-else
                >{{ item.content }}</div>
              </template>
            </div>
          </template>

          <div :key="i" v-for="(item, i) in contanListTop">
            <!-- 头部文字元素 -->
            <div
              :key="i"
              :style="
                'position: absolute; box-sizing: border-box; overflow: hidden; ' +
                (item.linkAddress ? 'cursor: pointer;' : '')  +
                'height:' + item.style.height + 'px; ' +
                item.style ? styleConfig(item.style, item) :'' "
              v-if="item.elementType === 1"
            >
              <a
                @click="textClick(item)"
                style="cursor: pointer;"
                v-html="item.content"
                v-if="item.linkAddress != '' || (item.link && item.link != '') || (item.columnId&&item.columnId != '') "
              ></a>
              <div v-else v-html="item.content"></div>

              <!-- <template v-if="item.linkAddress != '' || item.link != '' || item.columnId != '' ">
                <a @click="textClick(item)" v-html="item.content"></a>
              </template>
              <template v-else>
                <div v-html="item.content"></div>
              </template>-->
            </div>
     
            <div v-if="item.elementType === 2" :style="'position:absolute; overflow:hidden;' + 
                item.style ? styleConfig(item.style ,item) :'' 
                ">
              <a
                @click="textClick(item)"
                style="cursor: pointer;"
                v-if="item.linkAddress != '' || (item.link && item.link != '') || (item.columnId&&item.columnId != '') "
              >
                <img
                  :key="i"
                  :src="`${IMAGE_REL}${item.imagePath}`"
                  style="width: 100%;"
                  alt
                  srcset
                />
              </a>

              <img
                :key="i"
                :src="`${IMAGE_REL}${item.imagePath}`"
                style="width: 100%;"
                alt
                srcset
                v-else
              />
            </div>
          </div>

          <!-- 头部按钮 -->
          <template v-if="!isToken && loginButton.style">
            <div
              :style="
                'border-radius:' +
                loginButton.style.borderRadius +
                '%;cursor: pointer;background:' +
                loginButton.style.backgroundColor +
                ';color:' +
                loginButton.style.fontColor +
                ';font-size:' +
                loginButton.style.fontSize +
                'px;' +
                'width:' +
                loginButton.style.width +
                'px;height:' +
                loginButton.style.height +
                'px;line-height:' +
                loginButton.style.height +
                'px;top:' +
                loginButton.style.yAxis +
                'px;left:' +
                loginButton.style.xAxis +
                'px;position:absolute;text-align: center;text-decoration:' +
                (Number(loginButton.style.textDecoration) === 1 ? 'underline;' : 'none;') +
                'font-style:' +
                (Number(loginButton.style.italic) === 1 ? 'italic;' : 'none;') +
                'font-weight:' +
                (Number(loginButton.style.bold) === 1 ? 'bold;' : 'normal;')
              "
              @click.stop="clickLogin"
              @mouseenter="changeColor(1)"
              @mouseleave="changeColor(2)"
              v-show="loginButton.display != 2"
            >
              {{ loginButton.content }}
              <!-- <el-button
                  
                  type="primary"
                  @click.stop="clickLogin"
                >
                  {{ loginButton.content }}
              </el-button>-->
            </div>
            <!-- </el-dropdown> -->
          </template>
          <template v-if="isToken && loginButton.style">
            <el-dropdown
              :style="
                'top:' +
                loginButton.style.yAxis +
                'px;left:' +
                loginButton.style.xAxis +
                'px;cursor: pointer;position:absolute;'
              "
              placement="bottom"
              v-show="loginButton.display != 2"
            >
              <img
                :src="IMAGE_REL + userImg"
                alt
                height="48px"
                srcset
                style="border-radius: 50%"
                v-if="userImg != ''"
                width="48px"
              />
              <img
                :src="default_image"
                alt
                height="48px"
                srcset
                style="border-radius: 50%"
                v-else
                width="48px"
              />
              <el-dropdown-menu slot="dropdown" style="margin-top:0px;  text-align:center">
                <el-dropdown-item
                  :key="i"
                  @click.native="clickHandler(item)"
                  v-for="(item, i) in userList"
                >
                  <a>{{ item.content }}</a>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </div>
      </el-header>
    </template>

    <!-- 中间 -->
    <div
      :style="'height:' + topStyle.style.height + 'px;width:100%; background: rgba(255, 255, 255, 0);'"
      v-if="topStyle.position === 1"
    ></div>
    <el-main :style="'height:' + iframeHeight + 'px;'" style="overflow: hidden;">
      <iframe
        :src="iframeSrc"
        @load="iframeloaded"
        class="iframe"
        frameborder="0"
        id="iframe"
        ref="iframe"
        scrolling="auto"
      ></iframe>
    </el-main>
    <div
      :style="'height:' + botStyle.style.height + 'px;width:100%'"
      v-if="botStyle.position == 1 && botStyle.display == 1"
    ></div>

    <el-footer
      :style="
        'position:' +
        (botStyle.position === 1 ? 'fixed;bottom:0;width:100%' : 'static') +
        ';' +
        'background:' +
        botStyle.style.backgroundColor +
        ';' +
        'height:' +
        botStyle.style.height +
        'px;' +
        'display:' +
        botStyle.display
      "
      id="footerEditer"
      v-show="botStyle.display !== 2"
    >
      <div class="footer-content">
        <div class="footer">
          <template v-for="(item, i) in contanListBot">
            <div
              :key="i"
              :style="
                'position: absolute; box-sizing: border-box; overflow: hidden;' +
                (item.linkAddress ? 'cursor: pointer;' : '') +
                'height:' + item.style.height + 'px; ' +
                item.style ? styleConfig(item.style, item) :'' "
              v-if="item.elementType === 1"
            >
              <a
                @click="textClick(item)"
                style="cursor: pointer;"
                v-html="item.content"
                v-if="item.linkAddress != '' || (item.link && item.link != '') || (item.columnId&&item.columnId != '') "
              ></a>
              <div v-else v-html="item.content"></div>
            </div>

            <div v-if="item.elementType === 2" :key="i" :style="
              'width:' +
              item.style.width +
              'px;height:' +
              item.style.height +
              'px;left:' +
              item.style.xAxis +
              'px;top:' +
              item.style.yAxis +
              'px;font-size:' +
              item.style.fontSize +
              'px; border-radius:' +
              item.style.borderRadius +
              'px;position:absolute; overflow:hidden' 
            ">
              <a
                @click="textClick(item)"
                style="cursor: pointer;"
                v-if="item.linkAddress != '' || (item.link && item.link != '') || (item.columnId&&item.columnId != '') "
              >
                <img
                  :key="i"
                  :src="`${IMAGE_REL}${item.imagePath}`"
                  style="width: 100%； overflow:hidden"
                  :style=" 'border-radius:' +
              item.style.borderRadius +
              'px;position:absolute;' + item.linkAddress ? 'cursor: pointer;' : '' 
            "
                  alt
                  srcset
                />
              </a>

              <img
                :key="i"
                :src="`${IMAGE_REL}${item.imagePath}`"
                style="width: 100%"
                :style="'border-radius:' +
              item.style.borderRadius +
              'px;position:absolute;' + item.linkAddress ? 'cursor: pointer;' : ''
            "
                alt
                srcset
                v-else
              />
            </div>
          </template>
        </div>
      </div>
    </el-footer>

    <bindAccount :options="dialogVisible" @openIframeUrl="openIframeUrl" ref="bindAccount"></bindAccount>
  </div>
</template>

<script>
import {
  clientBackground,
  clientBackgroundDetail,
  clientGetMenu,
  clientElementList,
  clientLoginButton,
  //clientGetAppDetail,
  clientGetProfileImage,
  // clientGetApps,
  clientGetZhongtaiDetail,
  clientZhongtaiTopics,
  clientSession,
  clientLogout,
} from "@/assets/js/api/client";

import bindAccount from './components/bindAccount'
import { imageUrl as IMAGE_REL } from "@/assets/js/common/env.js";
import default_image from "@/assets/images/ImageElement/default_image.png";
import { oneloginUrl as LoginUrl } from "@/assets/js/common/env.js";
import Cookies from 'js-cookie'
//一定要引入这三个css，不然文本编辑器会出现不规则黑白几何图形
//这三个css可以在main.js中引入，也可以在具体使用的.vue文件中引入
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import 'quill/dist/quill.min.js'
export default {
  name: "client",
  inject: ["reload"],
  components: { bindAccount },
  data () {
    return {
      default_image,
      userImg: "",
      userList: [],
      iframeSrc: "",
      iframeHeight: '1000',
      iframeSrcOld: "",
      menuStyle: {},
      isToken: false,
      loginButton: {},
      IMAGE_REL,
      LoginUrl,
      contanListBot: [],
      contanListTop: [],
      menuList: [],
      topStyle: {
        backgroundCode: "",
        shadow: "",
        position: "", //父
        display: "",
        style: {
          backgroundColor: "#c3c3c3",
          height: "120",
        },
      },
      botStyle: {
        backgroundCode: "",
        shadow: "",
        position: "", //父
        display: "",
        style: {
          backgroundColor: "#c3c3c3",
          height: "120",
        },
      },
      tokenData: {
        accessToken: "",
        accountId: "",
      },
      menuFirst: '',
      menuSecord: '',
      dialogVisible: {
        visible: true,
        width: "600px",
        modalClose: true
      },
      windowScreenH: document.documentElement.clientHeight-10
    };
  },
  mounted () {
    this.getBackground();
    this.loginIframe();
    //监听浏览器返回
    if (window.history && window.history.pushState) {
      history.pushState(null, null, document.URL);
      window.addEventListener('popstate', this.goPageBefore, false);
    }
  },
  methods: {
    // iframe浏览器会退时返回页面
    goPageBefore() {
      this.refreashIframeSrc(this.iframeSrcOld)
    },
    styleConfig (style, item) {
      if (style) {
        let newStyleData = {
          position: "absolute",
          left: `${style.xAxis}px`,
          top: `${style.yAxis}px`,
          width: `${style.width}px`,
          height: `${style.height}px`,
          "word-break": "break-all",
          "border-radius": `${style.borderRadius}px`,
          "box-sizing": 'border-box',
          "color": `${style.fontColor}`,
          "font-size": `${style.fontSize}px`,
          "font-style": `${style.italic}`,
          "font-weight": `${style.bold}`,
          "font-family": `${style.font}`,
          "text-decoration": `${style.textDecoration}`,
          "overflow": "hidden",
          "cursor": item.linkAddress ? 'pointer' : ''
        }
        //console.log(newStyleData,style.borderRadius)
        return newStyleData
      } else {
        return ''
      }
    },
    changeUrl (url) {
      if (url && url != '') {
        window.open(url, "_blank");
        //this.iframeSrc = url;
      }
    },
    clickLogin () {
      this.iframeSrcOld = this.iframeSrc;
      // this.iframeSrc = LoginUrl;
      this.refreashIframeSrc(LoginUrl)
      // this.iframeUrl = '';

      // let vm = this;
      // let reloadIframe = setTimeout(() => {
      //     vm.iframeSrc = LoginUrl;
      //     clearTimeout(reloadIframe)
      // }, 10)
    },
    loginIframe () {
      let that = this;
      window.addEventListener(
        "message",
        function (data) {
          console.log('aaa::', data)

          if (data && data.data && data.data.type && data.data.type == "windowDoplin" && data.data.newData) {
            let newData = data.data.newData;
            that.tokenData = {
              accessToken: newData.accessToken,
              accountId: newData.accountId,
            };

            // that.iframeSrc = that.iframeSrcOld;
            that.refreashIframeSrc(that.iframeSrcOld)
            // this.iframeSrc = '';
            // let reloadIframe = setTimeout(() => {
            //     that.iframeSrc = that.iframeSrcOld;
            //     clearTimeout(reloadIframe)
            // }, 10)
            //  localStorage.setItem(
            //   "cookieToken",
            //   JSON.stringify(newData.accessToken)
            // );
            Cookies.set("cookieToken", newData.accessToken)
            localStorage.setItem(
              "DataOSDesktopTokenJson",
              JSON.stringify(that.tokenData)
            );
            location.reload()
          } else if (data && data.data && data.data.type && data.data.type == "loginOut") {
            // 退出登陆
            that.resetToken()
          } else if (data && data.data && data.data.type && data.data.type == "loginOpen") {
            // 打开登陆页面
            that.clickLogin()
          } else if (data && data.data && data.data.type && data.data.type == "selectNavId" && data.data.navId) {
            // 打开指定栏目页面
            this.openMenuByMessage(data.data.navId)
            window.parent.postMessage({
              data: data.data.data
            }, '*')
          }
        },
        false
      );
    },
    openMenuByMessage (navId) {
      let that = this

      let menulist = that.menuList
      let currni = ''
      let currnj = ''
      for (let i = 0; i < menulist.length; i++) {
        let item = menulist[i]
        if (item.id == navId) {
          // 选中颜色和跳转页面
          currni = i
          that.clickHandlerMenu(currni)
        } else if (item.children && item.children[0] && item.children[0].children && item.children[0].children.length > 0) {
          let childrenArry = item.children[0].children
          for (let j = 0; j < childrenArry.length; j++) {
            let children = childrenArry[j]
            if (children.id == navId) {
              // 选中颜色和跳转页面
              currni = i
              currnj = j
              that.clickHandlerMenu(currni, currnj)
            }
          }
        }
      }
      if (!currni && !currnj) {
        that.$message({
          type: "error",
          message: '栏目不存在'
        })
      }
    },
    changeColor (type) {
      const vm = this;
      let loginList = this.loginButton;
      if (type === 1) {
        loginList.style.fontColor = this.loginButton.style.fontHoverColor;
        let backgroundColor = loginList.style.backgroundColor;
        loginList.style.backgroundColor = this.loginButton.style.hoverColor;
        this.loginButton.style.hoverColor = backgroundColor;
      } else {
        loginList.style.fontColor = this.loginColor;
        let backgroundColor = loginList.style.backgroundColor;
        loginList.style.backgroundColor = this.loginButton.style.hoverColor;
        this.loginButton.style.hoverColor = backgroundColor;
      }
      vm.loginButton = loginList;
    },
    // 取消之前选中的栏目的样式
    changeUnclickMenuColor (menuI, menuJ, menulist, i, j) {

      let that = this
      if (menuI) {
        if (menuI - 1 == i) {
          if (menuJ && menuJ - 1 != j) {
            let item = menulist[menuI - 1].children[0].children[menuJ - 1]
            menulist[i].style.fontColor1 = menulist[i].style.checkedColor
            menulist[menuI - 1].children[0].children[menuJ - 1].style.fontColor1 = item.style.fontColor
            that.$set(that.menuList, menuI - 1, menulist[menuI - 1])
          } else {
            menulist[i].style.fontColor1 = menulist[i].style.checkedColor
            that.$set(that.menuList, menuI - 1, menulist[menuI - 1])
          }
        } else {
          if (menuJ) {
            let item = menulist[menuI - 1].children[0].children[menuJ - 1]
            menulist[menuI - 1].children[0].children[menuJ - 1].style.fontColor1 = item.style.fontColor
            menulist[menuI - 1].style.fontColor1 = menulist[menuI - 1].style.fontColor
            that.$set(that.menuList, menuI - 1, menulist[menuI - 1])
          } else {
            menulist[menuI - 1].style.fontColor1 = menulist[menuI - 1].style.fontColor
            menulist[i].style.fontColor1 = menulist[i].style.checkedColor
            that.$set(that.menuList, menuI - 1, menulist[menuI - 1])
          }
        }
      }
    },
    // 切换选中的栏目的样式
    changeCheckedMenuColor (i, j) {
      let that = this
      let menulist = that.menuList
      console.log('menuFirst>>>>' + that.menuFirst + '>>>>menuSecord>>>' + that.menuSecord)
      //之前选中的栏目的下标+1
      let menuI = that.menuFirst
      let menuJ = that.menuSecord
      // 当前选中栏目的样式
      if (menulist[i]) {
        if (menulist[i].children && menulist[i].children[0] && menulist[i].children[0].children && menulist[i].children[0].children[j]) {
          let item = menulist[i].children[0].children[j]
          menulist[i].style.fontColor1 = menulist[i].style.checkedColor
          menulist[i].children[0].children[j].style.fontColor1 = item.style.checkedColor
          that.$set(that.menuList, i, menulist[i])
        } else {
          menulist[i].style.fontColor1 = menulist[i].style.checkedColor
          that.$set(that.menuList, i, menulist[i])
        }
      }
      // 取消之前选中的栏目的样式
      that.changeUnclickMenuColor(menuI, menuJ, menulist, i, j)
      // 当前选中的栏目的下标+1
      that.menuSecord = j + 1
      that.menuFirst = i + 1
    },

    clickHandlerMenu (i, j) {

      let that = this

      let menulist = that.menuList
      let item = menulist[i]
      if (menulist[i]) {
        if (menulist[i].children && menulist[i].children[0] && menulist[i].children[0].children && menulist[i].children[0].children[j]) {
          item = menulist[i].children[0].children[j]
        }
      }
      console.log("clickHandlerMenu", i, j)
      that.changeCheckedMenuColor(i, j)
      that.clickHandler(item, i, j)


    },
    mouseOverLi (i, j) {
      let that = this
      let menulist = that.menuList
      if (menulist[i]) {
        if (menulist[i].children && menulist[i].children[0] && menulist[i].children[0].children && menulist[i].children[0].children[j]) {
          let item = menulist[i].children[0].children[j]
          menulist[i].children[0].children[j].style.fontColor1 = item.style.hoverColor
        } else {
          menulist[i].style.fontColor1 = menulist[i].style.hoverColor
        }
        if (menulist[i].children && menulist[i].children[0]) {
          menulist[i].children[0].showChildren = true;
        }
      }
      that.$set(that.menuList, i, menulist[i])
    },
    mouseLeaveLi (i, j) {
      let that = this
      let menulist = that.menuList
      let menuI = that.menuFirst
      let menuJ = that.menuSecord
      if (menulist[i]) {
        if (menulist[i].children && menulist[i].children[0] && menulist[i].children[0].children && menulist[i].children[0].children[j]) {
          let item = menulist[i].children[0].children[j]
          if (menuI && menuJ && menuI - 1 == i && menuJ - 1 == j) {
            menulist[i].children[0].children[j].style.fontColor1 = item.style.checkedColor
          } else {
            menulist[i].children[0].children[j].style.fontColor1 = item.style.fontColor
          }
        } else {
          if (menuI && menuI - 1 == i) {
            menulist[i].style.fontColor1 = menulist[i].style.checkedColor
          } else {
            menulist[i].style.fontColor1 = menulist[i].style.fontColor
          }
        }
        if (menulist[i].children && menulist[i].children[0]) {
          menulist[i].children[0].showChildren = false;
        }
      }
      that.$set(that.menuList, i, menulist[i])
    },
    jump (url) {
      window.open(url, "_blank");
    },
    tokenTest () {
      const vm = this;
      const tokenData = JSON.parse(
        localStorage.getItem("DataOSDesktopTokenJson")
      );
      if (tokenData && tokenData.accessToken) {
        clientSession({
          accessToken: tokenData.accessToken,
          accountId: tokenData.accountId,
        })
          .then((res) => {
            if (res.code == 200) {
              vm.getMenu(vm.topStyle, true);
              if (tokenData.accessToken && vm.topStyle.backgroundCode) {
                clientGetProfileImage({
                  token: tokenData.accessToken,
                  backgroundCode: vm.topStyle.backgroundCode,
                }).then((res) => {
                  if (res.code == 200) {
                    vm.isToken = true;
                    vm.userImg = res.result.imagePath;
                    vm.userList = res.result.children[0].children;
                  } else {
                    //console.log(res.message);
                  }
                });
              }

            } else {
              vm.isToken = false;
              vm.getMenu(vm.topStyle, false);
              // vm.$message({
              //   type: "error",
              //   message: res.message,
              // });
            }
          })
          .catch((err) => {
            vm.getMenu(vm.topStyle, false);
            return Promise.reject(err);
          });
      } else {
        vm.getMenu(vm.topStyle, false);
      }
    },
    resetToken () {
      const vm = this;
      let tokenData = JSON.parse(
        localStorage.getItem("DataOSDesktopTokenJson")
      );
      clientLogout({
        accessToken: tokenData.accessToken,
        accountId: tokenData.accountId,
      })
        .then((res) => {
          if (res.code === 200) {
            localStorage.removeItem("DataOSDesktopTokenJson");
            Cookies.remove("cookieToken");
            vm.isToken = false;
            vm.reload();
          } else {
            vm.$message({
              type: "error",
              message: res.message,
            });
          }
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    },
    clickHandler (data, i, j) {
      console.log("clickHandler::", data, i, j)
      // this.iframeHeight = data.iframeHigh
      const vm = this;
      const tokenData = JSON.parse(
        localStorage.getItem("DataOSDesktopTokenJson")
      );
      let tokenInfo = ''
      if (tokenData && tokenData.accessToken) {
        tokenInfo = "accountToken=" + tokenData.accessToken + "&accountId=" + tokenData.accountId + '&time=' + new Date().getTime();
      } else {
        tokenInfo = 'time=' + new Date().getTime()
      }
      let url;
      if (data.detailType === 5) {
        vm.resetToken();
        return false;
      }
      switch (data.linkType) {
        // 链接
        case 1:
          url = data.linkAddress;
          if (data.linkMode === 1) {
            // 桌面内打开
            // vm.refreashIframeSrc(url + `${url.indexOf('?') === -1 ? '?' : '&'}${tokenInfo}`)
            vm.refreashIframeSrc(url)
          } else {
            // 站外内打开
            // url = url + `${url.indexOf('?') === -1 ? '?' : '&'}${tokenInfo}`;
            window.open(url, "_blank");

          }
          break;
        // 应用
        case 2:
          if (localStorage.getItem("DataOSDesktopTokenJson")) {
            //this.getHandleIsBind(data)
            clientSession({
              accessToken: tokenData.accessToken,
              accountId: tokenData.accountId,
            })
              .then((res) => {
                if (res.code == 200) {
                  this.getHandleIsBind(data)
                } else {
                  //token验证失败 记录当前点击的是哪个
                  let currentMenu = {
                    data, i, j
                  }
                  localStorage.setItem("currentMenu", JSON.stringify(currentMenu))
                  vm.isToken = false;
                  vm.refreashIframeSrc(LoginUrl)
                  vm.$message({
                    type: "error",
                    message: res.message,
                  });

                }
              })
              .catch((err) => {
                return Promise.reject(err);
              });
          } else {
            //token验证失败 记录当前点击的是哪个
            let currentMenu = {
              data, i, j
            }
            localStorage.setItem("currentMenu", JSON.stringify(currentMenu))
            vm.iframeSrc = LoginUrl;
          }
          break;
        // 中台桌面
        case 3:
          clientZhongtaiTopics({
            accessToken: tokenData.accessToken,
            accountId: tokenData.accountId,
          })
            .then((res) => {
              if (res.code === 200) {
                // 查询详情
                clientGetZhongtaiDetail({ id: data.link })
                  .then((res) => {
                    if (res.code === 200) {
                      url = res.result.platformAddress + `${res.result.platformAddress.indexOf('?') === -1 ? '?' : '&'}${tokenInfo}`;
                      if (data.linkMode === 1) {
                        vm.refreashIframeSrc(url)
                      } else {
                        window.open(url, "_blank");
                      }
                    } else {
                      vm.$message({
                        type: "error",
                        message: res.message,
                      });
                    }
                  })
                  .catch((err) => {
                    return Promise.reject(err);
                  });
              } else {
                vm.$message({
                  type: "error",
                  message: res.message,
                });
              }
            })
            .catch((err) => {
              return Promise.reject(err);
            });
          break;
        default:
          break;
      }
      this.mouseLeaveLi(i, j)
    },

    textClick (data) {
      if (data.linkType == 4 && data.columnId != '') {
        this.getMenuType(data)
      } else {
        this.clickHandler(data)
      }
    },
    //根据 columnId 获取当前栏目的 i,j
    getMenuType (data) {
      let meunI, menuJ
      let columnIdArr = JSON.parse(data.columnId)
      if (this.menuList && this.menuList.length > 0) {
        this.menuList.forEach((item, i) => {
          if (item.elementCode == columnIdArr[0]) {
            meunI = i
            if (columnIdArr.length > 1) {
              item.children[0].children.forEach((items, j) => {
                if (items.elementCode == columnIdArr[1]) {
                  menuJ = j
                }
              })
            }
          }
        })
        console.log("clickHandlerMenu::", meunI, menuJ)
        this.clickHandlerMenu(meunI, menuJ)
      }
    },

    //点击应用判断是否需要绑定
    getHandleIsBind (data) {
      this.$refs.bindAccount.getHandleIsBind(data)
    },

    iframeloaded () {
      // iframe加载完成的方法
      let vm = this
      if (!vm.iframeSrc) {
        vm.iframeSrc = vm.iframe_src;
      }
    },
    //  跳转路径
    refreashIframeSrc (url) {
      if (url) {
        let vm = this
        vm.iframeSrc = '';
        vm.iframe_src = url
      }
    },

    noToken (msg) {
      //没有token,跳转登陆页
      const vm = this
      vm.isToken = false;
      // vm.iframeSrc = LoginUrl;
      vm.refreashIframeSrc(LoginUrl)
      // vm.iframeSrc = '';
      // let reloadIframe = setTimeout(() => {
      //   vm.iframeSrc = LoginUrl;
      //   clearTimeout(reloadIframe)
      // }, 10)
      vm.$message({
        type: "error",
        message: msg,
      });

    },
    openIframeUrl (linkAddress, data) {
      const vm = this
      const tokenData = JSON.parse(
        localStorage.getItem('DataOSDesktopTokenJson')
      )
      let tokenInfo = ''
      if (tokenData && tokenData.accessToken) {
        tokenInfo = "accountToken=" + tokenData.accessToken + "&accountId=" + tokenData.accountId + '&time=' + new Date().getTime();
      } else {
        tokenInfo = 'time=' + new Date().getTime()
      }

      let url = linkAddress + `${linkAddress.indexOf('?') === -1 ? '?' : '&'}${tokenInfo}`;

      if (data.accessType == 1) {
        // 带token跳转 linkAddress
        let url = linkAddress + `${linkAddress.indexOf('?') === -1 ? '?' : '&'}${tokenInfo}`;

        if (data.linkMode === 1) { // 当前页面打开 ，2 新窗口打开
          // vm.iframeSrc = url;
          vm.refreashIframeSrc(url)
          // vm.iframeSrc = '';
          // let reloadIframe = setTimeout(() => {
          //   vm.iframeSrc = url;
          //   clearTimeout(reloadIframe)
          // }, 10)
        } else {
          window.open(url, "_blank");
        }
      } else {
        // 不带token跳转
        url = linkAddress;
        if (data.linkMode === 1) {
          // vm.iframeSrc = url;
          vm.refreashIframeSrc(url)
          // vm.iframeSrc = '';
          // let reloadIframe = setTimeout(() => {
          //   vm.iframeSrc = url;
          //   clearTimeout(reloadIframe)
          // }, 10)
        } else {
          window.open(url, "_blank");
        }
      }


    },

    getBackground () {
      const vm = this;
      clientBackground()
        .then((res) => {
          if (res.code === 200) {
            vm.topStyle = res.result.records[0];
            vm.tokenTest();
            vm.botStyle = res.result.records[1];
            // 获取头部和底部的高度
            let topHeigth = vm.topStyle && vm.topStyle.style && vm.topStyle.style.height && vm.topStyle.display==1 && vm.topStyle.position==1 ? vm.topStyle.style.height : 0
            let fotHeigth = vm.botStyle && vm.botStyle.style && vm.botStyle.style.height && vm.botStyle.display==1 && vm.botStyle.position==1 ? vm.botStyle.style.height : 0
            let heigth = 0
            heigth = topHeigth+fotHeigth
            console.log('heigth',heigth)
            console.log('vm.windowScreenH',vm.windowScreenH)
            vm.iframeHeight = vm.windowScreenH-heigth
            vm.getDetail(vm.topStyle);
            vm.getDetail(vm.botStyle);
            vm.getContan(vm.topStyle, 1);
            vm.getContan(vm.botStyle, 2);
            vm.getLoginButton(vm.topStyle);
          } else {
            vm.$message({
              type: "error",
              message: res.message,
            });
          }
        })
        .catch((err) => {
          return Promise.reject(err);
        });


    },
    getContan (detail, type) {
      const vm = this;
      clientElementList({ backgroundCode: detail.backgroundCode })
        .then((res) => {
          if (res.code === 200) {
            if (type === 1) {
              vm.contanListTop = res.result.records;
            } else {
              vm.contanListBot = res.result.records;
            }
          } else {
            vm.$message({
              type: "error",
              message: res.message,
            });
          }
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    },
    getDetail (detail) {
      const vm = this;
      clientBackgroundDetail({ backgroundCode: detail.backgroundCode })
        .then((res) => {
          if (res.code === 200) {
            if (detail.type === 1) {
              vm.topDetail = res;
            } else {
              vm.botDetail = res;
            }
          } else {
            vm.$message({
              type: "error",
              message: res.message,
            });
          }
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    },
    getMenu (detail, hasToken) {


      const vm = this;
      let tokenData = "";
      let accessToken = "";
      //let tokenInfo = ''
      if (localStorage.getItem("DataOSDesktopTokenJson")) {
        tokenData = JSON.parse(
          localStorage.getItem("DataOSDesktopTokenJson")
        );
        accessToken = tokenData.accessToken;
      }


      if (!hasToken) {
        accessToken = ""
      }

      clientGetMenu({
        backgroundCode: detail.backgroundCode,
        token: accessToken
      })
        .then((res) => {
          if (res.code === 200) {
            this.menuStyle = res.result;
            this.menuList = res.result.children;


            //判断之前是否有点击过  
            let currentMenu = JSON.parse(localStorage.getItem("currentMenu"))
            if (currentMenu && currentMenu.i && currentMenu.i != null) {
              this.clickHandler(currentMenu.data, currentMenu.i, currentMenu.j)
              this.changeCheckedMenuColor(currentMenu.i, currentMenu.j)
              localStorage.removeItem("currentMenu")

            } else {
              if (this.menuList && this.menuList[0]) {
                if (this.menuList[0].children) {
                  this.clickHandler(this.menuList[0].children[0].children[0], 0, 0)
                  this.changeCheckedMenuColor(0, 0)
                } else {
                  this.clickHandler(this.menuList[0], 0)
                  this.changeCheckedMenuColor(0)
                }
              }
            }

            if (this.menuStyle.style) {
              this.menuStyle.style.lineHeight = this.menuStyle.style.height - this.menuStyle.style.padding * 2;
            }
          } else {
            vm.$message({
              type: "error",
              message: res.message,
            });
          }
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    },

    getLoginButton (detail) {
      const vm = this;
      clientLoginButton({ backgroundCode: detail.backgroundCode })
        .then((res) => {
          if (res.code === 200) {
            this.loginButton = res.result;
            this.loginColor = this.loginButton.style.fontColor;
          } else {
            vm.$message({
              type: "error",
              message: res.message,
            });
          }
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    },
  },
};
</script>
<style lang="less">
.el-popper[x-placement^='bottom'] .popper__arrow {
  top: 0 !important;
}
.el-popper .popper__arrow::after {
  border-width: 0 !important;
  border-bottom-color: none !important;
}
.el-popper .popper__arrow {
  border-width: 0 !important;
}
.el-dropdown-menu__item:hover {
  background: none !important;
  color: #4c4c4c !important;
  opacity: 0.5;
  padding: 0 20px;
}
.ql-font-serif {
  font-family: Georgia, Times New Roman, serif !important;
}
.ql-font-monospace {
  font-family: Monaco, Courier New, monospace;
}

.ql-container.ql-snow {
  font-size: 16px;
  border: none !important;
}

.ql-editor {
  overflow-y: hidden;
  p {
    color: #000 !important;
  }
}
.ql-snow .ql-editor a {
  text-decoration: none;
}
</style>
<style lang="less" scope>
.client {
  .iframe {
    width: 100%;
    height: 100%;
  }
  .header {
    position: relative;
    width: 1280px;
    margin: 0 auto;
    ul {
      width: fit-content;
      width: -webkit-fit-content;
      width: -moz-fit-content;
      display: flex;
      li {
        cursor: pointer;
      }
    }

    .dp-submenu {
      .levelOne {
        position: relative;
        .dp-submenu__icon-arrow {
          position: absolute;
          top: 50%;
          /* right: 20px; */
          margin-top: -7px;
          transition: transform 0.3s;
          font-size: 12px;
        }
      }
      &:hover {
        .dp-submenu__icon-arrow {
          transform: rotate(180deg);

          transition: transform 0.2s ease-in;
        }
      }
    }
  }
  .footer-content {
    width: 1280px;
    position: relative;
    margin: 0 auto;
    height: 100%;
    .footer {
      // width: 1280px;
      // position: relative;
      // margin: 0 auto;
      width: 1280px;
      left: 50%;
      height: 200px;
      margin-left: -640px;
      position: absolute;
      bottom: 0;
      overflow: hidden;
      pointer-events: none;
      div{
        pointer-events: all;
      }
    }
  }
}
</style>