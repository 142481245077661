<!--
 * @Author: your name
 * @Date: 2021-05-26 17:11:34
 * @LastEditTime: 2021-06-02 16:52:19
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \dolphin-web\src\views\home\home.vue
-->
<template>
  <div class="container">
    <headerEditer></headerEditer>
    <div class="top"></div>
    <el-main><div class="mainbody">webpage区域-组件</div></el-main>
    <div class="bot"></div>
    <bottomEditer></bottomEditer>
    <settingsPage></settingsPage>
    <el-dialog
      title="提示"
      :visible="dialogVisible"
      width="500px"
      :before-close="handleSure"
    >
      <div slot="title" class="dialog-header">
        <i class="el-icon-warning-outline"></i>
        <span>警告</span>
      </div>
      <p class="dialog-content">
        元素超出警戒线，可能会影响正常显示。为了更好的显示效果，建议将元
        素保持在警戒线以内
      </p>
      <span slot="footer" class="dialog-footer">
        <el-checkbox v-model="checked" @change="handleTips"
          >不再提示</el-checkbox
        >
        <el-button type="primary" @click="handleSure">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import bottomEditer from "@/views/manage/components/bottomEditer";
import headerEditer from "@/views/manage/components/headerEditer";
import settingsPage from "@/views/manage/components/settingsPage";
import { getBackground } from "@/assets/js/api/reset";

import { mapActions, mapState } from "vuex";

export default {
  name: "home",
  data() {
    return {
      checked: false,
      topPosition: {},
      botPosition: {},
    };
  },
  components: { headerEditer, bottomEditer, settingsPage },

  created() {
    this.getBackground();
  },
  computed: {
    ...mapState({
      safe_distance: (state) => state.safe_distance,
      safe_dialog_state: (state) => state.safe_dialog_state,
    }),
    dialogVisible() {
      console.log(this.safe_distance);
      return !this.safe_distance.is_safe && !this.safe_dialog_state;
    },
  },
  methods: {
    ...mapActions([
      "setBackgroundCodeTop",
      "setBackgroundCodeBottom",
      "safeDistanceChange",
      "safeDialogStateChange",
    ]),
    /** 获取背景值，存入vuex */
    getBackground() {
      const vm = this;
      getBackground()
        .then((res) => {
          if (res.code === 200) {
            const {
              result: { records = [] },
            } = res;
            const topCode = records.filter((item) => item.type === 1)[0]
              .backgroundCode;
              console.log(records.filter((item) => item.type === 1)[0])
            vm.setBackgroundCodeTop(topCode);
            const bottomCode = records.filter((item) => item.type === 2)[0]
              .backgroundCode;
            vm.setBackgroundCodeBottom(bottomCode);
          } else {
            vm.$message({
              type: "error",
              message: res.message,
            });
          }
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    },
    handleTips(val) {
      this.safeDialogStateChange(val);
    },
    handleSure() {
      this.safeDistanceChange({ is_safe: true });
    },
  },
};
</script>
<style lang="less">
.el-main {
  height: 1000px;
  padding: 0 !important;
  .mainbody {
    // width: 1200px;
    margin: 0 auto;
  }
}
.headerEditer {
  flex-shrink: 0;
}
</style>
<style lang="less" scoped>
/deep/ .dialog-header {
  display: flex;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  i {
    font-size: 24px;
    color: #fdb54a;
  }
  span {
    font-size: 18px;
    font-weight: 400;
    color: #262626;
    line-height: 32px;
    margin-left: 10px;
  }
}
/deep/ .dialog-content {
  font-size: 14px;
  font-weight: 400;
  color: #4c4c4c;
  line-height: 22px;
}
/deep/ .dialog-footer {
  display: flex;
  justify-content: space-between;
  align-content: center;
  flex-wrap: nowrap;
  align-items: center;
}
</style>
