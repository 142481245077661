import request from '../common/axios'

export function getBackground(params) {
  /** 获取背景 */
  return request({
    url: '/manage/background/list',
    method: 'get',
    params
  })
}
/** 获取背景详情 */
export function getBackgroundDetail(params) {
  return request({
    url: '/manage/background/detail',
    method: 'get',
    params
  })
}

export function getDeskTopSetting (params) {
  // 获取桌面配置
  return request({
    url: '/desktop/getSetting',
    method: 'GET',
    params: params
  })
}