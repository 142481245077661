/*
 * @Author: your name
 * @Date: 2021-05-26 17:11:34
 * @LastEditTime: 2021-06-02 16:40:07
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \dolphin-web\src\store\index.js
 */
import Vue from 'vue'
import Vuex from 'vuex'
// import state from './state'
// import mutations from './mutations'
// import actions from './actions'
Vue.use(Vuex)
export default new Vuex.Store({
  state: {
    backgroundConfig: [],//背景
    systemFrom: 'appManager', //token状态
    savetxt: '上次保存：两分钟前', //保存状态
    backgroundCodeTop: '', // 顶部的backgroundCode
    backgroundCodeBottom: '', // 底部的backgroundCode
    // 设置元素是否可见
    elementVisible: {
      top: false,
      menu: false,
      login: false,
      bottom: false
    },
    // 安全区域
    safe_distance: {
      safe_width: 1280, // 安全区域
      left_safe_distance: '', // 左边的安全距离
      right_safe_distance: '', // 右边距离屏幕左边的安全距离
      top_safe_distance: '', // 上菜单的安全高度
      bottom_safe_distance: '', // 下菜单的安全高度
      is_safe: true // 是否在安全区域内
    },
    safe_dialog_state: false, // 安全区域的提示弹框是否在展示出来 false为展示  true表示勾选上不展示
  },
  mutations: {
    headSet(state, obj) {
      state.backgroundConfig = obj
    },
    addSystemFrom(state, val) {
      state.systemFrom = val
    },
    setBackgroundCodeTop(state, val) {
      state.backgroundCodeTop = val;
    },
    setBackgroundCodeBottom(state, val) {
      state.backgroundCodeBottom = val;
    },
    elementVisibleChange(state, val) {
      state.elementVisible = val;
    },
    safeDistanceChange(state, val) {
      Object.assign(state.safe_distance, val)
    },
    safeDialogStateChange(state, val) {
      state.safe_dialog_state = val;
    }
  },
  getters: {
    getHeader(state) {
      return state.backgroundConfig
    }
  },
  actions: {
    headSet(context, num) {
      context.commit('', num)
    },
    getSystemFrom({ commit }, from) {
      // 获取系统来源（用作确认跳转来源为应用管理或无数据页面）
      commit('addSystemFrom', from)
    },
    setBackgroundCodeTop(context, val) {
      context.commit('setBackgroundCodeTop', val);
    },
    setBackgroundCodeBottom(context, val) {
      context.commit('setBackgroundCodeBottom', val);
    },
    elementVisibleChange(context, val) {
      context.commit('elementVisibleChange', val);
    },
    safeDistanceChange(context, val) {
      context.commit('safeDistanceChange', val);
    },
    safeDialogStateChange(context, val) {
      context.commit('safeDialogStateChange', val);
    }
  },
  modules: {

  }
})
