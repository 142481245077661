var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"client"},[(_vm.topStyle.style.backgroundCode != '')?[_c('el-header',{directives:[{name:"show",rawName:"v-show",value:(_vm.topStyle.display !== 2),expression:"topStyle.display !== 2"}],style:('backdrop-filter: blur(' +
        _vm.topStyle.blurry +
        'px);background:' +
        _vm.topStyle.style.backgroundColor +
        ';' +
        'height:' +
        _vm.topStyle.style.height +
        'px;' +
        'position:' +
        (_vm.topStyle.position === 1 ? 'fixed;top:0;width:100%' : 'static') +
        ';' +
        'box-shadow:' +
        _vm.topStyle.shadow +
        ';'),attrs:{"id":"headerEditer"}},[_c('div',{staticClass:"header"},[(_vm.menuStyle.style )?[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.menuStyle.display != 2),expression:"menuStyle.display != 2"}],style:('backgroundColor:' +
               ( _vm.menuList.length > 1   ? _vm.menuList[0].style.backgroundColor : _vm.menuStyle.style.backgroundColor )  +
              '; height:' +
              (  _vm.menuList.length > 1   ? _vm.menuList[0].style.height : _vm.menuStyle.style.height) +
              'px; padding-left: ' + (   _vm.menuStyle.style.height/2) +
              'px; padding-right: ' + (   _vm.menuStyle.style.height/2) +
              'px; position:absolute; min-height:10px;min-width:10px;left:' +
              _vm.menuStyle.style.xAxis +
              'px;top:' +
              _vm.menuStyle.style.yAxis +
              'px;line-height:' +
              _vm.menuStyle.style.lineHeight +
              'px; z-index: 2222')},[_vm._l((_vm.menuList),function(item,i){return [(item.children)?_c('div',{key:i,staticClass:"dp-submenu",staticStyle:{"display":"inline-block"}},[_c('span',{staticClass:"levelOne",style:('height:' +  item.style.height + 
                    'px; line-height:' + item.style.height +
                    'px; z-index: 2222; ' + 
                    'display: inline-block;cursor: pointer; color:' +
                    (item.style.fontColor1?item.style.fontColor1:item.style.fontColor) +
                    ';font-size:' +
                    item.style.fontSize +
                    'px;text-decoration:' +
                    (Number(item.style.textDecoration) === 1 ? 'underline;' : 'none;') +
                    'font-style:' +
                    (Number(item.style.italic) === 1 ? 'italic;' : 'none;') +
                    'font-weight:' +
                    (Number(item.style.bold) === 1 ? '800;' : 'normal;') +
                    'margin-right:'+
                    (_vm.menuList.length > 1 && i != _vm.menuList.length-1 ? item.style.padding :'') +'px;'),on:{"click":function($event){return _vm.clickHandlerMenu(i)},"mouseleave":function($event){return _vm.mouseLeaveLi(i)},"mouseover":function($event){return _vm.mouseOverLi(i)}}},[_vm._v(" "+_vm._s(item.content)+" "),_c('i',{staticClass:"dp-submenu__icon-arrow el-icon-arrow-down"})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(item.children[0].showChildren),expression:"item.children[0].showChildren"}],style:('position: absolute; background:'+
                  item.style.backgroundColor +
                  '; margin-left: -' + 
                  item.children[0].style.padding/2 + 'px;')},_vm._l((item.children[0].children),function(subMenu,j){return _c('div',{key:j,staticClass:"el-menu-item",style:('clear: both;cursor: pointer;display: block;color:' +
                        (subMenu.style.fontColor1?subMenu.style.fontColor1:subMenu.style.fontColor) +
                        ';font-size:' +
                        subMenu.style.fontSize +
                        'px;' +
                        'text-decoration:' +
                        (Number(subMenu.style.textDecoration) === 1
                          ? 'underline;'
                          : 'none;') +
                        'font-style:' +
                        (Number(subMenu.style.italic) === 1 ? 'italic;' : 'none;') +
                        'font-weight:' +
                        (Number(subMenu.style.bold) === 1 ? '800;' : 'normal;')+
                        'height:' +
                        (subMenu.style.height==0?10:subMenu.style.height) +
                        'px;line-height:'+
                        (subMenu.style.height==0?10:subMenu.style.height) +
                        'px;background:'+ subMenu.style.backgroundColor +
                        ';padding-left: '+ subMenu.style.padding + 'px;'+
                        ';padding-right: '+ subMenu.style.padding + 'px; box-shadow: 0px 2px 4px 0px rgb(0 0 0 / 15%);'),on:{"click":function($event){return _vm.clickHandlerMenu(i,j)},"mouseleave":function($event){return _vm.mouseLeaveLi(i,j)},"mouseover":function($event){return _vm.mouseOverLi(i,j)}}},[_vm._v(_vm._s(subMenu.content))])}),0)]):_c('div',{key:i,style:('height:' +  item.style.height + 
                    'px; line-height:' + item.style.height +
                    'px; z-index: 2222; ' + 
                    'display: inline-block;cursor: pointer; color:' +
                    (item.style.fontColor1?item.style.fontColor1:item.style.fontColor) +
                    ';font-size:' +
                    item.style.fontSize +
                    'px;text-decoration:' +
                    (Number(item.style.textDecoration) === 1 ? 'underline;' : 'none;') +
                    'font-style:' +
                    (Number(item.style.italic) === 1 ? 'italic;' : 'none;') +
                    'font-weight:' +
                    (Number(item.style.bold) === 1 ? '800;' : 'normal;') +
                    'margin-right:'+
                    (_vm.menuList.length > 1 && i != _vm.menuList.length-1 ? item.style.padding :'') +'px;'),on:{"click":function($event){return _vm.clickHandlerMenu(i)},"mouseleave":function($event){return _vm.mouseLeaveLi(i)},"mouseover":function($event){return _vm.mouseOverLi(i)}}},[_vm._v(_vm._s(item.content))])]})],2)]:_vm._e(),_vm._l((_vm.contanListTop),function(item,i){return _c('div',{key:i},[(item.elementType === 1)?_c('div',{key:i,style:('position: absolute; box-sizing: border-box; overflow: hidden; ' +
              (item.linkAddress ? 'cursor: pointer;' : '')  +
              'height:' + item.style.height + 'px; ' +
              item.style ? _vm.styleConfig(item.style, item) :'')},[(item.linkAddress != '' || (item.link && item.link != '') || (item.columnId&&item.columnId != '') )?_c('a',{staticStyle:{"cursor":"pointer"},domProps:{"innerHTML":_vm._s(item.content)},on:{"click":function($event){return _vm.textClick(item)}}}):_c('div',{domProps:{"innerHTML":_vm._s(item.content)}})]):_vm._e(),(item.elementType === 2)?_c('div',{style:('position:absolute; overflow:hidden;' + 
              item.style ? _vm.styleConfig(item.style ,item) :'')},[(item.linkAddress != '' || (item.link && item.link != '') || (item.columnId&&item.columnId != '') )?_c('a',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.textClick(item)}}},[_c('img',{key:i,staticStyle:{"width":"100%"},attrs:{"src":("" + _vm.IMAGE_REL + (item.imagePath)),"alt":"","srcset":""}})]):_c('img',{key:i,staticStyle:{"width":"100%"},attrs:{"src":("" + _vm.IMAGE_REL + (item.imagePath)),"alt":"","srcset":""}})]):_vm._e()])}),(!_vm.isToken && _vm.loginButton.style)?[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.loginButton.display != 2),expression:"loginButton.display != 2"}],style:('border-radius:' +
              _vm.loginButton.style.borderRadius +
              '%;cursor: pointer;background:' +
              _vm.loginButton.style.backgroundColor +
              ';color:' +
              _vm.loginButton.style.fontColor +
              ';font-size:' +
              _vm.loginButton.style.fontSize +
              'px;' +
              'width:' +
              _vm.loginButton.style.width +
              'px;height:' +
              _vm.loginButton.style.height +
              'px;line-height:' +
              _vm.loginButton.style.height +
              'px;top:' +
              _vm.loginButton.style.yAxis +
              'px;left:' +
              _vm.loginButton.style.xAxis +
              'px;position:absolute;text-align: center;text-decoration:' +
              (Number(_vm.loginButton.style.textDecoration) === 1 ? 'underline;' : 'none;') +
              'font-style:' +
              (Number(_vm.loginButton.style.italic) === 1 ? 'italic;' : 'none;') +
              'font-weight:' +
              (Number(_vm.loginButton.style.bold) === 1 ? 'bold;' : 'normal;')),on:{"click":function($event){$event.stopPropagation();return _vm.clickLogin($event)},"mouseenter":function($event){return _vm.changeColor(1)},"mouseleave":function($event){return _vm.changeColor(2)}}},[_vm._v(" "+_vm._s(_vm.loginButton.content)+" ")])]:_vm._e(),(_vm.isToken && _vm.loginButton.style)?[_c('el-dropdown',{directives:[{name:"show",rawName:"v-show",value:(_vm.loginButton.display != 2),expression:"loginButton.display != 2"}],style:('top:' +
              _vm.loginButton.style.yAxis +
              'px;left:' +
              _vm.loginButton.style.xAxis +
              'px;cursor: pointer;position:absolute;'),attrs:{"placement":"bottom"}},[(_vm.userImg != '')?_c('img',{staticStyle:{"border-radius":"50%"},attrs:{"src":_vm.IMAGE_REL + _vm.userImg,"alt":"","height":"48px","srcset":"","width":"48px"}}):_c('img',{staticStyle:{"border-radius":"50%"},attrs:{"src":_vm.default_image,"alt":"","height":"48px","srcset":"","width":"48px"}}),_c('el-dropdown-menu',{staticStyle:{"margin-top":"0px","text-align":"center"},attrs:{"slot":"dropdown"},slot:"dropdown"},_vm._l((_vm.userList),function(item,i){return _c('el-dropdown-item',{key:i,nativeOn:{"click":function($event){return _vm.clickHandler(item)}}},[_c('a',[_vm._v(_vm._s(item.content))])])}),1)],1)]:_vm._e()],2)])]:_vm._e(),(_vm.topStyle.position === 1)?_c('div',{style:('height:' + _vm.topStyle.style.height + 'px;width:100%; background: rgba(255, 255, 255, 0);')}):_vm._e(),_c('el-main',{staticStyle:{"overflow":"hidden"},style:('height:' + _vm.iframeHeight + 'px;')},[_c('iframe',{ref:"iframe",staticClass:"iframe",attrs:{"src":_vm.iframeSrc,"frameborder":"0","id":"iframe","scrolling":"auto"},on:{"load":_vm.iframeloaded}})]),(_vm.botStyle.position == 1 && _vm.botStyle.display == 1)?_c('div',{style:('height:' + _vm.botStyle.style.height + 'px;width:100%')}):_vm._e(),_c('el-footer',{directives:[{name:"show",rawName:"v-show",value:(_vm.botStyle.display !== 2),expression:"botStyle.display !== 2"}],style:('position:' +
      (_vm.botStyle.position === 1 ? 'fixed;bottom:0;width:100%' : 'static') +
      ';' +
      'background:' +
      _vm.botStyle.style.backgroundColor +
      ';' +
      'height:' +
      _vm.botStyle.style.height +
      'px;' +
      'display:' +
      _vm.botStyle.display),attrs:{"id":"footerEditer"}},[_c('div',{staticClass:"footer-content"},[_c('div',{staticClass:"footer"},[_vm._l((_vm.contanListBot),function(item,i){return [(item.elementType === 1)?_c('div',{key:i,style:('position: absolute; box-sizing: border-box; overflow: hidden;' +
              (item.linkAddress ? 'cursor: pointer;' : '') +
              'height:' + item.style.height + 'px; ' +
              item.style ? _vm.styleConfig(item.style, item) :'')},[(item.linkAddress != '' || (item.link && item.link != '') || (item.columnId&&item.columnId != '') )?_c('a',{staticStyle:{"cursor":"pointer"},domProps:{"innerHTML":_vm._s(item.content)},on:{"click":function($event){return _vm.textClick(item)}}}):_c('div',{domProps:{"innerHTML":_vm._s(item.content)}})]):_vm._e(),(item.elementType === 2)?_c('div',{key:i,style:('width:' +
            item.style.width +
            'px;height:' +
            item.style.height +
            'px;left:' +
            item.style.xAxis +
            'px;top:' +
            item.style.yAxis +
            'px;font-size:' +
            item.style.fontSize +
            'px; border-radius:' +
            item.style.borderRadius +
            'px;position:absolute; overflow:hidden')},[(item.linkAddress != '' || (item.link && item.link != '') || (item.columnId&&item.columnId != '') )?_c('a',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.textClick(item)}}},[_c('img',{key:i,staticStyle:{"width":"100%； overflow:hidden"},style:('border-radius:' +
            item.style.borderRadius +
            'px;position:absolute;' + item.linkAddress ? 'cursor: pointer;' : ''),attrs:{"src":("" + _vm.IMAGE_REL + (item.imagePath)),"alt":"","srcset":""}})]):_c('img',{key:i,staticStyle:{"width":"100%"},style:('border-radius:' +
            item.style.borderRadius +
            'px;position:absolute;' + item.linkAddress ? 'cursor: pointer;' : ''),attrs:{"src":("" + _vm.IMAGE_REL + (item.imagePath)),"alt":"","srcset":""}})]):_vm._e()]})],2)])]),_c('bindAccount',{ref:"bindAccount",attrs:{"options":_vm.dialogVisible},on:{"openIframeUrl":_vm.openIframeUrl}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }