import request from '../common/axios'

/** 保存顶部 */
export function updateBackground(data) {
  return request({
    url: 'manage/background/update/' + data.backgroundCode,
    method: 'put',
    data
  })
}
/** 保存栏目 */
export function updateContainer(data) {
  return request({
    url: 'manage/container/update/' + data.containerCode,
    method: 'put',
    data
  })
}