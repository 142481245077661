/*
 * @Author: your name
 * @Date: 2021-05-18 15:25:17
 * @LastEditTime: 2021-05-18 15:26:27
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \dolphin-web\src\assets\js\api\application.js
 */
import request from '../common/axios';
// 背景列表
export function clientBackground(params) {
    return request({
        url: `client/background/list`,
        method: 'get',
        params
    })
}
// 背景详情
export function clientBackgroundDetail(params) {
    return request({
        url: `client/background/detail`,
        method: 'get',
        params
    })
}
// 获取菜单
export function clientGetMenu(params) {
    return request({
        url: `client/container/getMenu`,
        method: 'get',
        params
    })
}
// 获取容器
export function clientElementList(params) {
    return request({
        url: `client/element/list`,
        method: 'get',
        params
    })
}
// 获取登录按钮
export function clientLoginButton(params) {
    return request({
        url: `client/element/getLoginButton`,
        method: 'get',
        params
    })
}
// 获取应用详情
export function clientGetAppDetail(params) {
    return request({
        url: `app/getAppDetail`,
        method: 'get',
        params
    })
}

// 获取app接口
export function clientGetApps(params) {
    return request({
        url: `app/getApps`,
        method: 'get',
        params
    })
}

// 获取中台桌面详情
export function clientGetZhongtaiDetail(params) {
    return request({
        url: `app/getZhongtaiDetail`,
        method: 'get',
        params
    })
}

// 获取中台桌面列表
export function clientZhongtaiTopics(params) {
    return request({
        url: `app/getZhongtaiTopics`,
        method: 'get',
        params
    })
}

// token验证
export function clientSession(params) {
    return request({
        url: `account/session`,
        method: 'get',
        params
    })
}

// 获取头像图片
export function clientGetProfileImage(params) {
    return request({
        url: `client/element/getProfileImage`,
        method: 'get',
        params
    })
}

// 退出登录
export function clientLogout(data) {
    return request({
        url: `/account/logout`,
        method: 'post',
        data
    })
}