/*
 * @Author: your name
 * @Date: 2021-05-18 13:57:40
 * @LastEditTime: 2021-05-26 15:32:34
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \dolphin-web\src\assets\js\api\container.js
 */
import request from '../common/axios';

/**
 * @description: 获取一级栏目
 * @param {*} param
 * @return {*}
 */
export function getMenu(params) {
  return request({
    url: `manage/container/getMenu`,
    method: 'get',
    params
  })
}

/**
 * @description: 获取容器相关样式
 * @param {*} params
 * @return {*}
 */
export function getMenuStyles(params) {
  return request({
    url: `manage/container/getStyles`,
    method: 'get',
    params
  })
}

/**
 * @description: 获取容器列表
 * @param {*} params
 * @return {*}
 */
export function getContainerList(params) {
  return request({
    url: `manage/container/list`,
    method: 'get',
    params
  })
}

/**
 * @description: 获取容器样式
 * @param {*} params
 * @return {*}
 */
export function getContainerStyle(params) {
  return request({
    url: `manage/container/getStyles`,
    method: 'get',
    params
  })
}

/**
 * @description: 更新容器信息
 * @param {*} data
 * @return {*}
 */
export function updateContainer(data) {
  return request({
    url: `manage/container/update/${data.containerCode}`,
    method: 'put',
    data: data.containerUpdateDto
  })
}

/**
 * @description: 更新容器样式
 * @param {*} data
 * @return {*}
 */
export function updateContainerStyle(data) {
  return request({
    url: `manage/container/updateStyles`,
    method: 'put',
    data
  })
}
