<!--
 * @Author: your name
 * @Date: 2021-05-11 16:38:44
 * @LastEditTime: 2021-06-22 17:37:24
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \dolphin-web\src\components\ImageElement\index.vue
-->
<template>
	<!-- <div
    draggable
    class="ImageElement"
    ref="ImageElement"
    :style="draggableStyle"
    @dragstart="handleDragStart"
    @dragend="handleDragEnd"
    @dragover="handleDragOver"
  > -->
	<!-- <div class="text-event1"> -->
	<!-- <vue-draggable-resizable
    :w="settingData.style.width"
    :h="settingData.style.height"
    :x="settingData.style.xAxis"
    :y="settingData.style.yAxis"
    :min-width="50"
    :min-height="50"
    :parent="true"
    :grid="[10, 10]"
    class-name="dragging1"
    @dragstop="onDrag"
    @resizing="onResize"
    @mouseleave="handleBoxMouseleave"
    @resizestop="onResizstop"
  > -->
	<vue-draggable-resizable
		ref="ImageElement"
		:w="settingData.style.width"
		:h="settingData.style.height"
		:x="settingData.style.xAxis"
		:y="settingData.style.yAxis"
		:min-width="10"
		:min-height="10"
		:grid="[1, 1]"
		class-name="dragging1"
		@dragstop="onDrag"
		@resizing="onResize"
		@resizestop="onResizstop"
		@activated="onActivated"
		@deactivated="onDeactivated" 
		
	>
		<!-- <img
      :src="defaultSrc"
      style="width: 100%; height: 100%"
      :style="borderStyle"
      @mouseenter="switchEdit(1)"
      @mouseleave="handleBoxMouseleave(1)"
    /> -->
		<div :style="
				'width:100%; height:100%; overflow: hidden; border-radius: ' +
				settingData.style.borderRadius +
				'px'
			" @mouseenter="switchEdit(1)"
			@mouseleave="handleBoxMouseleave(1)">
			<img
			:src="defaultSrc"
			:style="
				'width: 100%; margin-bottom: 5px; border-radius: ' +
				settingData.style.borderRadius +
				'px'
			"
			
		/>
			</div>
		
		<!-- <div style="width: 100%; height: 100%" @mouseleave="handleBoxMouseleave(2)"> -->
		<div
			class="edit-menu"
			:class="this.source === 'header' ? 'edit-menu-position' : ''"
			v-show="showEditMenu"
			:style="zIndexStyle"
			@mouseenter="switchEdit(1)"
			@mouseleave="handleBoxMouseleave(2)"
			@click="onDeactivated"
		>
			<div class="edit-box">
				<div class="angle-box">
					<el-input
						placeholder="圆角"
						v-model="settingData.style.borderRadius"
						:max="100"
						:min="0"
						@change="handleChange('border')"
						@input.native="borderRadiusInput"
						@focus="onDeactivated"
					>
						<template slot="prepend">
							<img :src="iconFillet" />
						</template>
						<template slot="append">px</template>
					</el-input>
				</div>
				<div @click="handleUploadImage">
					{{ isReUpload ? '重新上传' : '上传图片' }}
				</div>
				<div @click="handleDeleteImage">删除</div>
				<div @click="jumpSetting">跳转设置</div>
			</div>
			<div class="div-line"></div>
			<div class="link-box">
				<el-form ref="imageForm" label-width="40" :model="imageForm" :rules="imageRules">
					<!-- <el-form-item label="" prop="linkAddress">
						<el-input
							v-model="imageForm.linkAddress"
							placeholder="跳转地址"
							@change="handleChange('link')"
						>
							<img slot="prefix" :src="icon_text_link" />
						</el-input>
					</el-form-item> -->
					<el-row>
						<el-col :span="10">
							<el-form-item label="W" prop="width" style="width:100%">
								<el-input-number v-model="imageForm.width" controls-position="right" @change="ImgChange('width')" :min="10" :max="1000" 
								@input.native="onInputClick" @focus="onDeactivated"></el-input-number>
							</el-form-item>
						</el-col>
						<el-col :span="4">	
							<i @click="changeLockType(false)" class="iconStyle iconfont icon-suoding" v-if="lockingType"></i>
							<i @click="changeLockType(true)" class="iconStyle iconfont icon-jiesuo" v-else></i>
						</el-col>
						<el-col :span="10">
							<el-form-item label="H" prop="height" style="width:100%">
								<el-input-number v-model="imageForm.height" controls-position="right" @change="ImgChange('height')" :min="10" :max="1000" 
								@input.native="onInputClick" @focus="onDeactivated"></el-input-number>
							</el-form-item>
						</el-col>
					</el-row>
				</el-form>
			</div>
		</div>
		<input
			accept=".jpg, .jpeg, .png, .bmp, .gif"
			ref="uploadingImg"
			name="filename"
			type="file"
			v-show="false"
		/>
		<!-- </div> -->
		<jumpSetting
      :options="jumpSettingOptions"
      @changeJumpSettingData="changeJumpSettingData"
      v-if="jumpSettingOptions.visible"
    ></jumpSetting>
	</vue-draggable-resizable>
	<!-- </div> -->
</template>
<script>
import { mapActions, mapState } from 'vuex';

import icon_fillet from '@/assets/images/ImageElement/icon_fillet.png';
import default_image from '@/assets/images/ImageElement/default_image.png';
import icon_text_link from '@/assets/images/TextElement/icon_text_link.png';

import { postImage } from '@/assets/js/api/elements.js';
// import DraggableBorder from "./../DraggableBorder/index.vue";
import { imageUrl as IMAGE_REL } from '@/assets/js/common/env.js';
import VueDraggableResizable from 'vue-draggable-resizable';
import 'vue-draggable-resizable/dist/VueDraggableResizable.css';
import jumpSetting from './components/jumpSetting';
export default {
	name: 'ImageElement',
	props: ['elementData', 'source'],
	components: { VueDraggableResizable, jumpSetting },
	data() {
		return {
			defaultSrc: '',
			showEditMenu: false,
			showImg: false,
			showEdit: true,
			iconFillet: icon_fillet,
			default_image,
			icon_text_link,
			linkAddress: '',
			isReUpload: false,
			IMAGE_REL,
			width: 0,
			height: 0,
			x: 0,
			y: 0,
			imageForm: {
				linkAddress: '',
				width:'',
				height:''
			},
			imageRules: {
				linkAddress: {
					pattern: /http[s]{0,1}:\/\/([\w.]+\/?)\S*/,
					message: '请输入以http://或者https://开头链接',
					trigger: 'change',
				},
			},
			jumpSettingOptions: {
        visible: false
			},
			lockingType:false
		};
	},
	computed: {
		...mapState(['safe_distance']),
		draggableStyle() {
			return {
				width: `${this.settingData.style.width}px`,
				height: `${this.settingData.style.height}px`,
				left: `${this.settingData.style.xAxis}px`,
				top: `${this.settingData.style.yAxis}px`,
			};
		},
		settingData() {
			return { ...this.elementData };
		},
		styleConfig() {
			return `
        width: ${this.settingData.style.width}px;
        height: ${this.settingData.style.height}px;
      `;
		},
		borderStyle() {
			return {
				borderRadius: `${this.settingData.style.borderRadius}%`,
			};
		},
		zIndexStyle() {
			let topVal = '';
			if (this.source === 'header') {
				topVal = -16;
			} else {
				topVal = -99;
			}
			return {
				zIndex: `${this.settingData.style.zIndex}`,
				top: `${topVal}px`,
			};
		},
	},
	watch: {
    "settingData.style.width": {
      handler () {
        this.showImgWH();
      },
		},
		"settingData.style.height": {
      handler () {
        this.showImgWH();
      },
		},
	},
	mounted() {
		const imagePath = this.elementData.imagePath;
		this.defaultSrc = imagePath
			? `${this.IMAGE_REL}${this.elementData.imagePath}`
			: this.default_image;
		this.imageForm.linkAddress = this.settingData.linkAddress;
		const uploadingImgRef = this.$refs['uploadingImg'];
		const vm = this;
		uploadingImgRef.addEventListener(
			'change',
			function () {
				return vm.uploadingImg(this);
			},
			true
		);
		document.body.addEventListener(
			'click',
			() => {
				this.showEditMenu = false;
			},
			false
		);
		this.showImgWH()

		//当前页面监视键盘输入
    
    // document.onkeydown = function (e) {
    //   console.log('键盘输入了', e, vm.$refs.ImageElement)
    //   //事件对象兼容
    //   // let e1 = e || event || window.event || arguments.callee.caller.arguments[0]
    //   // //键盘按键判断:左箭头-37;上箭头-38；右箭头-39;下箭头-40
    //   // console.log('键盘输入了2', e1, vm.$refs.ImageElement)
    //   //                   vm.keydownHandle(e)
		// }
		
	},
	methods: { 
		...mapActions(['safeDistanceChange']),	
		
		onInputClick(e){
			console.log("aaa:",e.target.value)
			e.target.value = e.target.value.replace(/[^\d]/g,'')
			if(parseInt(e.target.value) > 1000){
				e.target.value = 1000
			} else if(e.target.value == ''){
				//e.target.value = 10
			}
		},
		borderRadiusInput(e){
			e.target.value = e.target.value.replace(/[^\d]/g,'')
			
		},

		showImgWH(){
			this.imageForm.width = this.settingData.style.width
			this.imageForm.height = this.settingData.style.height
		},
		ImgChange(type){
			console.log("ImgChange:", this.imageForm.width)
			if(!this.imageForm.width || typeof(this.imageForm.width) == "undefined" || this.imageForm.width == ''  ){
				this.imageForm.width = 10
			}else if(!this.imageForm.height || typeof(this.imageForm.height) == "undefined" || this.imageForm.height == ''  ){
				this.imageForm.height = 10
			}

			console.log("ImgChange:", this.imageForm.width)
			if(this.lockingType){
				if(type=='width'){
					let pec = this.imageForm.width /  this.settingData.style.width
					this.settingData.style.width = Math.round(this.imageForm.width)
					this.settingData.style.height = Math.round(this.imageForm.height * pec)
				}else{
					let pec = this.imageForm.height / this.settingData.style.height
					this.settingData.style.height = Math.round(this.imageForm.height)
					this.settingData.style.width = Math.round(this.imageForm.width * pec)
				}
			}else{
				if(type=='width'){
					this.settingData.style.width = Math.round(this.imageForm.width)
				}else{
					this.settingData.style.height = Math.round(this.imageForm.height)
				}
			}
			this.$emit('handleChange', this.settingData);
		},
		numberChange() {
			let that = this;
			let maxNum = 100;
			//转换数字类型
			let val = that.settingData.style.borderRadius;
			if (isNaN(that.settingData.style.borderRadius)) {
				that.settingData.style.borderRadius = 100;
			}
			//重新渲染
			this.$nextTick(() => {
				//比较输入的值和最大值，返回小的
				let num = Math.min(Number(val), maxNum);
				if (isNaN(num)) {
					num = 100;
				}
				//输入负值的情况下， = 0（可根据实际需求更该）
				if (num < 0) {
					that.settingData.style.borderRadius = 0;
				} else {
					//反之
					that.settingData.style.borderRadius = num;
				}
				this.$emit('handleChange', this.settingData);
			});
		},
	
		/** */
		switchEdit() {
			let that = this;
			that.showImg = true;
			that.showEdit = true;
			that.showEditMenu = true;
			// 隐藏父级工具栏
			that.$emit('hiddenTool');
		},
		handleBoxMouseleave() {
			let that = this;
			// if (type == 1) {
			//   that.showImg = false;
			// } else {
			//   that.showEdit = false;
			// }
			// if (!that.showImg && !that.showEdit) {
			//   that.showEditMenu = false;
			// }
			that.showImg = false;
			that.showEdit = false;
			that.showEditMenu = false;
		},
		onResize(x, y, width, height) {
			// const { bottom_safe_distance } = this.safe_distance;
			// const dragRef = this.$refs["ImageElement"];
			// const { top } = dragRef;
			// if (height + top >= bottom_safe_distance) {
			//   height = bottom_safe_distance - top;
			//   this.onResizstop(x, y, width, height);
			//   return;
			// }
			this.x = x;
			this.y = y;
			this.width = width;
			this.height = height;
			this.$set(this.settingData.style, 'xAxis', x);
			this.$set(this.settingData.style, 'yAxis', y);
			this.$set(this.settingData.style, 'width', width);
			this.$set(this.settingData.style, 'height', height);
			// this.$emit("handleChange", this.settingData);
		},
		onResizstop(x, y, width, height) {
			// const { bottom_safe_distance } = this.safe_distance;
			// const dragRef = this.$refs["ImageElement"];
			// const { top } = dragRef;
			// if (height + top >= bottom_safe_distance) {
			//   height = bottom_safe_distance - top;
			//   return;
			// }
			// console.log("onResizstop", x, y, width, height);
			this.x = x;
			this.y = y;
			this.width = width;
			this.height = height;
			this.$set(this.settingData.style, 'xAxis', x);
			this.$set(this.settingData.style, 'yAxis', y);
			this.$set(this.settingData.style, 'width', width);
			this.$set(this.settingData.style, 'height', height);
			this.$emit('handleChange', this.settingData);
		},
		onDrag: function (x, y) {
			console.log('onDrag', x, y);
			// const dragRef = this.$refs["ImageElement"];
			const offsetWidth = this.width;
			// const offsetHeight = this.height;
			this.x = x;
			this.y = y;
			this.$set(this.settingData.style, 'xAxis', x);
			this.$set(this.settingData.style, 'yAxis', y);
			// let saft_height = 0;
			// if (this.source === "header") {
			//   saft_height = this.safe_distance.top_safe_distance;
			// } else if (this.source === "footer") {
			//   saft_height = this.safe_distance.bottom_safe_distance;
			// }
			if (x < 0) {
				this.safeDistanceChange({ is_safe: false });
			} else if (x + offsetWidth > this.safe_distance.safe_width) {
				this.safeDistanceChange({ is_safe: false });
			}
			// if (y < 0) {
			//   this.safeDistanceChange({ is_safe: false });
			// } else if (y + offsetHeight > saft_height) {
			//   this.safeDistanceChange({ is_safe: false });
			// }
			this.$emit('handleChange', this.settingData);
		},
		
		handleUploadImage() {
			const a = this.$refs['uploadingImg'];
			a.click();
		},
		/** 上传图片 */
		uploadingImg(element) {
			console.log(element.files, 'file');
			const vm = this;
			const arr = element.files[0].name.split('.');
			if (
				arr[arr.length - 1].toLowerCase() != 'jpg' &&
				arr[arr.length - 1].toLowerCase() != 'png' &&
				arr[arr.length - 1].toLowerCase() != 'jpeg' &&
				arr[arr.length - 1].toLowerCase() != 'bmp' &&
				arr[arr.length - 1].toLowerCase() != 'gif'
			) {
				vm.$message.error('文件格式不正确');
				return;
			}

			if (element.files[0].size / 1024 / 1024 > 3) {
				vm.$message.error('图片大小超过3M');
				return;
			}

			const fr = new FileReader();
			fr.readAsDataURL(element.files[0]);
			const imageForm = new FormData();
			imageForm.append('file', element.files[0]);

			postImage(imageForm).then((res) => {
				vm.defaultSrc = `${this.IMAGE_REL}${res.result}`;

				//重新设置新图片的宽高
				let img = new Image();
				img.src = vm.defaultSrc;
				img.onload = function () {
					let imgPec = 1;
					imgPec = this.height / this.width; //获取当前图片宽高比
					let dragHeight = Math.round(vm.$refs.ImageElement.$el.clientWidth * imgPec);
					vm.settingData.style.height = dragHeight
					vm.$set(vm.settingData.style, 'height', dragHeight); //重新设置图片外层拖拽的高度
					vm.$set(vm.settingData, 'imagePath', res.result);
					//console.log("defaultSrc::", dragHeight,vm.settingData )
					vm.$emit('handleChange', vm.settingData);
				};
			});
		},
		handleScale(event) {
			this.$set(
				this.settingData.style,
				'width',
				this.settingData.style.width + event.movementX
			);
			this.$set(
				this.settingData.style,
				'height',
				this.settingData.style.height + event.movementY
			);
		},
		/** 删除图片 */
		handleDeleteImage() {
			this.$emit('handleDelete', this.elementData.elementCode);
		},
		/** 编辑 */
		handleChange(type) {
			if (type === 'link') {
				this.$set(this.settingData, 'linkAddress', this.imageForm.linkAddress);
			} else if (type == 'border') {
				this.numberChange();
				return;
			}
			this.$emit('handleChange', this.settingData);
		},
		changeLockType(type){
			this.lockingType = type
		},
		//跳转设置
    jumpSetting () {
      this.jumpSettingOptions.visible = true
      this.jumpSettingOptions.data = this.settingData
		},
		//修改图片的跳转链接
    changeJumpSettingData (data,showMsg) {
			let newData = Object.assign(this.settingData, data);
      console.log("newData:",newData,  data)
      this.$emit("handleChange", newData, showMsg);
		},

		//单击组件时调用
		onActivated() {
			console.log('down');
			const vm = this;
			document.onkeydown = function (e) {
				//监听键盘事件
				//事件对象兼容
				let e1 = e || event || window.event || arguments.callee.caller.arguments[0]
				// //键盘按键判断:左箭头-37;上箭头-38；右箭头-39;下箭头-40
				//console.log('键盘输入了', e1, vm.$refs.ImageElement)
				vm.keydownHandle(e1)
			}
		},
 
		//每当用户单击组件外的任何位置时调用
		onDeactivated() {
			console.log('onDeactivated');
			document.onkeydown = function () {
				//取消监听键盘事件
			}
		},

		/**
     * 上下左右按键调整组件位置
     *
     */
    keydownHandle (ev) {

			if (ev.keyCode == 38) {
          this.settingData.style.yAxis--
      } else if (ev.keyCode == 39) {
          this.settingData.style.xAxis++
      } else if (ev.keyCode == 40) {
          this.settingData.style.yAxis++
      } else if (ev.keyCode == 37) {
          this.settingData.style.xAxis--
      }
			ev.preventDefault()
			this.$emit('handleChange', this.settingData);
    },



		
		handleMousedown(e) {
			console.log('down', e);
			const imageElementRef = this.$refs['ImageElement'];
			const vm = this;
			imageElementRef.addEventListener('mousemove', vm.handleMouseMove, false);
		},
		handleMouseUp(e) {
			const imageElementRef = this.$refs['ImageElement'];
			console.log('up', e, imageElementRef);
			const vm = this;
			imageElementRef.removeEventListener('mousemove', vm.handleMouseMove, false);
		},
		handleMouseMove(e) {
			console.log('mmove', e);
			this.settingData.style.width = e.x;
			this.settingData.style.height = e.y;
		},
	},
};
</script>
<style lang="less" scoped>
.ImageElement {
	display: inline-block;
	position: absolute;
}
.img-border {
	position: relative;
	border: 1px solid #54adff;
	cursor: all-scroll;
	&:hover {
		background: rgba(0, 132, 255, 0.1);
		border: 1px solid #54adff;
	}
}
.edit-menu {
	width: 400px;
	margin-top: 5px;
	background: #ffffff;
	box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
	border-radius: 4px;
	position: absolute;
	div {
		cursor: pointer;
	}
}
.edit-menu-position {
	position: relative;
}
.edit-box {
	display: flex;
	justify-content: flex-start;
	height: 48px;
	align-items: center;
	position: relative;
	padding: 5px 10px 0 10px;
	font-size: 14px;
	color: #4c4c4c;
	div {
		margin-right: 30px;
	}
	.angle-box {
		width: 120px;
	}
	/deep/ .el-input-group__append,
	/deep/ .el-input-group__prepend {
		padding: 0 5px;
	}
}
.div-line {
	height: 1px;
	background: #f2f2f2;
}
.link-box {
	padding: 0;
	height: 38px;
	padding: 5px 10px 0 10px;
	/deep/ .el-input__prefix {
		height: 32px;
		top: 4px;
	}
}
.dragging1 {
	border: 1px solid #54adff;
	position: absolute;
	cursor: all-scroll;
}
.text-event1 {
	// float: left;
	height: 100%;
	width: 100%;
	// border: 1px solid red;
	// position: relative;
	// display: inline-block;
	position: absolute;
}
.p-event {
	float: left;
	height: 300px;
	width: 300px;
	border: 1px solid blue;
	position: relative;
	margin-left: 20px;
}
.icon-jiesuo, .icon-suoding{
	display: block;
	line-height: 30px;
	text-align: center;
}
</style>
