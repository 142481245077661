<!--
 * @Author: your name
 * @Date: 2021-04-29 14:18:05
 * @LastEditTime: 2021-06-21 15:06:01
 * @LastEditors: Please set LastEditors
 * @Description: 文字元素组件
 * @FilePath: \dolphin-web\src\components\TextElement\index.vue
-->
<template>
  <!-- :draggable="!editStatus" @resizing="editStatus ? null : onResize"   -->
  <vue-draggable-resizable
    :grid="[1, 1]"
    :h="settingData.style.height ? settingData.style.height :40"
    :min-height="50"
    :min-width="200"
    :w="settingData.style.width"
    :x="settingData.style.xAxis"
    :y="settingData.style.yAxis"
    :z="editStatus || showEditBox ? settingData.style.zIndex :'auto'"
    @dragging="onDragging"
    @dragstop="onDrag"
    @resizestop="onResizstop"
    @activated="onActivated"
		@deactivated="onDeactivated" 
    class-name="dragging1"
    class="TextElement"
    ref="TextElement"
  >
    <div
      :style="styleConfig"
      @dblclick="handleDbClick"
      @mouseenter="handleEditBox()"
      @mouseleave="handleBoxMouseleave()"
      class="text-box"
    >
      <div
        :style="'width: 100%; word-break: break-all; overflow: hidden; text-decoration:' + settingData.style.textDecoration +
                      ';font-style:' + settingData.style.italic +
                      ';font-weight:' + settingData.style.bold  + 
                      ';font-family:'+  settingData.style.font  + 
                      ';height:'+  settingData.style.height +'px' "
      >
        <div  class="input-box" v-html="textContent"></div>
      </div>
    </div>
    <div
      :style="zIndexStyle"
      @mouseenter="handleEditBox()"
      @mouseleave="handleBoxMouseleave()"
      class="div-posiotion div-action"
      v-show="showEditBox"
    >
      <div @click="handleEditMenu">编辑</div>
      <div @click="handleCopy">复制</div>
      <div @click="handleDelete">删除</div>
      <div @click="setLink">跳转设置</div>
    </div>

    <textSetting
      :options="textSettingOptions"
      @changeSettingData="changeSettingData"
      v-if="textSettingOptions.visible"
    ></textSetting>

    <jumpSetting
      :options="jumpSettingOptions"
      @changeJumpSettingData="changeJumpSettingData"
      v-if="jumpSettingOptions.visible"
    ></jumpSetting>
  </vue-draggable-resizable>
</template>
<script>
import { mapState, mapActions } from "vuex";
import VueDraggableResizable from "vue-draggable-resizable";
import "vue-draggable-resizable/dist/VueDraggableResizable.css";
import textSetting from './components/textSetting';
import jumpSetting from './components/jumpSetting';
export default {
  name: "TextElement",
  props: ["elementData", "index", "source"],
  components: {
    textSetting,
    jumpSetting,
    VueDraggableResizable
  },
  data () {
    //const vm = this
    return {
      textSettingOptions: {
        visible: false
      }, //链接弹窗
      textContent: "双击修改文字",
      showTextOrInput: false,
      showEditBox: false,
      showEditMenu: true,
      editStatus: false, // 是否编辑状态
      mouseOffsetData: {
        x: "",
        y: "",
        objX: "",
        objY: "",
      },
      width: 0,
      height: 0,
      x: 0,
      y: 0,
      jumpSettingOptions: {
        visible: false
      }
    };
  },
  computed: {
    draggableStyle () {
      return {
        left: `${this.settingData.style.xAxis}px`,
        top: `${this.settingData.style.yAxis}px`,
        width: `${this.settingData.style.width}px`,
        height: `${this.settingData.style.height}px`,
        zIndex: 999,
      };
    },
    settingData () {
      return { ...this.elementData };
    },
    styleConfig () {
      return {
        color: `${this.settingData.style.fontColor}`,
        fontSize: `${this.settingData.style.fontSize}px`,
        // fontWeight: `${BOLD_ENUM[this.settingData.style.bold]}`,
        // fontStyle: `${ITALIC_ENUM[this.settingData.style.italic]}`,
        // textDecoration: `${UNDERLINE_ENUM[this.settingData.style.underline]}`,
        // lineHeight: `${this.settingData.style.lineHeight * 10}px`,
        // textAlign: `${ALIGN_ENUM[this.settingData.style.alignment]}`,
      };
    },
    zIndexStyle () {
      let topVal = -(2 + this.settingData.style.lineHeight * 10 - 5);
      let indexN = this.settingData.style.zIndex
      if (this.showLink) {
        indexN = this.settingData.style.zIndex + 10
      }
      return {
        zIndex: indexN,
        top: `${topVal}px`,
      };
    },
    linkIndexStyle () {
      let topVal = -(2 + this.settingData.style.lineHeight * 10);
      let indexN = this.settingData.style.zIndex + 10
      return {
        zIndex: `${indexN}`,
        top: `${topVal}px`,
      };
    },
    ...mapState(["safe_distance"]),
  },
  watch: {
    "settingData.style.yAxis": {
      handler () {

      },
      "editStatus": {
        handler (value) {
          console.log("yAxis:::", value)
        },
      }
    },
  },
  mounted () {
    this.textContent = this.settingData.content;
    //this.textForm.linkAddress = this.settingData.linkAddress;
    // document.body.addEventListener(
    //   "click",
    //   () => {
    //     console.log('editor ready!111')
    //   },
    //   false
    // );


  },
  methods: {
    ...mapActions(["safeDistanceChange"]),


    /** 开始拖拽 */
    handleDragStart (event) {
      const dragRef = this.$refs["TextElement"];
      this.mouseOffsetData.x = event.clientX;
      this.mouseOffsetData.y = event.clientY;
      this.mouseOffsetData.objX = dragRef.offsetLeft;
      this.mouseOffsetData.objY = dragRef.offsetTop;
    },
    /** 拖拽结束事件 */
    handleDragEnd (event) {
      const dragRef = this.$refs["TextElement"];
      const { clientX = 0, clientY = 0 } = event;
      const {
        offsetWidth,
        offsetHeight
      } = dragRef;
      const drageX =
        clientX - this.mouseOffsetData.x + this.mouseOffsetData.objX;
      const drageY =
        clientY - this.mouseOffsetData.y + this.mouseOffsetData.objY;
      let saft_height = 0;
      if (this.source === "header") {
        saft_height = this.safe_distance.top_safe_distance;
      } else if (this.source === "footer") {
        saft_height = this.safe_distance.bottom_safe_distance;
      }
      if (drageX < 0) {
        this.safeDistanceChange({ is_safe: false });
        // this.$set(this.settingData.style, "xAxis", 0);
      } else if (drageX + offsetWidth > this.safe_distance.safe_width) {
        this.safeDistanceChange({ is_safe: false });
        // this.$set(this.settingData.style,"xAxis", this.safe_distance.safe_width - offsetWidth);
      } else {
        // this.$set(this.settingData.style, "xAxis", drageX);
      }
      this.editStatus = false
      this.$set(this.settingData.style, "xAxis", drageX);
      if (drageY < 0) {
        this.safeDistanceChange({ is_safe: false });
        // this.$set(this.settingData.style, "yAxis", 0);
      } else if (drageY + offsetHeight > saft_height) {
        this.safeDistanceChange({ is_safe: false });
        // this.$set(this.settingData.style, "yAxis", saft_height - offsetHeight);
      } else {
        // this.$set(this.settingData.style, "yAxis", drageY);
      }
      this.$set(this.settingData.style, "yAxis", drageY);
      this.$emit("handleChange", this.settingData);
    },
    onResize (x, y, width, height) {
      //不显示

      this.x = x;
      this.y = y;
      this.width = width;
      this.height = height;

      this.$set(this.settingData.style, "xAxis", x);
      this.$set(this.settingData.style, "yAxis", y);
      this.$set(this.settingData.style, "width", width);
      this.$set(this.settingData.style, "height", height);

    },
    onResizstop (x, y, width, height) {
      this.x = x;
      this.y = y;
      this.width = width;
      this.height = height;
      this.$set(this.settingData.style, "xAxis", x);
      this.$set(this.settingData.style, "yAxis", y);
      this.$set(this.settingData.style, "width", width);
      this.$set(this.settingData.style, "height", height);
      this.$set(this.settingData, "content", this.textContent);
      this.$emit("handleChange", this.settingData);
    },
    onDrag: function (x, y) {

      const offsetWidth = this.width;
      this.x = x + this.width;
      this.y = y;
      this.$set(this.settingData.style, "xAxis", x);
      this.$set(this.settingData.style, "yAxis", y);

      if (x < 0) {
        this.safeDistanceChange({ is_safe: false });
      } else if (x + offsetWidth > this.safe_distance.safe_width) {
        this.safeDistanceChange({ is_safe: false });
      }

      this.$emit("handleChange", this.settingData);
    },
    onDragging: function () {
      //console.log("onDragging:::;",x,y)
    },
    //双击配置文字信息
    handleDbClick () {
      console.log("settingData:::", this.settingData.style.fontColor)
      this.showEditBox = false;
      this.editStatus = true
      this.textSettingOptions.fontColor = true
      this.textSettingOptions.data = this.settingData
      this.textSettingOptions.visible = true
    },
    //配置文字信息
    handleEditMenu () {
      console.log("settingData:::", this.settingData.style.fontColor)
      
      this.textSettingOptions.firstColor = true
      this.textSettingOptions.data = this.settingData
      this.textSettingOptions.visible = true
    },

    //跳转设置
    setLink () {
      this.jumpSettingOptions.visible = true
      this.jumpSettingOptions.data = this.settingData
    },

    //鼠标hover显示 编辑框
    handleEditBox () {
      this.editStatus = true
      this.showEditBox = true;
      //this.showEditMenu = false;
      // 隐藏父级工具栏
      this.$emit("hiddenTool");
    },
    
    //鼠标离开 隐藏编辑
    handleBoxMouseleave () {
      this.showEditBox = false;
      //this.showEditMenu = false;
      this.editStatus = true
    },


    
    /** 复制 */
    handleCopy () {
      this.$emit("handleCopy", this.settingData);
    },
    /** 删除 */
    handleDelete () {
      this.$emit("handleDelete", this.elementData.elementCode);
    },
    

    



    /** 编辑 */
    // handleChange (type) {
    //   console.log("type:====", type, this.settingData.style)
    //   if (type === "text") {
    //     this.$set(this.settingData, "content", this.textContent);
    //     this.$emit("handleChange", this.settingData);
    //   } else if (type === "link") {
    //     this.$refs["textForm"].validate((valid) => {
    //       if (valid) {
    //         this.$set(
    //           this.settingData,
    //           "linkAddress",
    //           this.textForm.linkAddress
    //         );
    //         this.$emit("handleChange", this.settingData);
    //       }
    //     });
    //   } else {
    //     this.$emit("handleChange", this.settingData);
    //   }
    // },
    showInput () {
      this.showEditMenu = !this.showEditMenu;
    },
    //通用 修改文字元素信息
    changeSettingData (type, data) {
      console.log("===", type, data)
      if (type == 'content') {
        this.$set(this.settingData, "content", data);
        this.textContent = this.settingData.content;
      } else if (type == 'link') {
        this.$set( this.settingData, "linkAddress", data );
      } else {
        this.$set(this.settingData.style, type, data);
      }
      this.$emit("handleChange", this.settingData);
    },

    //修改文字的跳转链接
    changeJumpSettingData (data,showMsg) {
      let newData = Object.assign(this.settingData, data);
      console.log("newData:",newData,  data)
      this.$emit("handleChange", newData, showMsg); 
    },


    /** 行高 */
    handleTextLineHeight (val) {
      this.$set(this.settingData.style, "lineHeight", val);
      this.$emit("handleChange", this.settingData);
    },
    /** 对齐方式 */
    handleTextAlign (val) {
      this.$set(this.settingData.style, "alignment", val);
      this.$emit("handleChange", this.settingData);
    },
    //单击组件时调用
		onActivated() {
      this.settingData.style.zIndex = 90
			console.log('down');
			const vm = this;
			document.onkeydown = function (e) {
				//监听键盘事件
				//事件对象兼容
				let e1 = e || event || window.event || arguments.callee.caller.arguments[0]
				// //键盘按键判断:左箭头-37;上箭头-38；右箭头-39;下箭头-40
				//console.log('键盘输入了', e1, vm.$refs.ImageElement)
				vm.keydownHandle(e1)
			}
		},
 
		//每当用户单击组件外的任何位置时调用
		onDeactivated() {
			console.log('onDeactivated');
			document.onkeydown = function () {
				//取消监听键盘事件
			}
		},

		/**
     * 上下左右按键调整组件位置
     *
     */
    keydownHandle (ev) {

			if (ev.keyCode == 38) {
          this.settingData.style.yAxis--
      } else if (ev.keyCode == 39) {
          this.settingData.style.xAxis++
      } else if (ev.keyCode == 40) {
          this.settingData.style.yAxis++
      } else if (ev.keyCode == 37) {
          this.settingData.style.xAxis--
      }
      ev.preventDefault()
      this.$emit("handleChange", this.settingData);
    },
  },
};
</script>
<style lang="less" scoped>
@import url('./quill.less');
/deep/ .active {
  z-index: 999;
}
.TextElement {
  display: inline-block;
  position: absolute;
  z-index: 999;
  .text-box {
    width: auto;
    cursor: all-scroll;
    &:hover {
      background: rgba(0, 132, 255, 0.1);
      border: 1px solid #54adff;
    }
    .input-box {
      width: 100%;
    }
  }

  .div-posiotion {
    margin-top: 5px;
    background: #fff;
  }
  .div-action {
    display: flex;
    justify-content: space-around;
    width: 206px;
    height: 48px;
    background: #ffffff;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    align-items: center;
    position: relative;
    font-size: 14px;
    color: #4c4c4c;
    div {
      cursor: pointer;
    }
  }
  .div-edit {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 400px;
    height: 107px;
    background: #ffffff;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    position: relative;
    div {
      height: 48px;
    }
    .div-first {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
      align-content: center;
      flex-wrap: nowrap;
      line-height: 48px;
      .mouse-style {
        cursor: pointer;
      }
      /deep/ .el-input {
        height: 100%;
        /deep/ .el-input__suffix {
          height: unset;
        }
      }
    }
    .div-line {
      width: 400px;
      height: 1px;
      background: #f2f2f2;
    }
    .link-box {
      padding: 9px 10px 0 10px;
      height: 38px;
      /deep/ .el-input__prefix {
        height: 32px;
        top: 4px;
      }
    }
    .dropdown-box {
      ul {
        position: absolute !important;
      }
    }
    .alignDropDown {
      .dropdown-box {
        ul {
          width: 120px !important;
        }
      }
    }
  }
  .ql-editor {
    p {
      color: #000 !important;
    }
  }
  .quillWrap {
    position: relative;
    .ql-toolbar {
      position: absolute;
      top: -20px;
      left: 0;
      width: 300px;
    }
  }
}

/deep/ .ql-toolbar.ql-snow {
  width: 300px;
  position: absolute;
  display: none;
  box-shadow: 0px 2px 4px 0px rgb(0 0 0 / 15%);
  border-radius: 4px;
  text-align: left;
  background: #fff;
  z-index: 999;
}
/deep/.ql-snow {
  border: none;
}
/deep/.ql-editor {
  color: #000000;
}
</style>