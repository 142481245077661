/*
 * @Author: your name
 * @Date: 2021-05-18 15:25:17
 * @LastEditTime: 2021-05-24 19:42:54
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \dolphin-web\src\assets\js\api\application.js
 */
import request from '../common/axios';

/**
 * @description: 获取应用列表
 * @param {*} params
 * @return {*}
 */
export function getApps(params) {
  return request({
    url: `app/getApps`,
    method: 'get',
    params
  })
}

/**
 * @description: 获取中台桌面列表
 * @param {*} params
 * @return {*}
 */
export function getZhongtaiTopics(params) {
  return request({
    url: `app/getZhongtaiTopics`,
    method: 'get',
    params
  })
}

export function postClickApp (data) {
  // 点击应用的时候
  return request({
    url: 'third/loginByAuthDriver',
    method: 'post',
    data
  })
}


export function getIsBind (params) {
  // 点击应用的时候判断判断是否需要绑定
  return request({
    url: 'third/isBindingSystem',
    method: 'get',
    params: params
  })
}

// 绑定账户
// export const getPostBindAccount = (params) => {
//   return request({
//     url: 'third/binding',
//     method: 'get',
//     params: params
//   })
// }

export const getPostBindAccount = (data) => {
  return request({
    url: 'third/binding',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/json'
    }
  })
}