/**
 * CryptoJS加密
 */
import CryptoJS from 'crypto-js/crypto-js'
export const Encryption = data => {
  let key = CryptoJS.enc.Utf8.parse('youedata12345678') // 加密秘钥
  let iv = CryptoJS.enc.Utf8.parse(data) //  矢量
  let encryptResult = CryptoJS.AES.encrypt(iv, key, {
    //  AES加密
    iv: key,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  })
  return encodeURIComponent(
    CryptoJS.enc.Base64.stringify(encryptResult.ciphertext)
  ) // Base64加密再 encode; */
}
