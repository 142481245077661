/*
 * @Author: your name
 * @Date: 2021-05-13 10:36:17
 * @LastEditTime: 2021-05-24 19:07:07
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \dolphin-web\src\assets\js\api\elements.js
 */
import request from '../common/axios';

/**
 * @description: 添加元素
 * @param { Object } data 
 * @return {*}
 */
export function addElemet(data) {
  return request({
    url: `manage/element/add`,
    method: 'post',
    data
  })
}

/**
 * @description: 背景上新增元素
 * @param { Object } data 
 * @return {*}
 */
export function addToBackground(data) {
  return request({
    url: `manage/element/addToBackground`,
    method: 'post',
    data
  })
}

/**
 * @description: 容器中新增元素
 * @param { Object } data 
 * @return {*}
 */
export function addToContainer(data) {
  return request({
    url: `manage/element/addToContainer`,
    method: 'post',
    data
  })
}

/**
 * @description: 菜单上新增元素
 * @param { Object } data 
 * @return {*}
 */
export function addToMenu(data) {
  return request({
    url: `manage/element/addToMenu`,
    method: 'post',
    data
  })
}

/**
 * @description: 删除元素
 * @param {*} data
 * @return {*}
 */
export function deleteElemet(params) {
  return request({
    url: `manage/element/delete/${params.elementCode}`,
    method: 'delete',
    params
  })
}
/**
 * @description: 修改元素信息
 * @param {*} param
 * @return {*}
 */
export function updateElemet(data) {
  return request({
    url: `manage/element/update/${data.elementCode}`,
    method: 'put',
    data: data.elementUpdateDto
  })
}

/**
 * @description: 图片上传
 * @param {*} data
 * @return {*}
 */
export function postImage(data) {
  return request({
    url: `manage/element/image/upload`,
    method: 'post',
    data
  })
}

/**
 * @description: 获取元素列表
 * @param {*} params
 * @return {*}
 */
export function getElementList(params) {
  return request({
    url: `manage/element/list`,
    method: 'get',
    params
  })
}

/**
 * @description: 获取元素详情
 * @param {*} params
 * @return {*}
 */
export function getElementDetail(params) {
  return request({
    url: `manage/element/detail`,
    method: 'get',
    params
  })
}

/**
 * @description: 修改元素排序
 * @param {*} params
 * @return {*}
 */
export function elementSort(data) {
  return request({
    url: `manage/element/sort/update`,
    method: 'put',
    data
  })
}

/**
 * @description: 获取元素列表-带子级容器code 更具子级容器的code去获取子级元素列表
 * @param {*} params
 * @return {*}
 */
export function getElementChildCode(params) {
  return request({
    url: `manage/element/listWithChildCode`,
    method: 'get',
    params
  })
}

/**
 * @description: 登录元素
 * @param {*} params
 * @return {*}
 */
export function getLoginButton(params) {
  return request({
    url: `manage/element/getLoginButton`,
    method: 'get',
    params
  })
}

/**
 * @description: 获取头像元素
 * @param {*} params
 * @return {*}
 */
export function getProfileImage(params) {
  return request({
    url: `manage/element/getProfileImage`,
    method: 'get',
    params
  })
}


/**
 * @description: 获取树形结构栏目
 * @param {*} params
 * @return {*}
 */
export function getColumnTree(params) {
  return request({
    url: `manage/element/getColumnTree`,
    method: 'get',
    params
  })
}
