/**
* 作者 ：wbz
*
* 日期 ：2020-09-22
*
* 描述 ：
*/
<template>
  <div>
    <el-dialog :visible.sync="options.visible" append-to-body title="编辑" width="500px">
      <div>
        <el-form :model="ruleForm" :rules="textRules" label-width="100px" ref="ruleForm">
          <el-form-item label="文本内容" prop="content">
            <el-input
              :autosize="{ minRows: 2, maxRows: 4}"
              @blur="changeTextContent()"
              class="textareaStyle"
              maxlength="1024"
              placeholder="请输入文本内容"
              ref="textContentInput"
              type="textarea"
              v-model.trim="ruleForm.textContent"
            ></el-input>
          </el-form-item>
          <el-form-item label="字体大小" prop="content">
            <el-input
              @blur="changeFontSize()"
              @input="ruleForm.fontSize = ruleForm.fontSize.replace( /\D|^0/g,'')"
              maxlength="2"
              minlength="1"
              placeholder="12"
              style="width: 90px"
              v-model.trim="ruleForm.fontSize"
            ></el-input>
            <span class="formTips">字体最大值为36，超过按最大值显示</span>
          </el-form-item>
          <el-form-item label="字体样式" prop="content">
            <el-select
              @change="changeOptionsFontFamily"
              placeholder="请选择"
              size="mini"
              style="width: 150px; float: left;"
              v-model="ruleForm.textFontFamily"
            >
              <el-option
                :key="item.value"
                :label="item.label"
                :value="item.value"
                v-for="item in fontFamilyList"
              ></el-option>
            </el-select>

            <verte
              @change="changeFontColor()"
              menuPosition="top"
              model="rgb"
              picker="square"
              style="position:relative; top:3px; float: left; width: 50px"
              v-if="fontColor"
              v-model="fontColor"
            ></verte>
          </el-form-item>
          <el-form-item label="其他样式" prop="ruleForm.content">
            <div class="otherFontStyle">
              <span
                :class="{actives: item.check}"
                :key="item.icon"
                :span="3"
                class="itemIcon"
                v-for="(item, index) in items"
              >
                <i :class="item.icon" @click="change(item, index)" class="iconStyle iconfont"></i>
              </span>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <!-- <div class="dialog-footer" slot="footer">
        <el-button @click="options.visible = false">取 消</el-button>
        <el-button @click="cancel" type="primary">确 定</el-button>
      </div>-->
    </el-dialog>
  </div>
</template>
<script>
import Verte from "verte";
export default {
  components: { Verte },
  props: ['options'],
  data () {
    return {
      ruleForm: {
        textContent: '',
        fontSize: '',
        textFontFamily: ''
      },
      fontColor: "",
      firstColor: true,
      textRules: {
        fontSize: { required: true, message: "请输入字体大小", trigger: "blur" },
      },
      fontFamilyList: [
        {
          label: '微软雅黑',
          value: '微软雅黑'
        },
        {
          label: '思源',
          value: '思源'
        }
      ],
      items: [
        {
          icon: 'icon-jiacu',
          check: false,
          name: 'fontWeight',
          ar: [
            'normal',
            'bold'
          ]
        },
        {
          icon: 'icon-qingxie1',
          check: false,
          name: 'fontStyle', //normal : 正常的字体  italic : 斜体
          ar: [
            'normal',
            'italic'
          ]
        },
        {
          icon: 'icon-xiahuaxian1',
          check: false,
          name: 'textDecoration',
          bName: "underline",
          ar: [
            'none',
            'underline'
          ],
          'radio': true
        },
        {
          icon: 'icon-shanchuxian',
          check: false,
          name: 'textDecoration',
          bName: "line-through",
          ar: [
            'none',
            'line-through'
          ],
          'radio': true
        }
      ],
    }
  },
  watch: {
    "fontColor": {
      handler () {
        if (!this.options.firstColor) {
          this.changeFontColor();
        } else {
          this.options.firstColor = false
        }

      },
    },

  },
  mounted () {
    this.init()
    this.$nextTick(() => { //设置文本内容获取焦点
      this.$refs.textContentInput.focus();
    })


    /** 回显字体相关信息 */
    let data = this.options.data
    if (data) {
      this.ruleForm.textContent = data.content
      this.ruleForm.fontSize = data.style.fontSize
      this.ruleForm.textFontFamily = data.style.font
      this.fontColor = data.style.fontColor
      this.changeFontStyle('fontWeight', data.style.bold)
      this.changeFontStyle('fontStyle', data.style.italic)
      this.changeFontStyle('textDecoration', data.style.textDecoration)
    }



  },
  methods: {
    init () {
    },
    /** 回显字体下划线、删除线、加粗、斜体 */
    changeFontStyle (name, value) {
      if (value) {
        if (name == 'textDecoration') {
          if (value == 'normal' || Number(value) == 0) {
            this.items.forEach(i => {
              if (i.name == name) {
                i.check = false
              }
            })
          } else {
            this.items.forEach(i => {
              if (i.bName == value) {
                i.check = true
              }
            })

          }


        } else {
          this.items.forEach(i => {
            if (i.name == name) {
              if (value == 'normal' || value == '0') {
                i.check = false
              } else {
                i.check = true
              }
            }
          })

        }
      }

    },

    /** 修改字体样式 */
    changeOptionsFontFamily () {
      this.$emit("changeSettingData", 'font', this.ruleForm.textFontFamily);
    },
    /** 修改字体大小 */
    changeFontSize () {
      if (this.ruleForm.fontSize != '') {
        if (this.ruleForm.fontSize > 36) {
          this.ruleForm.fontSize = 36
        }
        this.$emit("changeSettingData", 'fontSize', Number(this.ruleForm.fontSize));
      }

    },
    /** 修改文本内容 */
    changeTextContent () {
      this.$emit("changeSettingData", 'content', this.ruleForm.textContent);
    },
    /** 字体颜色 */
    changeFontColor () {
      this.$emit("changeSettingData", 'fontColor', this.fontColor);
    },
    /** 字体下划线、删除线、加粗、斜体 */
    change (item, index) {
      let obj = {}
      item.check = !item.check
      obj[item.name] = item.ar[+item.check]
      // 处理单选项
      this.detail(item, index)
      let nameArr = {
        'fontStyle': 'italic',
        'fontWeight': 'bold',
        'textDecoration': 'textDecoration'
      }

      this.$emit("changeSettingData", nameArr[item.name], item.ar[+item.check]);

    },
    // 如果某项是单选的，如textDecoration
    detail (item, index) {
      if (item.radio && item.check) {
        for (let i = 0, len = this.items.length; i < len; i++) {
          const element = this.items[i]
          if (element.radio && element.check && index !== i) {
            element.check = false
          }
        }
      }
    }
  },

}
</script>
<style lang="less" scoped>
.formTips {
  cursor: pointer;
  color: #cacaca;
  font-size: 12px;
  margin-left: 5px;
}
.textareaStyle {
  font-family: Microsoft YaHei, SimSun, Arial Narrow;
}
.otherFontStyle {
  width: 146px;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  padding: 0 2px;
  line-height: 32px;
  .itemIcon {
    margin: 0 10px;
  }
  .itemIcon.actives .iconfont {
    color: #279eb7;
  }
  .iconfont {
    color: #999999;
    font-size: 18px;
    cursor: pointer;
  }
  .iconfont:hover {
    color: #279eb7;
  }
}
</style>