<!--
 * @Author: your name
 * @Date: 2021-05-24 10:51:54
 * @LastEditTime: 2021-06-16 17:00:08
 * @LastEditors: Please set LastEditors
 * @Description: 登录组件
 * @FilePath: \dolphin-web\src\views\manage\components\components\LoginElement\index.vue
-->
<template>
  <div
    draggable
    class="loginElement"
    ref="LoginElement"
    v-show="!buttonForm.display"
    :style="draggableStyle"
    @dragstart="handleDragStart"
    @dragend="handleDragEnd"
    @dragover="handleDragOver"
    @mouseleave="handleBoxMouseleave"
  >
    <div class="login-box" @mouseenter="switchEdit" :style="loginBtnStyle">
      <span :style="loginTextStyle">{{ buttonForm.content }}</span>
    </div>
    <div class="edit-box" v-show="showEditBtn">
      <div @click="handleEdit">编辑</div>
      <el-checkbox v-model="buttonForm.display" @change="handleChange"
        >隐藏</el-checkbox
      >
    </div>
    <el-dialog
      class="logindia"
      title="编辑"
      width="500px"
      append-to-body
      custom-class="login-dialog"
      ref="loginDialog"
      :visible="loginDialog"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      @close="handleClose"
    >
      <el-tabs v-model="activeName" @tab-click="handleTabClick">
        <el-tab-pane label="按钮" name="button">
          <el-form
            ref="buttonRef"
            label-width="80px"
            :model="buttonForm"
            :rules="buttonRules"
          >
            <el-form-item label="文本信息" prop="content">
              <el-input
                v-model="buttonForm.content"
                placeholder="请输入登录按钮名称"
                minlength="1"
                maxlength="20"
                @change="handleChange"
              ></el-input>
            </el-form-item>
            <el-form-item label="按钮尺寸">
              <el-input
                style="width: 120px"
                v-model="buttonForm.width"
                placeholder="宽度"
                minlength="1"
                maxlength="3"
                @change="handleChange"
              >
                <img
                  slot="prefix"
                  :src="icon_width"
                  style="padding-top: 10px"
                />
              </el-input>
              <el-input
                style="width: 120px; margin-left: 10px"
                v-model="buttonForm.height"
                placeholder="高度"
                minlength="1"
                maxlength="3"
                @change="handleChange"
              >
                <img slot="prefix" :src="icon_height" style="padding-top: 10px"
              /></el-input>
              <!-- <el-select
                v-model="buttonForm.height"
                placeholder="按钮尺寸"
                @change="handleChange"
              >
                <el-option
                  v-for="item in marginOptions"
                  :key="item.elementCode"
                  :label="item.content"
                  :value="item.elementCode"
                ></el-option>
              </el-select>
              <el-select
                v-model="buttonForm.height"
                placeholder="按钮尺寸"
                @change="handleChange"
              >
                <el-option
                  v-for="item in lineHightOptions"
                  :key="item.elementCode"
                  :label="item.content"
                  :value="item.elementCode"
                ></el-option>
              </el-select> -->
            </el-form-item>
            <el-form-item label="圆角">
              <div style="display: flex">
                <el-slider
                  v-model="buttonForm.borderRadius"
                  style="width: 200px"
                  @change="handleChange"
                ></el-slider>
                <span>{{ `${buttonForm.borderRadius} %` }}</span>
              </div>
            </el-form-item>
            <el-form-item label="背景颜色">
              <verte
                v-model="buttonForm.backgroundColor"
                picker="square"
                model="rgb"
              ></verte>
              <!-- <el-color-picker
                show-alpha
                v-model="buttonForm.backgroundColor"
                size="mini"
                @change="handleChange"
              > 
              </el-color-picker>-->
            </el-form-item>
            <el-form-item label="悬停颜色">
              <verte
                v-model="buttonForm.hoverColor"
                @change="handleChange"
                picker="square"
                model="rgb"
              ></verte>
              <!-- <el-color-picker
                show-alpha
                v-model="buttonForm.hoverColor"
                size="mini"
                @change="handleChange"
              ></el-color-picker> -->
            </el-form-item>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="字体" name="typeface">
          <el-form ref="typefaceRef" label-width="80px" :model="typefaceForm">
            <el-form-item label="字体字号">
              <!-- <el-select
                v-model="typefaceForm.font"
                style="width: 140px"
                size="mini"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in typefaceOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select> -->
              <el-select
                v-model="typefaceForm.fontSize"
                style="width: 120px; height: 100%"
                size="mini"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in FONT_SIZE_ENUM"
                  :key="item.key"
                  :label="item.value"
                  :value="item.key"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="字体颜色">
              <verte
                v-model="typefaceForm.fontColor"
                @change="handleChange"
                picker="square"
                model="rgb"
              ></verte>
              <!-- <el-color-picker
                show-alpha
                v-model="typefaceForm.fontColor"
                size="mini"
                @change="handleChange"
              ></el-color-picker> -->
            </el-form-item>
            <el-form-item label="悬停颜色">
              <verte
                v-model="typefaceForm.fontHoverColor"
                @change="handleChange"
                picker="square"
                model="rgb"
              ></verte>
              <!-- <el-color-picker
                show-alpha
                v-model="typefaceForm.fontHoverColor"
                size="mini"
                @change="handleChange"
              ></el-color-picker> -->
            </el-form-item>
            <el-form-item label="其他属性">
              <div class="box-other">
                <div>
                  <img
                    class="mouse-style"
                    :src="
                      Number(typefaceForm.bold) ? icon_text_bold_active : icon_text_bold
                    "
                    @click="handleBtnBIU('bold')"
                  />
                </div>
                <div>
                  <img
                    class="mouse-style"
                    :src="
                      typefaceForm.italic
                        ? icon_text_italic_active
                        : icon_text_italic
                    "
                    @click="handleBtnBIU('italic')"
                  />
                </div>
                <div>
                  <img
                    class="mouse-style"
                    :src="
                      typefaceForm.textDecoration
                        ? icon_text_underline_active
                        : icon_text_underline
                    "
                    @click="handleBtnBIU('textDecoration')"
                  />
                </div>
              </div>
            </el-form-item>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="个人中心" name="personal">
          <el-table style="width: 100%" border :data="personalSecondData">
            <el-table-column
              prop="content"
              label="菜单项"
              align="left"
            ></el-table-column>
            <el-table-column label="排序" align="left">
              <template slot-scope="scope">
                <div class="order">
                  <i
                    v-if="scope.row.firstRow"
                    class="el-icon-top order-up-unabled"
                  ></i>
                  <i
                    v-else
                    class="el-icon-top order-up-abled"
                    @click="handleOrderUp(scope.row)"
                  ></i>
                  <i
                    v-if="scope.row.lasterRow"
                    class="el-icon-bottom order-up-unabled"
                  ></i>
                  <i
                    v-else
                    class="el-icon-bottom order-up-abled"
                    @click="handleOrderDown(scope.row)"
                  ></i>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="操作" align="left">
              <template slot-scope="scope">
                <el-button
                  type="text"
                  :disabled="
                    scope.row.canDelete || scope.row.content === '退出'
                  "
                  @click="handlePersonalEdit(scope.row)"
                  >编辑</el-button
                >
                <el-button
                  type="text"
                  :disabled="
                    scope.row.canDelete || scope.row.content === '退出'
                  "
                  @click="handlePersonalDelete(scope.row)"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <div class="add-box" @click="handleAdd">
            <div>
              <i class="el-icon-plus"></i>
              <span>新建</span>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </el-dialog>
    <el-dialog
      class="logindia"
      width="500px"
      append-to-body
      custom-class="login-dialog"
      :title="personalTitle"
      :visible="personalDialog"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      :destroy-on-close="true"
      @close="handlePersonalDialogClose"
    >
      <el-form
        ref="personalForm"
        label-width="80px"
        :model="personalForm"
        :rules="personalRules"
      >
        <el-form-item label="文本信息" prop="content">
          <el-input
            v-model.trim="personalForm.content"
            placeholder="请输入栏目名称"
            minlength="1"
            maxlength="10"
          ></el-input>
        </el-form-item>
        <el-form-item label="跳转到">
          <el-radio-group
            style="margin-top: 9px"
            v-model="personalForm.linkType"
            @change="handleLinkTypeChange"
          >
            <el-radio :label="3">中台桌面</el-radio>
            <el-radio :label="1">链接</el-radio>
            <el-radio :label="2">应用</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          :key="personalForm.linkType"
          v-if="personalForm.linkType === 1"
          label="链接"
          prop="linkAddress"
        >
          <el-input
            v-model="personalForm.linkAddress"
            placeholder="请输入以http:或者https:开头链接"
          ></el-input>
        </el-form-item>
        <el-form-item
          :key="personalForm.linkType"
          v-if="personalForm.linkType === 2"
          label="应用"
          prop="link"
        >
          <el-select v-model="personalForm.link" placeholder="请选择应用">
            <el-option
              v-for="item in appList"
              :key="item.appId"
              :label="item.appName"
              :value="item.appId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          :key="personalForm.linkType"
          v-if="personalForm.linkType === 3"
          label="中台桌面"
          prop="link"
        >
          <el-select v-model="personalForm.link" placeholder="请选择中台桌面">
            <el-option
              v-for="item in zhongtaiTopicsList"
              :key="item.id"
              :label="item.platformName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            @click="onSubmit('personalForm')"
            :loading="personalFormLoading"
            >确定</el-button
          >
          <el-button @click="handlePersonalDialogClose">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import icon_text_bold from "./../../../../../assets/images/TextElement/icon_text_bold.png";
import icon_text_bold_active from "./../../../../../assets/images/TextElement/icon_text_bold_active.png";
import icon_text_italic from "./../../../../../assets/images/TextElement/icon_text_italic.png";
import icon_text_italic_active from "./../../../../../assets/images/TextElement/icon_text_italic_active.png";
import icon_text_underline from "./../../../../../assets/images/TextElement/icon_text_underline.png";
import icon_text_underline_active from "./../../../../../assets/images/TextElement/icon_text_underline_active.png";
import icon_height from "./../../../../../assets/images/TextElement/icon_height.png";
import icon_width from "./../../../../../assets/images/TextElement/icon_width.png";

import { mapState, mapActions } from "vuex";

import {
  getLoginButton,
  updateElemet,
  getProfileImage,
  addToContainer,
  deleteElemet,
  elementSort,
} from "./../../../../../assets/js/api/elements.js";

import {
  getZhongtaiTopics,
  getApps,
} from "./../../../../../assets/js/api/application.js";

import { FONT_SIZE_ENUM } from "./../../staticData/index.js";
import Verte from "verte";
const BOLD_ENUM = [400, 800];
const ITALIC_ENUM = ["", "italic"];
const UNDERLINE_ENUM = ["", "underline"];

export default {
  props: ["backgroundCode"],
  components: { Verte },
  data() {
    const validateLink = (rule, value, callback) => {
      console.log(rule, value, callback);
      if (value === "") {
        if (this.personalForm.linkType === 2) {
          callback(new Error("请选择应用"));
        } else if (this.personalForm.linkType === 3) {
          callback(new Error("请选择中台桌面"));
        }
      } else {
        // if (this.ruleForm.link !== "") {
        //   this.$refs.personalForm.validateField("link");
        // }
        callback();
      }
    };
    return {
      BOLD_ENUM,
      ITALIC_ENUM,
      UNDERLINE_ENUM,
      loginVisible: false,
      showEditBtn: false,
      loginDialog: false,
      activeName: "button",
      buttonForm: {
        display: "",
        elementCode: "",
        content: "",
        width: "",
        height: "",
        borderRadius: 20,
        backgroundColor: "",
        hoverColor: "",
        xAxis: "",
        yAxis: "",
      },
      buttonRules: {
        content: { required: true, message: "请输入按钮名称", trigger: "blur" },
      },
      loginBtnWidth: "",
      loginBtnHeight: "",
      marginOptions: [],
      lineHightOptions: [],
      typefaceForm: {
        font: "",
        fontSize: "",
        fontColor: "",
        fontHoverColor: "",
        bold: 0,
        italic: 0,
        textDecoration: 0,
      },
      typefaceOptions: [],
      FONT_SIZE_ENUM,
      icon_text_bold,
      icon_text_bold_active,
      icon_text_italic,
      icon_text_italic_active,
      icon_text_underline,
      icon_text_underline_active,
      icon_height,
      icon_width,
      personalData: {}, // 个人中心数据
      personalSecondData: [], // 个人中心二级数据
      personalTitle: "",
      personalDialog: false,
      personalDialogType: "new",
      personalForm: {
        elementCode: "",
        content: "",
        linkType: 1,
        linkAddress: "",
        link: "",
      },
      personalRules: {
        content: { required: true, message: "请输入栏目名称", trigger: "blur" },
        linkAddress: [
          {
            required: true,
            message: "请输入以http:或者https:开头链接",
            trigger: "blur",
          },
          {
            pattern: /http[s]{0,1}:\/\/([\w.]+\/?)\S*/,
            message: "请输入以http://或者https://开头链接",
            trigger: "change",
          },
        ],
        link: {
          validator: validateLink,
          required: true,
          // message: "请选择应用/中台桌面",
          trigger: "change",
        },
      },
      appList: [],
      zhongtaiTopicsList: [],
      mouseOffsetData: {
        x: "",
        y: "",
        objX: "",
        objY: "",
      },
      personalFormLoading: false,
      timeID: "",
      loginElementActive: false,
    };
  },
  computed: {
    ...mapState(["elementVisible", "safe_distance"]),
    draggableStyle() {
      return {
        left: `${this.buttonForm.xAxis}px`,
        top: `${this.buttonForm.yAxis}px`,
      };
    },
    loginBtnStyle() {
      return {
        width: `${this.buttonForm.width}px`,
        height: `${this.buttonForm.height}px`,
        borderRadius: `${this.buttonForm.borderRadius}%`,
        "--background-color": `${this.buttonForm.backgroundColor}`,
        "--background-color-hover": `${this.buttonForm.hoverColor}`,
        fontSize: `${this.typefaceForm.fontSize}px`,
        fontWeight: `${BOLD_ENUM[Number(this.typefaceForm.bold)]}`,
        fontStyle: `${ITALIC_ENUM[Number(this.typefaceForm.italic)]}`,
        textDecoration: `${
          UNDERLINE_ENUM[Number(this.typefaceForm.textDecoration)]
        }`,
        // lineHeight: `${this.buttonForm.height}px`,
      };
    },
    loginTextStyle() {
      return {
        "--color": `${this.typefaceForm.fontColor}`,
        "--color-hover": `${this.typefaceForm.fontHoverColor}`,
      };
    },
  },
  watch: {
    elementVisible: {
      deep: true,
      handler(val) {
        if (val.top) {
          this.buttonForm.display = !val.login;
        } else {
          this.buttonForm.display = true;
        }
      },
    },
    "buttonForm.display": {
      deep: true,
      handler(val) {
        this.elementVisible.login = !val;
        this.elementVisibleChange(this.elementVisible);
      },
    },
    "buttonForm.backgroundColor": {
      deep: true,
      handler(nVal, oVal) {
        if (oVal === "" || !oVal) return;
        // this.handleChange();
        this.handleDebounce();
      },
    },
    "buttonForm.hoverColor": {
      deep: true,
      handler(nVal, oVal) {
        if (oVal === "" || !oVal) return;
        // this.handleChange();
        this.handleDebounce();
      },
    },
  },
  mounted() {
    const vm = this
    this.getLoginButtonInfo();
    getApps({ pageNumber: 1, pageSize: 1000 }).then((res) => {
      console.log("res-->", res);
      const { records } = res.result;
      this.appList = records;
    });
    getZhongtaiTopics().then((res) => {
      console.log("res-->", res);
      const { records } = res.result;
      this.zhongtaiTopicsList = records;
    });
    document.body.addEventListener(
      "click",
      () => {
        this.showEditBtn = false;
      },
      false
    );
    //监听鼠标按下 是否在登陆组件下
    document.body.addEventListener(
      "mousedown",
      (e) => {
        let num = 0
        e.path.forEach( item => {
          if(item.className == 'loginElement'){
            num++
          }
        })

        if(num > 0){
          vm.loginElementActive = true
          vm.onActivated() 
        }else{
          vm.loginElementActive = false;
        }
 
      },
      false
    );
  },
  methods: {
    ...mapActions(["elementVisibleChange", "safeDistanceChange"]),
    /** */
    switchEdit() {
      this.showEditBtn = !this.showEditBtn;
      // 隐藏父级工具栏
      this.$emit("hiddenTool");
    },
    /** 开始拖拽 */
    handleDragStart(event) {
      const dragRef = this.$refs["LoginElement"];
      this.showEditBox = false;
      this.showEditMenu = false;
      this.mouseOffsetData.x = event.clientX;
      this.mouseOffsetData.y = event.clientY;
      this.mouseOffsetData.objX = dragRef.offsetLeft;
      this.mouseOffsetData.objY = dragRef.offsetTop;
    },
    /** 拖拽结束事件 */
    handleDragEnd(event) {
      const dragRef = this.$refs["LoginElement"];
      const { clientX = 0, clientY = 0 } = event;
      const {
        offsetWidth,
        // offsetHeight
      } = dragRef;
      const drageX =
        clientX - this.mouseOffsetData.x + this.mouseOffsetData.objX;
      const drageY =
        clientY - this.mouseOffsetData.y + this.mouseOffsetData.objY;
      if (drageX < 0) {
        this.safeDistanceChange({ is_safe: false });
        // this.buttonForm.xAxis = 0;
      } else if (drageX + offsetWidth > this.safe_distance.safe_width) {
        this.safeDistanceChange({ is_safe: false });
        // this.buttonForm.xAxis = this.safe_distance.safe_width - offsetWidth;
      } else {
        // this.buttonForm.xAxis = drageX;
      }
      this.buttonForm.xAxis = drageX;
      // if (drageY < 0) {
      //   this.safeDistanceChange({ is_safe: false });
      //   // this.buttonForm.yAxis = 0;
      // } else if (drageY + offsetHeight > this.safe_distance.top_safe_distance) {
      //   this.safeDistanceChange({ is_safe: false });
      //   // this.buttonForm.yAxis = this.safe_distance.top_safe_distance - offsetHeight;
      // } else {
      //   // this.buttonForm.yAxis = drageY;
      // }
      this.buttonForm.yAxis = drageY;
      this.handleChange();
    },
    handleDragOver(event) {
      event.dataTransfer.dropEffect = "move";
    },
    getLoginButtonInfo() {
      getLoginButton({
        backgroundCode: this.backgroundCode,
      }).then((res) => {
        const { result = {} } = res;
        this.buttonForm.display = result.display === 2;
        this.buttonForm.content = result.content;
        this.buttonForm.elementCode = result.elementCode;
        const { style = {} } = result;
        this.buttonForm.width = style.width || 100;
        this.buttonForm.height = style.height || 42;
        this.buttonForm.borderRadius = style.borderRadius;
        this.buttonForm.backgroundColor = style.backgroundColor || "";
        this.buttonForm.hoverColor = style.hoverColor || "";
        this.buttonForm.xAxis = style.xAxis || 0;
        this.buttonForm.yAxis = style.yAxis || 0;

        this.typefaceForm.font = style.font || "";
        this.typefaceForm.fontSize = style.fontSize || 20;
        this.typefaceForm.fontColor = style.fontColor;
        this.typefaceForm.fontHoverColor = style.fontHoverColor;
        this.typefaceForm.bold = Number(style.bold);
        this.typefaceForm.italic = Number(style.italic);
        this.typefaceForm.textDecoration = Number(style.textDecoration);
      });
    },
    handleEdit() {
      this.loginDialog = true;
    },
    handleBoxMouseleave() {
      this.showEditBtn = false;
    },
    debounce(func, wait) {
      // timeID = "";
      return (v) => {
        if (this.timeID) {
          clearTimeout(this.timeID);
        }
        this.timeID = setTimeout(() => {
          func(v);
        }, wait);
      };
    },
    handleDebounce(type) {
      this.debounce(this.handleChange, 200)(type);
    },
    handleChange() {
      if (!this.buttonForm.content) return;
      updateElemet({
        elementCode: this.buttonForm.elementCode,
        elementUpdateDto: {
          elementCode: this.buttonForm.elementCode,
          display: Number(this.buttonForm.display) + 1,
          content: this.buttonForm.content,
          style: {
            xAxis: this.buttonForm.xAxis,
            yAxis: this.buttonForm.yAxis,
            width: this.buttonForm.width,
            height: this.buttonForm.height,
            borderRadius: this.buttonForm.borderRadius,
            backgroundColor: this.buttonForm.backgroundColor,
            hoverColor: this.buttonForm.hoverColor,
            fontSize: this.typefaceForm.fontSize,
            fontColor: this.typefaceForm.fontColor,
            fontHoverColor: this.typefaceForm.fontHoverColor,
            bold: Number(this.typefaceForm.bold),
            italic: Number(this.typefaceForm.italic),
            textDecoration: Number(this.typefaceForm.textDecoration),
          },
        },
      }).then((res) => {
        console.log("res-->", res);
      });
    },
    /** 处理加粗、斜体、下横线 */
    handleBtnBIU(sign) {
      this.typefaceForm[sign] = !this.typefaceForm[sign];
      this.handleChange();
    },
    handleTabClick({ name = "" }) {
      console.log(name);
      if (name === "personal") {
        this.getProfileImageData();
      }
    },
    // 个人中心的数据是头像数据
    getProfileImageData() {
      getProfileImage({
        backgroundCode: this.backgroundCode,
      }).then((res) => {
        console.log("getProfileImage res:", res);
        // 头像元素
        const { result = {} } = res;
        // 个人中心数据
        const { children = [] } = result;
        this.personalData = children[0];
        // 个人中心的二级list
        const personalSecondData = children[0].children || [];
        // 使用sort的最大值于最小值去判断第一行和最后一行
        const sortVal = personalSecondData.map((item) => item.sort);
        const sortMin = Math.min(...sortVal);
        const sortMax = Math.max(...sortVal);
        personalSecondData.forEach((item) => {
          item.firstRow = item.sort === sortMin;
          item.lasterRow = item.sort === sortMax;
          item.sortArr = personalSecondData;
          item.canDelete = false;
        });
        if (personalSecondData.length === 1) {
          personalSecondData[0].canDelete = true;
        }
        this.personalSecondData = personalSecondData;
      });
    },
    handleAdd() {
      this.personalTitle = "新建菜单项";
      this.personalDialog = true;
      this.personalDialogType = "new";
    },
    handleLinkTypeChange() {
      this.personalForm.link = "";
    },
    handleOrderUp(row) {
      const { sortArr = [] } = row;
      sortArr.forEach((item, index) => {
        if (item.elementCode === row.elementCode) {
          let tempA = sortArr[index - 1];
          let tempB = sortArr[index];
          [tempB, tempA] = [tempA, tempB];
          sortArr[index - 1] = tempA;
          sortArr[index] = tempB;
        }
      });
      const orderArr = sortArr.map((item, index) => {
        return {
          elementCode: item.elementCode,
          sort: index + 1,
        };
      });
      elementSort(orderArr).then(() => {
        this.getProfileImageData();
      });
    },
    handleOrderDown(row) {
      const { sortArr = [] } = row;
      for (let index = sortArr.length - 1; index > -1; index--) {
        if (sortArr[index].elementCode === row.elementCode) {
          let tempA = sortArr[index];
          let tempB = sortArr[index + 1];
          [tempB, tempA] = [tempA, tempB];
          sortArr[index] = tempA;
          sortArr[index + 1] = tempB;
        }
      }
      const orderArr = sortArr.map((item, index) => {
        return {
          elementCode: item.elementCode,
          sort: index + 1,
        };
      });
      elementSort(orderArr).then(() => {
        this.getProfileImageData();
      });
    },
    handlePersonalEdit(row) {
      console.log("row", row);
      this.personalTitle = "编辑菜单项";
      this.personalDialog = true;
      this.personalDialogType = "edit";
      this.personalForm.elementCode = row.elementCode;
      this.personalForm.content = row.content || "";
      this.personalForm.linkType = row.linkType || 1;
      this.personalForm.linkAddress = row.linkAddress || "";
      this.personalForm.link = row.link || "";
    },
    handlePersonalDelete({ elementCode }) {
      deleteElemet({ elementCode }).then((res) => {
        console.log("res", res);
        this.getProfileImageData();
      });
    },
    /** 提交 */
    onSubmit(ref) {
      this.$refs[ref].validate((valid) => {
        if (valid) {
          this.personalFormLoading = true;
          console.log(valid);
          // 新增
          if (this.personalDialogType === "new") {
            const { containerCode = "" } = this.personalData;
            addToContainer({
              containerCode,
              content: this.personalForm.content,
              linkType: this.personalForm.linkType,
              linkAddress: this.personalForm.linkAddress,
              link: this.personalForm.link,
              elementType: "1", //现在的个人中心是文本元素 elementType传1
            }).then((res) => {
              if (res.code === 200) {
                this.$notify({
                  title: "成功",
                  message: "新建成功",
                  type: "success",
                });
                this.getProfileImageData();
                this.handlePersonalDialogClose();
                this.personalFormLoading = false;
              } else {
                this.$notify({
                  title: "失败",
                  message: `${res.message}`,
                  type: "error",
                });
                this.personalFormLoading = false;
              }
            });
          } else if (this.personalDialogType === "edit") {
            // 编辑
            updateElemet({
              elementCode: this.personalForm.elementCode,
              elementUpdateDto: {
                content: this.personalForm.content,
                linkType: this.personalForm.linkType,
                linkAddress: this.personalForm.linkAddress,
                link: this.personalForm.link,
                elementType: "1", //现在的个人中心是文本元素 elementType传1
              },
            }).then((res) => {
              if (res.code === 200) {
                this.$notify({
                  title: "成功",
                  message: "新建成功",
                  type: "success",
                });
                this.getProfileImageData();
                this.handlePersonalDialogClose();
                this.personalFormLoading = false;
              } else {
                this.$notify({
                  title: "失败",
                  message: `${res.message}`,
                  type: "error",
                });
                this.personalFormLoading = false;
              }
            });
          } else {
            return;
          }
        }
      });
    },
    handleClose() {
      this.loginDialog = false;
    },
    handlePersonalDialogClose() {
      this.$refs["personalForm"].resetFields();
      this.personalDialog = false;
    },
    //单击组件时调用
		onActivated() {
      
			console.log('down');
			const vm = this;
			document.onkeydown = function (e) {
				//监听键盘事件
				//事件对象兼容
				let e1 = e || event || window.event || arguments.callee.caller.arguments[0]
        // //键盘按键判断:左箭头-37;上箭头-38；右箭头-39;下箭头-40
        if(vm.loginElementActive){
          vm.keydownHandle(e1)
        }
				
			}
		},
 
		

		/**
     * 上下左右按键调整组件位置
     *
     */
    keydownHandle (ev) {
      console.log('ev:', ev.keyCode);
			if (ev.keyCode == 38) {
          this.buttonForm.yAxis--
      } else if (ev.keyCode == 39) {
          this.buttonForm.xAxis++
      } else if (ev.keyCode == 40) {
          this.buttonForm.yAxis++
      } else if (ev.keyCode == 37) {
          this.buttonForm.xAxis--
      }
      ev.preventDefault()
      this.handleChange();
    },
  },
};
</script>
<style lang="less" scoped>
.logindia {
  /deep/ .el-tabs__content {
    overflow: visible !important;
  }
}
.loginElement {
  display: inline-block;
  position: absolute;
  // right: 100px;
  .login-box {
    // width: 100px;
    // height: 42px;
    // border-radius: 4px;
    // font-size: 16px;
    // font-weight: 400;
    // color: #7c7c7c;
    // line-height: 42px;
    position: relative;
    background-color: var(--background-color);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: all-scroll;
    &:hover {
      background: rgba(0, 132, 255, 0.1);
      border: 1px solid #54adff;
    }
    &:hover {
      background-color: var(--background-color-hover);
    }
    span {
      color: var(--color);
      &:hover {
        color: var(--color-hover);
      }
    }
  }
  .edit-box {
    display: flex;
    justify-content: space-around;
    width: 140px;
    height: 48px;
    background: #ffffff;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    align-items: center;
    font-size: 14px;
    color: #4c4c4c;
    margin-top: 5px;
    position: relative;
    div {
      cursor: pointer;
    }
  }
}
/deep/ .login-dialog {
  .el-form-item__content {
    display: flex;
  }
  .order {
    display: flex;
    justify-content: space-around;
    .order-up-abled {
      cursor: pointer;
      color: #279eb7;
      font-weight: 900;
      font-size: 17px;
    }
    .order-up-unabled {
      cursor: not-allowed;
      font-weight: 900;
      font-size: 17px;
    }
  }
  .box-other {
    display: flex;
    line-height: 38px;
    div {
      margin: 0 10px;
      cursor: pointer;
    }
  }
  .add-box {
    height: 40px;
    background: #ffffff;
    border-left: 1px solid;
    border-right: 1px solid;
    border-bottom: 1px solid;
    border-color: #eaeeef;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color: #279eb7;
    cursor: pointer;
    .el-icon-plus {
      margin-right: 5px;
    }
  }
}
</style>