<!--
 * @Author: your name
 * @Date: 2021-05-17 19:07:47
 * @LastEditTime: 2021-06-18 17:16:00
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \dolphin-web\src\components\MenuElement\index.vue
-->
<template>
  <div
    :style="draggableStyle"
    @dragend="handleDragEnd"
    @dragover="handleDragOver"
    @dragstart="handleDragStart"
    @mouseleave="handleBoxMouseleave"
    class="MenuElement"
    draggable
    ref="MenuElement"
    v-show="!menuForm.display"
  >
    <div
      :style="menuBoxStyle"
      @mouseenter="handleDbClick"
      class="menu-box"
      v-if="menuTableData.length"
    >
      <div class="fullbg"></div>
      <div
        :key="item.elementCode"
        :style="[menuContentStyle, menuTableData.length > 1 && index != menuTableData.length-1 ? menuBoxIntervalStyle :'']"
        class="menu-item"
        v-for="(item ,index) in menuTableData"
      >{{ item.content }}</div>
    </div>
    <div @mouseenter="handleDbClick" class="menu-tips" v-else>鼠标移入编辑栏目</div>
    <div class="div-action" v-show="showEdit">
      <div @click="handleMenuManage">栏目管理</div>
      <div @click="handleStyleSetting">样式设置</div>
      <el-checkbox @change="handleDisplay" v-model="menuForm.display">隐藏</el-checkbox>
    </div>
    <el-dialog
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :destroy-on-close="true"
      :visible="menuManageDialog"
      @close="handleClase('menuManageDialog')"
      append-to-body
      custom-class="menu-dialog"
      title="栏目管理"
      width="640px"
    >
      <div class="dialog-content">
        <div class="content-top">
          <div class="label">设置显示栏目：</div>
          <el-button @click="handleNewMenu('new')">新建栏目</el-button>
        </div>
        <el-table
          :data="menuTableData"
          :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
          border
          default-expand-all
          row-key="elementCode"
          style="width: 100%"
        >
          <el-table-column align="left" label="栏目" prop="content"></el-table-column>
          <!-- <el-table-column label="显示" width="80" align="center">
            <template slot-scope="scope">
              <el-checkbox v-model="scope.row.name"></el-checkbox>
            </template>
          </el-table-column>-->
          <el-table-column align="left" label="排序" width="120">
            <template slot-scope="scope">
              <div class="order">
                <i class="el-icon-top order-up-unabled" v-if="scope.row.firstRow"></i>
                <i @click="handleOrderUp(scope.row)" class="el-icon-top order-up-abled" v-else></i>
                <i class="el-icon-bottom order-up-unabled" v-if="scope.row.lasterRow"></i>
                <i @click="handleOrderDown(scope.row)" class="el-icon-bottom order-up-abled" v-else></i>
              </div>
            </template>
          </el-table-column>
          <el-table-column align="left" label="操作" width="120">
            <template slot-scope="scope">
              <el-button @click="handleEdit(scope.row)" type="text">编辑</el-button>
              <!-- :disabled="scope.row.canDelete" -->
              <el-button @click="handleDelete(scope.row)" type="text">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-dialog>
    <el-dialog
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :title="newMenuTitle"
      :visible="newMenuDialog"
      @close="handleClase('newMenuDialog')"
      append-to-body
      custom-class="menu-dialog"
      width="500px"
    >
      <el-form :model="menuForm" :rules="menuRules" label-width="80px" ref="menuForm">
        <el-form-item label="栏目名称" prop="content">
          <el-input
            maxlength="20"
            minlength="1"
            placeholder="请输入栏目名称"
            v-model.trim="menuForm.content"
          ></el-input>
        </el-form-item>
        <el-form-item label="上级栏目">
          <el-select
            :disabled="dialogType !== 'new'"
            placeholder="请选上级栏目"
            v-model="menuForm.pElementCode"
          >
            <el-option
              :key="item.elementCode"
              :label="item.content"
              :value="item.elementCode"
              v-for="item in menuOptions"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="跳转到">
          <el-radio-group @change="handleLinkTypeChange" v-model="menuForm.linkType">
            <el-radio :label="1">链接</el-radio>
            <el-radio :label="2">应用</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          :key="menuForm.linkType"
          label="链接"
          prop="linkAddress"
          v-if="menuForm.linkType === 1"
        >
          <el-input placeholder="请输入以http:或者https:开头链接" v-model="menuForm.linkAddress"></el-input>
        </el-form-item>
        <el-form-item
          :key="menuForm.linkType"
          label="应用"
          prop="link"
          v-if="menuForm.linkType === 2"
        >
          <!-- <el-select v-model="menuForm.link" placeholder="请选择应用">
            <el-option
              v-for="item in appList"
              :key="item.appId"
              :label="item.appName"
              :value="item.appId"
            ></el-option>
          </el-select>-->
          <!-- <youeSelectSearch
            :options="appList"
            :optionsType="appOptions.optionsType"
            :pageNum="appOptions.pageNum"
            :pageSize="appOptions.pageSize"
            :pageTotal="appOptions.pageTotal"
            :selectLabel="appOptions.selectLabel"
            :selectValue="appOptions.selectValue"
            :total="appOptions.total"
            @changeSelectValue="chioceOption"
            @initMoreOptionClick="initMoreOption"
            @selectOption="selectOption"
            @inputValueChangeEvent="inputValueChangeEvent"
          />-->
          <youeSelectSearch
            :options="appList"
            :optionsType="appOptions.optionsType"
            :pageNum="appOptions.pageNum"
            :pageSize="appOptions.pageSize"
            :pageTotal="appOptions.pageTotal"
            :selectLabel="appOptions.selectLabel"
            :selectValue="appOptions.selectValue"
            :total="appOptions.total"
            @changeSelectValue="chioceOption"
            @initMoreOptionClick="initMoreOption"
            @selectOption="selectOption"
          />
        </el-form-item>
        <el-form-item label="打开方式">
          <el-radio-group v-model="menuForm.linkMode">
            <el-radio :label="1">桌面内打开</el-radio>
            <el-radio :label="2">新窗口打开</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="页面高度" prop="iframePageHeight">
          <el-input
            @input="iframePageHeightCheck(menuForm.iframePageHeight)"
            maxlength="5"
            minlength="1"
            placeholder="请填写实际页面高度"
            style="width:152px"
            v-model.trim="menuForm.iframePageHeight"
          ></el-input>
          <span class="tipsText">为了更好的体验，请填写实际页面高度</span>
        </el-form-item>
        <el-form-item label="可见设置" prop="loginVisible">
          <el-checkbox v-model="menuForm.loginVisible">未登录可见</el-checkbox>
        </el-form-item>
        <el-form-item>
          <el-button :loading="menuFormLoading" @click="onSubmit('menuForm')" type="primary">确定</el-button>
          <el-button @click="handleClase('newMenuDialog')">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :visible="menuStyleDialog"
      @close="handleClase('menuStyleDialog')"
      append-to-body
      custom-class="menu-dialog"
      title="栏目样式"
      width="500px"
    >
      <el-tabs @tab-click="handleMenuTabs" v-model="menuActiveName">
        <el-tab-pane label="一级栏目" name="first"></el-tab-pane>
        <el-tab-pane label="二级栏目" name="second"></el-tab-pane>
        <el-form label-width="80px" ref="menuATabs" v-if="menuActiveName == 'first'">
          <p class="title">栏目背景</p>
          <el-form-item label="背景颜色">
            <verte model="rgb" picker="square" v-model="menuAStyleData.backgroundColor"></verte>
            <!-- <el-color-picker
                show-alpha
                v-model="menuAStyleData.backgroundColor"
                size="mini"
                @change="handleMenuABChange(1)"
            ></el-color-picker>-->
          </el-form-item>
          <p class="title">栏目字体</p>
          <el-form-item label="字体字号">
            <!-- <el-select
                v-model="menuAStyleData.fontSize"
                style="width: 140px"
                size="mini"
                placeholder="请选择"
                @change="handleMenuABChange(1)"
              >
                <el-option
                  v-for="item in typefaceOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
            </el-select>-->
            <el-select
              @change="handleMenuABChange(1)"
              placeholder="请选择"
              size="mini"
              style="width: 120px; height: 100%"
              v-model="menuAStyleData.fontSize"
            >
              <el-option
                :key="item.key"
                :label="item.value"
                :value="item.key"
                v-for="item in FONT_SIZE_ENUM"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="字体颜色">
            <verte model="rgb" picker="square" v-model="menuAStyleData.fontColor"></verte>
            <!-- <el-color-picker
                show-alpha
                v-model="menuAStyleData.fontColor"
                size="mini"
                @change="handleMenuABChange(1)"
            ></el-color-picker>-->
          </el-form-item>
          <el-form-item label="悬停颜色">
            <verte model="rgb" picker="square" v-model="menuAStyleData.hoverColor"></verte>
            <!-- <el-color-picker
                show-alpha
                v-model="menuAStyleData.hoverColor"
                size="mini"
                @change="handleMenuABChange(1)"
            ></el-color-picker>-->
          </el-form-item>
          <el-form-item label="选中颜色">
            <verte model="rgb" picker="square" v-model="menuAStyleData.checkedColor"></verte>
            <!-- <el-color-picker
                show-alpha
                v-model="menuAStyleData.checkedColor"
                size="mini"
                @change="handleMenuABChange(1)"
            ></el-color-picker>-->
          </el-form-item>
          <el-form-item label="其他属性">
            <div class="box-other">
              <div>
                <img
                  :src="
                    Number(menuAStyleData.bold) ? icon_text_bold_active : icon_text_bold
                  "
                  @click="handleMenuABtnBIU('bold')"
                  class="mouse-style"
                />
              </div>
              <div>
                <img
                  :src="
                    Number(menuAStyleData.italic)
                      ? icon_text_italic_active
                      : icon_text_italic
                  "
                  @click="handleMenuABtnBIU('italic')"
                  class="mouse-style"
                />
              </div>
              <div>
                <img
                  :src="
                    Number(menuAStyleData.textDecoration)
                      ? icon_text_underline_active
                      : icon_text_underline
                  "
                  @click="handleMenuABtnBIU('textDecoration')"
                  class="mouse-style"
                />
              </div>
            </div>
          </el-form-item>
          <p class="title">布局设置</p>
          <el-form-item label="间隔">
            <div style="display: flex">
              <el-slider
                @change="handleMenuABChange(1)"
                style="width: 200px"
                v-model="menuAStyleData.padding"
              ></el-slider>
              <span>{{ `${menuAStyleData.padding} px` }}</span>
            </div>
          </el-form-item>
          <el-form-item label="高度">
            <div style="display: flex">
              <el-slider
                :min="10"
                @change="handleMenuABChange(1)"
                style="width: 200px"
                v-model="menuAStyleData.height"
              ></el-slider>
              <span>{{ `${menuAStyleData.height} px` }}</span>
            </div>
          </el-form-item>
        </el-form>
        <el-form label-width="80px" ref="menuBTabs" v-else>
          <p class="title">栏目背景</p>
          <el-form-item label="背景颜色">
            <verte model="rgb" picker="square" v-model="menuBStyleData.backgroundColor"></verte>
            <!-- <el-color-picker
                show-alpha
                v-model="menuBStyleData.backgroundColor"
                size="mini"
                @change="handleMenuABChange(2)"
            ></el-color-picker>-->
          </el-form-item>
          <p class="title">栏目字体</p>
          <el-form-item label="字体字号">
            <!-- <el-select
                v-model="menuBStyleData.fontSize"
                style="width: 140px"
                size="mini"
                placeholder="请选择"
                @change="handleMenuABChange(2)"
              >
                <el-option
                  v-for="item in typefaceOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
            </el-select>-->
            <el-select
              @change="handleMenuABChange(2)"
              placeholder="请选择"
              size="mini"
              style="width: 120px; height: 100%"
              v-model="menuBStyleData.fontSize"
            >
              <el-option
                :key="item.key"
                :label="item.value"
                :value="item.key"
                v-for="item in FONT_SIZE_ENUM"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="字体颜色">
            <verte model="rgb" picker="square" v-model="menuBStyleData.fontColor"></verte>
            <!-- <el-color-picker
                show-alpha
                v-model="menuBStyleData.fontColor"
                size="mini"
                @change="handleMenuABChange(2)"
            ></el-color-picker>-->
          </el-form-item>
          <el-form-item label="悬停颜色">
            <verte model="rgb" picker="square" v-model="menuBStyleData.hoverColor"></verte>
            <!-- <el-color-picker
                show-alpha
                v-model="menuBStyleData.hoverColor"
                size="mini"
                @change="handleMenuABChange(2)"
            ></el-color-picker>-->
          </el-form-item>
          <el-form-item label="选中颜色">
            <verte model="rgb" picker="square" v-model="menuBStyleData.checkedColor"></verte>
            <!-- <el-color-picker
                show-alpha
                v-model="menuBStyleData.checkedColor"
                size="mini"
                @change="handleMenuABChange(2)"
            ></el-color-picker>-->
          </el-form-item>
          <el-form-item label="其他属性">
            <div class="box-other">
              <div>
                <img
                  :src="
                    Number(menuBStyleData.bold) ? icon_text_bold_active : icon_text_bold
                  "
                  @click="handleMenuBBtnBIU('bold')"
                  class="mouse-style"
                />
              </div>
              <div>
                <img
                  :src="
                    Number(menuBStyleData.italic)
                      ? icon_text_italic_active
                      : icon_text_italic
                  "
                  @click="handleMenuBBtnBIU('italic')"
                  class="mouse-style"
                />
              </div>
              <div>
                <img
                  :src="
                    Number(menuBStyleData.textDecoration)
                      ? icon_text_underline_active
                      : icon_text_underline
                  "
                  @click="handleMenuBBtnBIU('textDecoration')"
                  class="mouse-style"
                />
              </div>
            </div>
          </el-form-item>
          <p class="title">布局设置</p>
          <el-form-item label="边距">
            <div style="display: flex">
              <el-slider
                @change="handleMenuABChange(2)"
                style="width: 200px"
                v-model="menuBStyleData.padding"
              ></el-slider>
              <span>{{ `${menuBStyleData.padding} px` }}</span>
            </div>
          </el-form-item>
          <el-form-item label="高度">
            <div style="display: flex">
              <el-slider
                :min="10"
                @change="handleMenuABChange(2)"
                style="width: 200px"
                v-model="menuBStyleData.height"
              ></el-slider>
              <span>{{ `${menuBStyleData.height} px` }}</span>
            </div>
          </el-form-item>
        </el-form>
      </el-tabs>
    </el-dialog>
  </div>
</template>
<script>
import icon_text_bold from "@/assets/images/TextElement/icon_text_bold.png";
import icon_text_bold_active from "@/assets/images/TextElement/icon_text_bold_active.png";
import icon_text_italic from "@/assets/images/TextElement/icon_text_italic.png";
import icon_text_italic_active from "@/assets/images/TextElement/icon_text_italic_active.png";
import icon_text_underline from "@/assets/images/TextElement/icon_text_underline.png";
import icon_text_underline_active from "@/assets/images/TextElement/icon_text_underline_active.png";

import { mapState, mapActions } from "vuex";
import youeSelectSearch from 'youe-select-search';
import {
  getMenu,
  updateContainer,
  updateContainerStyle,
  getContainerStyle,
} from "@/assets/js/api/container.js";
import {
  // getElementList,
  getElementChildCode,
  deleteElemet,
  addToMenu,
  getElementDetail,
  elementSort,
  updateElemet,
} from "@/assets/js/api/elements.js";
import { getInternetTopicId } from "@/assets/js/api/topic.js";
// import { getMenuStyles } from "@/assets/js/api/container.js";
import { getApps } from "@/assets/js/api/application.js";

import { FONT_SIZE_ENUM } from "./../../staticData/index.js";
import Verte from "verte";
const BOLD_ENUM = [400, 800];
const ITALIC_ENUM = ["", "italic"];
const UNDERLINE_ENUM = ["", "underline"];

export default {
  name: "MenuElement",
  props: ["backgroundCode"],
  data () {

    return {
      BOLD_ENUM,
      ITALIC_ENUM,
      UNDERLINE_ENUM,
      containerCode: "", // 一级menu的容器code
      showEdit: false,
      menuData: [],
      menuManageDialog: false,
      menuTableData: [],
      newMenuDialog: false,
      newMenuTitle: "",
      menuForm: {
        elementCode: "",
        display: "",
        content: "",
        pElementCode: "",
        linkType: 1,
        linkAddress: "",
        link: "",
        linkMode: 1,
        loginVisible: "",
        iframePageHeight: '1000',
        style: {
          xAxis: "",
          yAxis: "",
        },
      },
      menuRules: {
        content: { required: true, message: "请输入栏目名称", trigger: "blur" },
        linkAddress: [
          {
            required: true,
            message: "请输入以http:或者https:开头链接",
            trigger: "blur",
          },
          {
            pattern: /http[s]{0,1}:\/\/([\w.]+\/?)\S*/,
            message: "请输入以http://或者https://开头链接",
            trigger: "change",
          },
        ],
        link: { required: true, message: "请选择应用", trigger: "change" },
        iframePageHeight:
          [{ required: true, message: "请输入页面高度", trigger: "change" }]
      },
      menuOptions: [],

      originalData: [],
      dialogType: "new",
      menuStyleDialog: false,
      menuActiveName: "first",
      menuAStyleData: {
        backgroundColor: "",
        fontSize: "",
        fontColor: "",
        hoverColor: "",
        checkedColor: "",
        bold: 0,
        italic: 0,
        textDecoration: 0,
        padding: 10,
        height: "",
      },
      menuBStyleData: {
        backgroundColor: "",
        fontSize: "",
        fontColor: "",
        hoverColor: "",
        checkedColor: "",
        bold: 0,
        italic: 0,
        textDecoration: 0,
        padding: 10,
        height: "",
      },
      typefaceOptions: [],
      isBold: false,
      icon_text_bold,
      icon_text_bold_active,
      isItalic: false,
      icon_text_italic,
      icon_text_italic_active,
      isUnderline: false,
      icon_text_underline,
      icon_text_underline_active,
      FONT_SIZE_ENUM,
      mouseOffsetData: {
        x: "",
        y: "",
        objX: "",
        objY: "",
      },
      topicId: "",
      menuFormLoading: false,
      timeID: "",
      appList: [],
      inputValue: '',
      appOptions: {
        selectValue: '',
        selectLabel: '',
        optionsType: { "value": "appId", "label": "appName" },
        pageSize: 10,
        pageNum: 1,
        total: 0,
        pageTotal: 0
      }, //
      menuElementActive: false
    };
  },
  components: { Verte, youeSelectSearch },
  mounted () {
    const vm = this
    this.getMenuData();
    let URL = window.location.href;
    // if (URL.indexOf("?") > 0) URL = URL.split("?")[0];
    if (URL) {
      URL = window.hostUrl + window.dolphinUrl;
    }
    if (URL.indexOf("localhost") > 0) {
      URL = "http://service.dataosdev.youedata.com/etete-151/newweb/";
    }
    getInternetTopicId({ topicUrl: URL }).then((res) => {
      this.topicId = res.result.topicId;
    });
    //   this.topicId =  1
    this.appList = []
    getApps({ pageNumber: 1, pageSize: 10 }).then((res) => {
      const { result } = res;
      this.appList = result.records;
      this.appOptions.pageNum = result.current
      this.appOptions.total = result.total
      this.appOptions.pageTotal = result.pages
    });

    //监听鼠标按下 是否在登陆组件下
    document.body.addEventListener(
      "mousedown",
      (e) => {
        let num = 0
        e.path.forEach( item => {
          if(item.className == 'MenuElement'){
            num++
          }
        })

        if(num > 0){
          vm.menuElementActive = true
          vm.onActivated() 
        }else{
          vm.menuElementActive = false;
        }
 
      },
      false
    );

  },
  computed: {
    ...mapState(["elementVisible", "safe_distance"]),
    draggableStyle () {
      return {
        left: `${this.menuForm.style.xAxis}px`,
        top: `${this.menuForm.style.yAxis}px`,
      };
    },
    menuBoxStyle () {
      console.log("this.menuAStyleData.height----", this.menuAStyleData.height)
      return {
        backgroundColor: this.menuAStyleData.backgroundColor,
        height: `${this.menuAStyleData.height}px`,
        lineHeight: `${this.menuAStyleData.height}px`,
        paddingLeft: `${this.menuAStyleData.height / 2}px`,
        paddingRight: `${this.menuAStyleData.height / 2}px`,
      };
    },
    menuContentStyle () {
      return {
        fontSize: `${this.menuAStyleData.fontSize}px`,
        "--color": `${this.menuAStyleData.fontColor}`,
        "--color-hover": `${this.menuAStyleData.hoverColor}`,
        "--color-active": `${this.menuAStyleData.checkedColor}`,
        fontWeight: `${BOLD_ENUM[Number(this.menuAStyleData.bold)]}`,
        fontStyle: `${ITALIC_ENUM[Number(this.menuAStyleData.italic)]}`,
        textDecoration: `${
          UNDERLINE_ENUM[Number(this.menuAStyleData.textDecoration)]
          }`,
        height: `${this.menuAStyleData.height}px`,
        lineHeight: `${this.menuAStyleData.height}px`,
      };
    },
    menuBoxIntervalStyle () {
      return {
        marginRight: `${this.menuAStyleData.padding}px`
      };
    }
  },
  watch: {
    elementVisible: {
      deep: true,
      handler (val) {
        console.log("123", val);
        if (val.top) {
          this.menuForm.display = !val.menu;
        } else {
          this.menuForm.display = true;
        }
      },
    },
    "menuForm.display": {
      deep: true,
      handler (val) {
        this.elementVisible.menu = !val;
        this.elementVisibleChange(this.elementVisible);
      },
    },
    "menuAStyleData.backgroundColor": {
      deep: true,
      handler (nVal, oVal) {
        if (oVal === "" || !oVal) return;
        // this.handleMenuABChange(1);
        this.handleDebounce(1);
      },
    },
    "menuAStyleData.fontColor": {
      deep: true,
      handler (nVal, oVal) {
        if (oVal === "" || !oVal) return;
        // this.handleMenuABChange(1);
        this.handleDebounce(1);
      },
    },
    "menuAStyleData.hoverColor": {
      deep: true,
      handler (nVal, oVal) {
        if (oVal === "" || !oVal) return;
        // this.handleMenuABChange(1);
        this.handleDebounce(1);
      },
    },
    "menuAStyleData.checkedColor": {
      deep: true,
      handler (nVal, oVal) {
        if (oVal === "" || !oVal) return;
        // this.handleMenuABChange(1);
        this.handleDebounce(1);
      },
    },
    "menuBStyleData.backgroundColor": {
      deep: true,
      handler (nVal, oVal) {
        if (oVal === "" || !oVal) return;
        // this.handleMenuABChange(2);
        this.handleDebounce(2);
      },
    },
    "menuBStyleData.fontColor": {
      deep: true,
      handler (nVal, oVal) {
        if (oVal === "" || !oVal) return;
        // this.handleMenuABChange(2);
        this.handleDebounce(2);
      },
    },
    "menuBStyleData.hoverColor": {
      deep: true,
      handler (nVal, oVal) {
        if (oVal === "" || !oVal) return;
        // this.handleMenuABChange(2);
        this.handleDebounce(2);
      },
    },
    "menuBStyleData.checkedColor": {
      deep: true,
      handler (nVal, oVal) {
        if (oVal === "" || !oVal) return;
        // this.handleMenuABChange(2);
        this.handleDebounce(2);
      },
    },
  },
  methods: {
    ...mapActions(["elementVisibleChange", "safeDistanceChange"]),
    iframePageHeightCheck (value) {
      if (value) {
        let num = JSON.stringify(value).replace(/[^0-9]/g, '')
        this.menuForm.iframePageHeight = num
        if (num >= 0) {
          if (num > 10000) {
            this.menuForm.iframePageHeight = 10000
          }
        } else {
          this.menuForm.iframePageHeight = 0
        }
      }
    },

    /** 开始拖拽 */
    handleDragStart (event) {
      const dragRef = this.$refs["MenuElement"];
      console.log("DragStart", event, dragRef.offsetLeft, dragRef.offsetTop);
      this.showEdit = false;
      this.mouseOffsetData.x = event.clientX;
      this.mouseOffsetData.y = event.clientY;
      this.mouseOffsetData.objX = dragRef.offsetLeft;
      this.mouseOffsetData.objY = dragRef.offsetTop;
    },
    /** 拖拽结束事件 */
    handleDragEnd (event) {
      const dragRef = this.$refs["MenuElement"];
      const { clientX = 0, clientY = 0 } = event;
      const {
        offsetWidth,
        // offsetHeight
      } = dragRef;
      const drageX =
        clientX - this.mouseOffsetData.x + this.mouseOffsetData.objX;
      const drageY =
        clientY - this.mouseOffsetData.y + this.mouseOffsetData.objY;
      if (drageX < 0) {
        this.safeDistanceChange({ is_safe: false });
        // this.menuForm.style.xAxis = 0;
      } else if (drageX + offsetWidth > this.safe_distance.safe_width) {
        this.safeDistanceChange({ is_safe: false });
        // this.menuForm.style.xAxis = this.safe_distance.safe_width - offsetWidth;
      } else {
        // this.menuForm.style.xAxis = drageX;
      }
      this.menuForm.style.xAxis = drageX;
      // if (drageY < 0) {
      //   this.safeDistanceChange({ is_safe: false });
      //   // this.menuForm.style.yAxis = 0;
      // } else if (drageY + offsetHeight > this.safe_distance.top_safe_distance) {
      //   this.safeDistanceChange({ is_safe: false });
      //   // this.menuForm.style.yAxis = this.safe_distance.top_safe_distance - offsetHeight;
      // } else {
      //   // this.menuForm.style.yAxis = drageY;
      // }
      this.menuForm.style.yAxis = drageY;
      this.updateContainerData();
    },
    updateContainerData () {
      const menuForm = JSON.parse(JSON.stringify(this.menuForm));
      updateContainer({
        containerCode: this.containerCode,
        containerUpdateDto: {
          containerCode: this.containerCode,
          display: Number(menuForm.display) + 1,
          style: {
            xAxis: this.menuForm.style.xAxis,
            yAxis: this.menuForm.style.yAxis,
          },
        },
      }).then(() => { });
    },
    handleDragOver (event) {
      event.dataTransfer.dropEffect = "move";
    },
    handleBoxMouseleave () {
      this.showEdit = false;
    },
    handleDbClick () {
      this.showEdit = !this.showEdit;
      // 隐藏父级工具栏
      this.$emit("hiddenTool");
    },
    handleMenuManage () {
      this.menuManageDialog = true;
    },
    getMenuData () {
      // 获取一级元素的容器
      getMenu({ backgroundCode: this.backgroundCode }).then((res) => {
        const { result = {} } = res;
        this.menuData = result;
        this.menuForm.display = result.display === 2;
        this.menuForm.style.xAxis = result.style.xAxis;
        this.menuForm.style.yAxis = result.style.yAxis;
        this.menuForm.loginVisible = result.loginVisible === 2;
        const { containerCode } = result;
        this.containerCode = containerCode;
        this.getElementList();
        this.getContainerStyle(1);
        this.getContainerStyle(2);
      });
    },
    /** 获取一级元素的列表 */
    getElementList () {
      getElementChildCode({ containerCode: this.containerCode }).then((res) => {
        const { records } = res.result;
        const childContainerCodeArr = [];
        // 使用sort的最大值于最小值去判断第一行和最后一行
        const sortVal = records.map((item) => item.sort);
        const sortMin = Math.min(...sortVal);
        const sortMax = Math.max(...sortVal);
        records.forEach((item) => {
          if (item.childContainerCode) {
            childContainerCodeArr.push(item.childContainerCode);
          }
          item.firstRow = item.sort === sortMin;
          item.lasterRow = item.sort === sortMax;
          // 给数组的每一项添加全部数据 用来排序
          item.sortArr = records;
          // 默认数据全部可以删除
          item.canDelete = false;
        });
        // 当数据只有一条的时候 不让删
        if (records.length === 1) {
          records[0].canDelete = true;
        }
        console.log("records--->", records);
        if (childContainerCodeArr.length > 0) {
          const promiseArr = childContainerCodeArr.map((item) =>
            getElementChildCode({ containerCode: item })
          );
          // res的长度应该和childContainerCodeArr的长度一致 且下标一致
          Promise.all(promiseArr).then((res) => {
            res.forEach((item) => {
              const s_records = item.result.records;
              // 使用sort的最大值于最小值去判断第一行和最后一行
              const s_sortVal = s_records.map((item) => item.sort);
              const s_sortMin = Math.min(...s_sortVal);
              const s_sortMax = Math.max(...s_sortVal);
              s_records.forEach((record) => {
                // 这里的sort应该和index+1相等，
                // 若sort和index+1的值不等，则改成使用sort的最大值于最小值去判断第一行和最后一行
                record.firstRow = record.sort === s_sortMin;
                record.lasterRow = record.sort === s_sortMax;
                // 给数组的每一项添加全部数据 用来排序
                record.sortArr = s_records;
                // 默认数据全部可以删除
                item.canDelete = false;
              });
            });
            childContainerCodeArr.forEach((item, index) => {
              records.forEach((record) => {
                if (item === record.childContainerCode) {
                  record.children = res[index].result.records;
                }
              });
            });
            console.log(records);
            this.menuTableData = records;
          });
        } else {
          this.menuTableData = records;
        }
      });
    },
    getContainerStyle (type) {
      getContainerStyle({
        containerCode: this.containerCode,
        level: type,
      }).then((res) => {
        console.log("res--->", res);
        const {
          result: { innerStyle = {} },
        } = res;
        const sign = type === 1 ? "A" : "B";
        this[`menu${sign}StyleData`].backgroundColor = innerStyle.backgroundColor;
        this[`menu${sign}StyleData`].fontSize = innerStyle.fontSize;
        this[`menu${sign}StyleData`].fontColor = innerStyle.fontColor;
        this[`menu${sign}StyleData`].hoverColor = innerStyle.hoverColor;
        this[`menu${sign}StyleData`].checkedColor = innerStyle.checkedColor;
        this[`menu${sign}StyleData`].bold = innerStyle.bold;
        this[`menu${sign}StyleData`].italic = innerStyle.italic;
        this[`menu${sign}StyleData`].textDecoration = innerStyle.textDecoration;
        this[`menu${sign}StyleData`].padding = innerStyle.padding;
        this[`menu${sign}StyleData`].height = innerStyle.height;
      });
    },
    /** 编辑 */
    async handleEdit ({ elementCode }) {
      this.menuForm.elementCode = elementCode;
      const vm = this
      //要先等列表获取完，在获取详情，不然就会出现错误
      this.appList = []
      await  getApps({ pageNumber: 1, pageSize: 10 }).then((res) => {
        const { result } = res;
        this.appList = result.records;
        this.appOptions.pageNum = result.current
        this.appOptions.total = result.total
        this.appOptions.pageTotal = result.pages
      });
        getElementDetail({ elementCode }).then((res) => {
          const { result } = res;
          // this.originalData = JSON.parse(JSON.stringify(result));
          vm.handleNewMenu("edit");
          // this.menuForm = result;
          vm.menuForm.display = result.display === 2;
          vm.menuForm.content = result.content;
          vm.menuForm.pElementCode = result.pElementCode || "";
          vm.menuForm.linkType = result.linkType;
          vm.menuForm.linkAddress = result.linkAddress;
          vm.menuForm.iframePageHeight = result.iframeHigh;

          if (vm.appList.length > 0) {
            vm.menuForm.link = result.link;
            vm.appOptions.selectValue = result.link
            vm.appOptions.selectLabel = result.linkName
          } else {
            vm.menuForm.link = "";
          }
          vm.menuForm.linkMode = result.linkMode;
          vm.menuForm.loginVisible = result.loginVisible === 2;
          // this.newMenuDialog = true;
        })
     


    },
    /** 删除栏目 */
    handleDelete ({ elementCode }) {
      console.log(elementCode, "------row");
      deleteElemet({ elementCode }).then((res) => {
        console.log(res, "------res");
        this.getElementList();
      });
    },
    /** 排序上升 */
    handleOrderUp (row) {
      const { sortArr = [] } = row;
      sortArr.forEach((item, index) => {
        if (item.elementCode === row.elementCode) {
          let tempA = sortArr[index - 1];
          let tempB = sortArr[index];
          [tempB, tempA] = [tempA, tempB];
          sortArr[index - 1] = tempA;
          sortArr[index] = tempB;
        }
      });
      const orderArr = sortArr.map((item, index) => {
        return {
          elementCode: item.elementCode,
          sort: index + 1,
        };
      });
      elementSort(orderArr).then(() => {
        this.getElementList();
      });
    },
    /** 排序下降 */
    handleOrderDown (row) {
      const { sortArr = [] } = row;
      for (let index = sortArr.length - 1; index > -1; index--) {
        if (sortArr[index].elementCode === row.elementCode) {
          let tempA = sortArr[index];
          let tempB = sortArr[index + 1];
          [tempB, tempA] = [tempA, tempB];
          sortArr[index] = tempA;
          sortArr[index + 1] = tempB;
        }
      }
      const orderArr = sortArr.map((item, index) => {
        return {
          elementCode: item.elementCode,
          sort: index + 1,
        };
      });
      elementSort(orderArr).then(() => {
        this.getElementList();
      });
    },
    handleNewMenu (type) {
      this.newMenuDialog = true;
      if (type === "new") {
        this.newMenuTitle = "新建栏目";
      } else if (type === "edit") {
        this.newMenuTitle = "编辑栏目";
      }
      this.dialogType = type;
      this.menuOptions = [...this.menuTableData];
      this.menuOptions.unshift({
        content: "无",
        elementCode: "",
      });
    },
    handleDisplay () {
      this.updateContainerData();
      this.elementVisible.menu = false;
      this.elementVisibleChange(this.elementVisible);
    },
    handleLinkTypeChange (val) {
      if (val === 1) {
        // this.menuForm.linkAddress = this.originalData.linkAddress || "";
      } else if (val === 2) {
        this.restartApps()
      }
    },

    restartApps () {
      this.appList = []
      getApps({ pageNumber: 1, pageSize: 10 }).then((res) => {
        const { result } = res;
        this.appList = result.records;
        this.appOptions.pageNum = result.current
        this.appOptions.total = result.total
        this.appOptions.pageTotal = result.pages
      });
      
    },
    onSubmit (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.menuFormLoading = true;
          let obj = {
            elementType: 1,
            content: this.menuForm.content,
            linkType: this.menuForm.linkType,
            linkMode: this.menuForm.linkMode,
            linkAddress: this.menuForm.linkAddress,
            link: this.menuForm.link,
            loginVisible: this.menuForm.loginVisible ? 2 : 1,
            iframeHigh: this.menuForm.iframePageHeight
          };
          // pElementCode为null则是添加一级menu
          console.log("添加一级类目" + this.topicId);
          if (this.menuForm.pElementCode) {
            obj.pElementCode = this.menuForm.pElementCode;
            obj.detailType = 4; // 详细类型(1:登录按钮 2:头像图片 3:一级菜单文本 4:二级菜单文本 20:其他)
          } else {
            obj.containerCode = this.containerCode;
            obj.detailType = 3; // 详细类型(1:登录按钮 2:头像图片 3:一级菜单文本 4:二级菜单文本 20:其他)
            obj.topicId = this.topicId;
            //obj.topicId = 32
          }
          if (this.dialogType === "new") {
            addToMenu(obj).then((res) => {
              if (res.code === 200) {
                this.$notify({
                  title: "成功",
                  message: "新建栏目成功",
                  type: "success",
                });
                this.newMenuDialog = false;
                this.getElementList();
                this.menuFormLoading = false;
              } else {
                this.$notify({
                  title: "失败",
                  message: `${res.message}`,
                  type: "error",
                });
                this.menuFormLoading = false;
              }
            });
          } else if (this.dialogType === "edit") {
            updateElemet({
              elementCode: this.menuForm.elementCode,
              // elementUpdateDto: { ...this.menuForm },
              elementUpdateDto: { ...obj },
            }).then((res) => {
              if (res.code === 200) {
                this.$notify({
                  title: "成功",
                  message: "编辑栏目成功",
                  type: "success",
                });
                this.newMenuDialog = false;
                this.getElementList();
                this.menuFormLoading = false;
              } else {
                this.$notify({
                  title: "失败",
                  message: `${res.message}`,
                  type: "error",
                });
                this.menuFormLoading = false;
              }
            });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    handleClase (dialog) {
      this[dialog] = false;
      if (dialog === "newMenuDialog") {
        this.$refs["menuForm"].resetFields();
        this.menuForm.content = "";
        this.menuForm.pElementCode = "";
        this.menuForm.linkType = 1;
        this.menuForm.linkAddress = "";
        this.menuForm.link = "";
        this.menuForm.linkMode = 1;
        this.menuForm.loginVisible = "";
        this.appOptions.selectValue = ""
        this.appOptions.selectLabel = ""
        this.appOptions.iframePageHeight = "1000"
        this.inputValue = ''
      }
    },
    handleStyleSetting () {
      this.menuStyleDialog = true;
      // getMenu({ backgroundCode: this.backgroundCode }).then((res) => {
      //   const { containerCode } = res.result;
      //   getMenuStyles({ containerCode: containerCode, level: 1 }).then(
      //     (res) => {
      //       console.log("res---->", res);
      //     }
      //   );
      // });
    },
    handleMenuTabs () { },
    debounce (func, wait) {
      // timeID = "";
      return (v) => {
        if (this.timeID) {
          clearTimeout(this.timeID);
        }
        this.timeID = setTimeout(() => {
          func(v);
        }, wait);
      };
    },
    handleDebounce (type) {
      this.debounce(this.handleMenuABChange, 200)(type);
    },
    handleMenuABChange (type) {
      // console.log(type, debounce(type));
      const sign = type === 1 ? "A" : "B";
      updateContainerStyle({
        containerCode: this.containerCode,
        level: type,
        innerStyle: {
          fontSize: this[`menu${sign}StyleData`].fontSize,
          fontColor: this[`menu${sign}StyleData`].fontColor,
          hoverColor: this[`menu${sign}StyleData`].hoverColor,
          checkedColor: this[`menu${sign}StyleData`].checkedColor,
          bold: Number(this[`menu${sign}StyleData`].bold),
          italic: Number(this[`menu${sign}StyleData`].italic),
          textDecoration: Number(this[`menu${sign}StyleData`].textDecoration),
          backgroundColor: this[`menu${sign}StyleData`].backgroundColor,
          padding: this[`menu${sign}StyleData`].padding,
          height: this[`menu${sign}StyleData`].height,
        },
      }).then((res) => {
        this.timeID = "";
        if (res.code === 200) {
          this.$notify({
            title: "成功",
            message: "修改成功",
            type: "success",
          });
          this.getContainerStyle(type);
        } else {
          this.$notify({
            title: "失败",
            message: "修改失败",
            type: "error",
          });
        }
      });
    },
    /** menuA处理加粗、斜体、下横线 */
    handleMenuABtnBIU (sign) {
      console.log("blod1", this.menuAStyleData[sign])
      this.menuAStyleData[sign] = !Number(this.menuAStyleData[sign]);
      console.log("blod2", this.menuAStyleData[sign])
      this.handleMenuABChange(1);
    },
    /** menuB处理加粗、斜体、下横线 */
    handleMenuBBtnBIU (sign) {
      this.menuBStyleData[sign] = !Number(this.menuBStyleData[sign]);
      this.handleMenuABChange(2);
    },
    handleHide () {
      this.$emit("handleHide", false);
    },

    //点击加载更多 参数 num 当前页数
    initMoreOption (num) {

      getApps({ pageNumber: num, pageSize: 10, search: this.inputValue }).then((res) => {
        const { result } = res;
        let newAppList = [...this.appList, ...result.records]
        this.appList = newAppList;
        this.appOptions.pageNum = result.current
        this.appOptions.total = result.total
        this.appOptions.pageTotal = result.pages
      });
    },

    //改变select值 参数 item 选中的对象
    chioceOption (item) {
      this.appOptions.selectValue = item.appId
      this.appOptions.selectLabel = item.appName
      this.menuForm.link = item.appId
    },
    selectOption (val) {
      console.log("val:::::1", val)
      this.inputValue = val.toString()
      getApps({ pageNumber: 1, pageSize: 10, search: this.inputValue }).then((res) => {
        const { result } = res;
        console.log("val:::::", this.inputValue, result.records)
        this.appList = []
        this.appList = result.records;
        this.appOptions.pageNum = result.current
        this.appOptions.total = result.total
        this.appOptions.pageTotal = result.pages
      });
    },
    //搜索input值变化 参数 inputValue
    inputValueChangeEvent (val) {
      console.log("val:::::1", val)
      this.inputValue = val.toString()
      getApps({ pageNumber: 1, pageSize: 10, search: this.inputValue }).then((res) => {
        const { result } = res;
        console.log("val:::::", this.inputValue, result.records)
        this.appList = []
        this.appList = result.records;
        this.appOptions.pageNum = result.current
        this.appOptions.total = result.total
        this.appOptions.pageTotal = result.pages
      });

    },

    //单击组件时调用
		onActivated() {
			const vm = this;
			document.onkeydown = function (e) {
				//监听键盘事件
				//事件对象兼容
				let e1 = e || event || window.event || arguments.callee.caller.arguments[0]
				//键盘按键判断:左箭头-37;上箭头-38；右箭头-39;下箭头-40
        if(vm.menuElementActive){
          vm.keydownHandle(e1)
        }
				
			}
		},

		/**
     * 上下左右按键调整组件位置
     *
     */
    keydownHandle (ev) {

			if (ev.keyCode == 38) {
          this.menuForm.style.yAxis--
      } else if (ev.keyCode == 39) {
          this.menuForm.style.xAxis++
      } else if (ev.keyCode == 40) {
          this.menuForm.style.yAxis++
      } else if (ev.keyCode == 37) {
          this.menuForm.style.xAxis--
      }
      ev.preventDefault()
      this.updateContainerData();
    },

  },
};
</script>
<style lang="less" scoped>
/deep/ .el-tabs__content {
  overflow: visible !important;
}
.MenuElement {
  display: inline-block;
  position: absolute;
  .menu-box {
    // display: flex;
    cursor: all-scroll;
    display: inline-block;
    position: relative;
    &:hover {
      border: 1px solid #54adff;
      .fullbg {
        background: rgba(0, 132, 255, 0.1);
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
      }
    }
    div {
      display: inline-block;
      color: var(--color);
      &:hover {
        color: var(--color-hover);
      }
      &:active {
        color: var(--color-active);
      }
    }
    min-width: 10px;
    min-height: 10px;
  }
  .menu-tips {
    cursor: pointer;
    // border: 1px solid;
    color: #cacaca;
    font-size: 24px;
    width: 220px;
    height: 30px;
    padding-top: 10px;
  }
  .div-action {
    display: flex;
    justify-content: space-around;
    width: 220px;
    height: 48px;
    background: #ffffff;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    align-items: center;
    margin-top: 5px;
    font-size: 14px;
    color: #4c4c4c;
    div {
      cursor: pointer;
    }
  }
}
/deep/ .menu-dialog {
  .el-dialog__header {
    border-bottom: 1px solid;
    border-color: #f0f3f4;
  }
  .dialog-content {
    .content-top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .label {
        font-size: 14px;
        font-weight: 400;
        color: #7f7f7f;
      }
    }
    .order {
      display: flex;
      justify-content: space-around;
      .order-up-abled {
        cursor: pointer;
        color: #279eb7;
        font-weight: 900;
        font-size: 17px;
      }
      .order-up-unabled {
        cursor: not-allowed;
        font-weight: 900;
        font-size: 17px;
      }
    }
  }
  .el-table {
    margin-top: 20px;
  }
  .title {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #7f7f7f;
    margin-bottom: 10px;
  }
  .box-other {
    display: flex;
    div {
      margin: 0 10px;
      cursor: pointer;
    }
  }
}
.verte {
  justify-content: flex-start;
}
/deep/ .verte__menu-origin {
  z-index: 10000 !important;
}
/deep/ .verte__model {
  width: 48px;
}
/deep/ .youe-select__list {
  max-height: 200px !important;
  overflow: auto !important;
}
.tipsText {
  font-size: 12px;
  font-weight: 400;
  color: #999999;
  margin-left: 3px;
}
</style>